import { Theme, SxProps } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  errorPage: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    color: '#fff',

    textAlign: 'center',
    zIndex: theme.zIndex.errorPage,

    [theme.breakpoints.down('md')]: {
      pt: 20,
      justifyContent: 'flex-start',
    },
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      pt: 7,
    },
  },

  logo: {
    width: '300px',
    height: 'auto',

    [theme.breakpoints.down('md')]: {
      width: '260px',
    },
  },

  button: {
    px: 2,
    backgroundColor: '#555',
    color: '#fff',
  },
});

export default getStyles;
