import { useState, useEffect } from 'react';
import { Stack, Button, IconButton } from '@mui/material';
import { Close, Polyline, ShapeLine } from '@mui/icons-material';

import useStyles from 'hooks/useStyles';
import getStyles from './MeasurementTool.styles';
import useStore from 'store/store';
import { ButtonScreenshot } from 'components/ui';

type Mode = 'PointToPoint' | 'AxisAligned';

const MeasurementTool = () => {
  const [isMeasuringActive, setIsMeasuringActive] = useState(false);
  const [mode, setMode] = useState<Mode>('AxisAligned');

  const { emitUIInteraction, setIsMeasurementMode } = useStore();

  useEffect(() => {
    // Prevent staying in measurment mode after reload
    const handleBeforeUnload = (event: any) => {
      event.returnValue = ''; // Chrome requires returnValue to be set
      closeMeasurmentTool();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      closeMeasurmentTool();
    };
  }, []);

  const closeMeasurmentTool = () => {
    emitUIInteraction({
      CallFunctionRequest: {
        FunctionName: 'ToggleMeasurementTool',
        Arguments: 'false',
      },
    });

    if (isMeasuringActive) {
      createMeasurement();
    }

    setIsMeasuringActive(false);
    setIsMeasurementMode(false);
  };

  const changeMode = () => {
    let currentMode: Mode = 'PointToPoint';

    if (mode === 'PointToPoint') {
      currentMode = 'AxisAligned';
    }

    emitUIInteraction({
      CallFunctionRequest: {
        FunctionName: 'SetMeasurementToolMode',
        Arguments: currentMode,
      },
    });

    setMode(currentMode);
    setIsMeasuringActive(false);
  };

  const createMeasurement = () => {
    emitUIInteraction({
      CallFunctionRequest: {
        FunctionName: 'CreateMeasurement',
        Arguments: '',
      },
    });
    setIsMeasuringActive((prev) => !prev);
  };

  const clearAllMeasurements = () => {
    emitUIInteraction({
      CallFunctionRequest: {
        FunctionName: 'ClearAllMeasurements',
        Arguments: '',
      },
    });
    setIsMeasuringActive(false);
  };

  const styles = useStyles(getStyles);

  return (
    <Stack>
      <Stack sx={styles.measurementTool}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <Button sx={styles.buttonText} size="large" onClick={createMeasurement}>
              {isMeasuringActive ? 'Set Point Two' : 'Set Point One'}
            </Button>
            <IconButton sx={styles.button} size="large" onClick={changeMode} disabled={isMeasuringActive}>
              {mode === 'AxisAligned' ? <Polyline /> : <ShapeLine />}
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button sx={styles.buttonText} size="large" onClick={clearAllMeasurements} disabled={isMeasuringActive}>
              Clear All
            </Button>
            <IconButton sx={styles.button} size="large" onClick={closeMeasurmentTool} disabled={isMeasuringActive}>
              <Close />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      {/* <ButtonScreenshot type="TakeHighQualityCapture" /> */}
    </Stack>
  );
};

export default MeasurementTool;
