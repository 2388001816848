import { useEffect } from 'react';

import { useSnackbar } from 'contexts/SnackBarContext/SnackbarContext';

type ErrorMessages = {
  [key: string]: string;
};

const errorMessages: ErrorMessages = {
  '4501': 'All available streams are currently being used. Please try again later.',
};

const useOnCloseSocketHandler = () => {
  const { showSnackbar } = useSnackbar();

  const originalConsoleWarn = console.warn;

  useEffect(() => {
    const handleClose = (event: any) => {
      if (event) {
        let errorMessage = 'There has been an error. Please refresh your browser to try to reconnect';

        if (errorMessages[event.code]) {
          errorMessage = errorMessages[event.code];
        }

        showSnackbar('error', errorMessage);
      }
    };

    // Intercepting Arcware console.warn for LL-s, because  newWebRTC.socket.ws.onclose does not work every time
    console.warn = function (message, ...optionalParams) {
      if (message.includes('LL')) {
        handleClose(optionalParams[0]);
      } else {
        originalConsoleWarn.call(console, message, ...optionalParams);
      }
    };
  }, []);
};

export default useOnCloseSocketHandler;
