import { Stack, Switch as MuiSwitch, Typography } from '@mui/material';

interface Props {
  value: boolean;
  label?: string;
  onChange: any;
  disabled: boolean;
}

const Switch = ({ value = false, label, onChange, disabled = false }: Props) => {
  const handleSwitch = (event: any) => onChange(event.target.checked);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
      <Typography variant="body2">{label}</Typography>
      <MuiSwitch checked={value} onChange={handleSwitch} size="small" disabled={disabled} />
    </Stack>
  );
};

export default Switch;
