interface ReleaseInfo {
  version: string;
  date: string;
}
export interface ProjectSlice {
  shareId: string;
  showAppUi: boolean;
  releaseInfo: ReleaseInfo;

  setProjectData: (shareId: string, showAppUi: boolean) => void;
  setReleaseInfo: (releaseInfo: ReleaseInfo) => void;
}

type SetFunction = (callback: (state: ProjectSlice) => ProjectSlice) => void;

export const createProjectSlice = (set: SetFunction): ProjectSlice => ({
  shareId: '',
  showAppUi: true,
  releaseInfo: { version: '', date: '' },

  setProjectData: (shareId, showAppUi) => set((state) => ({ ...state, shareId, showAppUi })),
  setReleaseInfo: (releaseInfo) => set((state) => ({ ...state, releaseInfo })),
});
