import { useEffect, useState } from 'react';
import { Stack, IconButton, Fade } from '@mui/material';
import { Visibility, VisibilityOff, Close, Add, Remove } from '@mui/icons-material';

import { Scale, Snap } from 'assets/icons';

import useStore from 'store/store';
import Slider from 'components/ui/sliders/Slider/Slider';

import useStyles from 'hooks/useStyles';
import getStyles from './SlicingTool.styles';

const SlicingTool = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [scale, setScale] = useState(10);
  const [snapTo45Angle, setSnapTo45Angle] = useState(false);
  const [scaleSectionPlane, setScaleSectionPlane] = useState(false);

  const { emitUIInteraction, setIsSlicingMode } = useStore();

  useEffect(() => {
    emitUIInteraction({
      CallFunctionRequest: {
        FunctionName: 'SetSlicingToolFloatingPanelVisibility',
        Arguments: false,
      },
    });
  }, []);

  const handleCloseSlicingTool = () => {
    emitUIInteraction({
      CallFunctionRequest: {
        FunctionName: 'SetSlicingToolMode',
        Arguments: false,
      },
    });
    setIsSlicingMode(false);
  };

  const handleHidePanel = () => {
    setIsVisible((prevState) => {
      emitUIInteraction({
        CallFunctionRequest: {
          FunctionName: 'SetSlicingToolVisibility',
          Arguments: !prevState,
        },
      });
      return !prevState;
    });
  };

  const handleResetSlice = () => {
    emitUIInteraction({
      CallFunctionRequest: {
        FunctionName: 'ResetSliced',
        Arguments: '',
      },
    });
  };

  const handleSlice = () => {
    emitUIInteraction({
      CallFunctionRequest: {
        FunctionName: 'Slice',
        Arguments: '',
      },
    });
  };

  const handleSnapTo45Angle = () => {
    setSnapTo45Angle((prevState) => {
      emitUIInteraction({
        CallFunctionRequest: {
          FunctionName: 'SetSlicingToolAngleSnapping',
          Arguments: !prevState,
        },
      });

      return !prevState;
    });
  };

  const handleScaleSectionPlane = () => {
    setScaleSectionPlane((prev) => !prev);
  };

  const handleScale = (event: any) => {
    setScale(event.target.value);

    emitUIInteraction({
      CallFunctionRequest: {
        FunctionName: 'SetSlicingToolScale',
        Arguments: `${event.target.value}`,
      },
    });
  };

  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.panel} alignItems="flex-end">
      <Stack direction="row" spacing={1}>
        {isVisible && (
          <Fade in={isVisible}>
            <Stack direction="row" spacing={1}>
              <IconButton onClick={() => handleSlice()}>
                <Add />
              </IconButton>
              <IconButton onClick={() => handleResetSlice()}>
                <Remove />
              </IconButton>
              <IconButton
                sx={{
                  ...styles.button,
                  ...(snapTo45Angle && styles.buttonActive),
                }}
                onClick={handleSnapTo45Angle}
              >
                <Snap size={16} color={snapTo45Angle ? '#000' : '#fff'} />
              </IconButton>
              <IconButton
                sx={{
                  ...styles.button,
                  ...(scaleSectionPlane && styles.buttonActive),
                }}
                onClick={handleScaleSectionPlane}
              >
                <Scale size={16} color={scaleSectionPlane ? '#000' : '#fff'} />
              </IconButton>
            </Stack>
          </Fade>
        )}
        <IconButton sx={isVisible && styles.buttonActive} onClick={handleHidePanel}>
          {isVisible ? <Visibility /> : <VisibilityOff />}
        </IconButton>
        <IconButton onClick={handleCloseSlicingTool}>
          <Close />
        </IconButton>
      </Stack>
      {isVisible && scaleSectionPlane && (
        <Fade in={isVisible}>
          <Slider sx={styles.slider} value={scale} onChange={handleScale} min={10} max={50} />
        </Fade>
      )}
    </Stack>
  );
};

export default SlicingTool;
