import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  cameraControls: {
    width: '180px',
    height: '100dvh',
    position: 'fixed',
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 5,
    pointerEvents: 'none',

    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      height: '100px',
      bottom: 0,
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      width: '120px',
    },
  },
});

export default getStyles;
