interface Props {
  size?: number;
  color?: string;
}

const Snap = ({ size = 14, color = '#fff' }: Props) => (
  <svg width={size} height={size} viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.275391 13.0625C0.275391 12.6958 0.572719 12.3984 0.939491 12.3984H13.336C13.7028 12.3984 14.0001 12.6958 14.0001 13.0625C14.0001 13.4293 13.7028 13.7266 13.336 13.7266H0.939491C0.572719 13.7266 0.275391 13.4293 0.275391 13.0625Z"
      fill={color}
    />
    <path
      d="M0.46959 13.5282C0.210243 13.2688 0.210243 12.8484 0.46959 12.589L12.3659 0.692727C12.6252 0.433379 13.0457 0.43338 13.305 0.692727C13.5644 0.952075 13.5644 1.37256 13.305 1.63191L1.40877 13.5282C1.14942 13.7875 0.728937 13.7875 0.46959 13.5282Z"
      fill={color}
    />
    <path
      d="M0.939491 13.7246C0.572718 13.7246 0.275391 13.4273 0.275391 13.0605L0.275391 12.2341C0.275391 11.8673 0.572718 11.57 0.939491 11.57C1.30626 11.57 1.60359 11.8673 1.60359 12.2341L1.60359 13.0605C1.60359 13.4273 1.30626 13.7246 0.939491 13.7246ZM0.939491 10.4189C0.572718 10.4189 0.27539 10.1215 0.27539 9.75476L0.27539 8.10189C0.27539 7.73512 0.572718 7.43779 0.939491 7.43779C1.30626 7.43779 1.60359 7.73512 1.60359 8.10189L1.60359 9.75476C1.60359 10.1215 1.30626 10.4189 0.939491 10.4189ZM0.939491 6.28668C0.572718 6.28668 0.27539 5.98936 0.27539 5.62258L0.27539 3.96971C0.27539 3.60294 0.572718 3.30561 0.939491 3.30561C1.30626 3.30561 1.60359 3.60294 1.60359 3.96971L1.60359 5.62258C1.60359 5.98936 1.30626 6.28668 0.939491 6.28668ZM0.93949 2.1545C0.572718 2.1545 0.27539 1.85718 0.27539 1.4904L0.27539 0.663968C0.27539 0.297195 0.572718 -0.000132574 0.93949 -0.00013259C1.30626 -0.000132606 1.60359 0.297195 1.60359 0.663968L1.60359 1.4904C1.60359 1.85718 1.30626 2.1545 0.93949 2.1545Z"
      fill={color}
    />
  </svg>
);
export default Snap;
