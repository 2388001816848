import { useState, useEffect } from 'react';

import axios from './axios';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useStore from 'store/store';

const useFetchProject = (id: string, isPublic: boolean) => {
  const [projectData, setProjectData] = useState<any>(null);

  const { setError } = useStore();

  const fetchAccessToken = useGetAccessToken();

  const url = isPublic ? `/project/public/${encodeURIComponent(id)}` : `/project/${encodeURIComponent(id)}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await fetchAccessToken();
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProjectData(response.data);
      } catch (error) {
        setError({ isError: true, message: 'This link is not valid.', type: 'projectIdError' });
      }
    };

    fetchData();
  }, []);

  return projectData;
};

export { useFetchProject };
