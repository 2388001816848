import { useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { VolumeOff, VolumeUp } from '@mui/icons-material';

import Slider from 'components/ui/sliders/Slider/Slider';
import useStore from 'store/store';

const AudioControls = () => {
  const { audioRef } = useStore();
  const [audioVolume, setAudioVolume] = useState(audioRef?.current?.paused ? 0 : 1);
  const [isAudioEnabled, setIsAudioEnabled] = useState<boolean>(!audioRef?.current?.paused);

  const setVolume = (volume: number) => {
    if (audioRef && audioRef.current) {
      audioRef.current.volume = volume;
      setAudioVolume(volume);
    }
  };

  const enableAudio = (enabled: boolean) => {
    if (audioRef && audioRef.current) {
      setIsAudioEnabled(enabled);

      if (enabled) {
        audioRef.current?.play();
      } else {
        audioRef.current.pause();
      }
    }
  };

  const handleVolumeChange = (event: any) => {
    const newVolume = event.target.value;
    setVolume(newVolume);

    if (newVolume === 0) {
      enableAudio(false);
    } else {
      enableAudio(true);
    }
  };

  const handleEnableAudio = () => {
    if (isAudioEnabled) {
      enableAudio(false);
      setVolume(0);
    } else {
      enableAudio(true);
      setVolume(1);
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <IconButton onClick={handleEnableAudio}>{isAudioEnabled ? <VolumeUp /> : <VolumeOff />}</IconButton>
      <Slider min={0} max={1} step={0.05} value={audioVolume} sx={{ width: '165px' }} onChange={handleVolumeChange} />
    </Stack>
  );
};

export default AudioControls;
