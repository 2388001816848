import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  logo: {
    '& img': {
      width: '300px',
      height: 'auto',

      [theme.breakpoints.down('md')]: {
        width: '230px',
      },
    },
  },
});

export default getStyles;
