import { Theme, SxProps } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  container: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    background: '#000',
    textAlign: 'center',
    zIndex: theme.zIndex.legalHealthPage,
  },

  fullScreenButton: {
    position: 'fixed',
    top: 0,
    left: 0,
    m: 2,
  },

  text: {
    width: '50%',
    margin: 'auto',
    color: 'white',

    [theme.breakpoints.down('md')]: {
      width: '90%',
    },

    // Mobile landscape
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      width: '90%',
    },
  },
});

export default getStyles;
