import { Stack, Button } from '@mui/material';

import useStyles from 'hooks/useStyles';
import getStyles from './ReailButtons.styles';

const RetailButtons = () => {
  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.retailButtons} direction="row" justifyContent="center" spacing={7}>
      <a href="https://cruisedemo.bayadynamics.com/retail" target="_blank" rel="noopener noreferrer">
        <Button sx={styles.retailButton}>buy now</Button>
      </a>
      <a href="https://cruisedemo.bayadynamics.com/retail" target="_blank" rel="noopener noreferrer">
        <Button sx={styles.retailButton}>add to cart</Button>
      </a>
    </Stack>
  );
};

export default RetailButtons;
