interface Props {
  size?: number;
  color?: string;
}

const Scale = ({ size = 14, color = '#fff' }: Props) => (
  <svg width={size} height={size} viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 10.8889L7 9.33333L4.66667 9.33333L4.66667 7L3.11111 7L3.11111 10.8889L7 10.8889ZM9.33333 4.66667L9.33333 7L10.8889 7L10.8889 3.11111L7 3.11111L7 4.66667L9.33333 4.66667ZM14 12.4444L14 1.55556C14 0.7 13.3 -3.0598e-08 12.4444 -6.79955e-08L1.55556 -5.43964e-07C0.7 -5.81361e-07 -3.0598e-08 0.699999 -6.79955e-08 1.55555L-5.43964e-07 12.4444C-5.81361e-07 13.3 0.699999 14 1.55555 14L12.4444 14C13.3 14 14 13.3 14 12.4444ZM1.54778 12.4444L1.54778 1.55556L12.4522 1.55556L12.4522 12.4444L1.54778 12.4444Z"
      fill={color}
    />
  </svg>
);
export default Scale;
