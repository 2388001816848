import { useState, useEffect } from 'react';

const useSetBaseURL = () => {
  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => setBaseUrl(window.location.pathname.split('/')[1]), []);

  return baseUrl;
};

export default useSetBaseURL;
