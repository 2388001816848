import { useEffect } from 'react';

const useClearUrl = () => {
  //Cleanup URL on reload
  useEffect(() => {
    // Extract the first part of the URL path
    const baseUrl = window.location.pathname.split('/')[1];

    // If there's a path beyond the first part, update the URL
    if (baseUrl) {
      const newUrl = `/${baseUrl}`;
      if (window.location.pathname !== newUrl) {
        window.history.replaceState({}, '', newUrl);
      }
    } else {
      // If there is no additional path, default to '/'
      if (window.location.pathname !== '/') {
        window.history.replaceState({}, '', '/');
      }
    }
  }, []);
};

export default useClearUrl;
