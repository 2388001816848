import get from 'lodash.get';

import controlPaths from 'config/sidebar/controlPaths';
import { getConfig } from 'config';

const getDefaultValue = (property: keyof typeof controlPaths, shareId: string) => {
  if (!shareId) {
    return null;
  }

  const config = getConfig(shareId);
  const propertyConfig = get(config, controlPaths[property]);

  if (!propertyConfig) {
    return null;
  }

  return propertyConfig.defaultValue;
};

export default getDefaultValue;
