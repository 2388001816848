import { Stack, Typography, IconButton, useMediaQuery, Theme } from '@mui/material';
import { Menu, ArrowRight } from '@mui/icons-material';

const InvertXYAxis = () => {
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Stack pt={4} px={2} pb={2} spacing={3}>
      <Stack
        direction={isMedium ? 'column' : 'row'}
        spacing={isLarge ? 1 : 2}
        justifyContent={isMedium ? 'flex-end' : 'flex-start'}
        alignItems="center"
      >
        <Stack sx={{ whiteSpace: 'nowrap' }} direction="row" alignItems="center" spacing={isMedium ? 0 : 2}>
          <IconButton color="inherit" aria-label="menu">
            <Menu />
          </IconButton>
          <ArrowRight sx={{ color: '#555' }} />
          <Typography variant="body2">Geneneral settings</Typography>
          <ArrowRight sx={{ color: '#555' }} />
          <Typography variant="body2">Invert x/y-axis</Typography>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          Inverting the <span style={{ color: '#FFF' }}>x-axis</span> (left/right) or{' '}
          <span style={{ color: '#FFF' }}>y-axis</span> (up/down) alters the way the camera responds to your mouse
          and/or touch inputs.
        </Typography>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          Setting this toggle to <span style={{ color: '#FFF' }}>on</span> will reverse the current setting - for
          example left becomes right, up becomes down and vice versa.
        </Typography>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          This is purely a matter of preference and there is no correct setting. Users are encouraged to try inverting
          the x or y axes if the default setting for either doesn’t feel natural.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default InvertXYAxis;
