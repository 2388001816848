import get from 'lodash.get';
import set from 'lodash.set';

import { getConfig } from 'config';
import controlPaths from 'config/sidebar/controlPaths';
import useStore from 'store/store';

const createDescriptor = (property: keyof typeof controlPaths, value: any) => {
  const getState = useStore.getState;
  const { shareId } = getState();

  const config = getConfig(shareId);
  const propertyConfig = get(config, controlPaths[property]);

  if (!propertyConfig) {
    return null;
  }

  const { object = {}, objectPath } = propertyConfig;

  const descriptor = set(object, objectPath, value);

  return descriptor;
};

export default createDescriptor;
