import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import useStore from 'store/store';

const CloseOverheadMode = () => {
  const { emitUIInteraction, setIsOverheadMode } = useStore();

  const handleCloseOverheadMode = () => {
    emitUIInteraction({ CallFunctionRequest: { FunctionName: 'Restart' } });
    setTimeout(
      emitUIInteraction({
        CallFunctionRequest: {
          FunctionName: 'SetMovementSpeed',
          Arguments: 0.5,
        },
      }),
      2000,
    );

    setIsOverheadMode(false);
  };

  return (
    <IconButton sx={{ position: 'fixed', top: 10, right: 10 }} onClick={handleCloseOverheadMode}>
      <Close />
    </IconButton>
  );
};

export default CloseOverheadMode;
