import { Stack, Typography, IconButton, useMediaQuery, Theme } from '@mui/material';
import { Menu, ArrowRight } from '@mui/icons-material';

import { MockButton } from 'components/ui';

const MovementModes = () => {
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Stack pt={4} px={2} pb={2} spacing={3}>
      <Stack
        direction={isMedium ? 'column' : 'row'}
        spacing={isLarge ? 1 : 2}
        justifyContent={isMedium ? 'flex-end' : 'flex-start'}
        alignItems="center"
      >
        <Stack sx={{ whiteSpace: 'nowrap' }} direction="row" alignItems="center" spacing={isMedium ? 0 : 2}>
          <IconButton color="inherit" aria-label="menu">
            <Menu />
          </IconButton>
          <ArrowRight sx={{ color: '#555' }} />
          <Typography variant="body2">Wayfinding</Typography>
          <ArrowRight sx={{ color: '#555' }} />
          <Typography variant="body2">Movement</Typography>
          <ArrowRight sx={{ color: '#555' }} />
        </Stack>

        <Stack sx={{ pl: isMedium ? 5 : 0 }} direction="row" spacing={1}>
          <MockButton label="walk" selected />
          <MockButton label="fly" />
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          You can choose between <span style={{ color: '#FFF' }}>Walk</span> or{' '}
          <span style={{ color: '#FFF' }}>Fly</span> mode by clicking the above options.
        </Typography>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          <span style={{ color: '#FFF' }}>Walk</span> mode allows you to you walk around the space from a human point of
          view i.e. your feet are anchored to the ground.
        </Typography>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          <span style={{ color: '#FFF' }}>Fly</span> mode allows you to fly around the space as if from a drone’s
          perspective. This allows you to get to places you might not be able to reach in{' '}
          <span style={{ color: '#FFF' }}>Walk</span> mode.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default MovementModes;
