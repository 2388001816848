import { Stack, Box, Button, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import Select from '../Select/Select';
import { labelItems } from '../../config';

import useStyles from 'hooks/useStyles';
import getStyles from './MobileModalContent.styles';

interface Props {
  value: string;
  name: string;
  handleClose: () => void;
  onChange: (name: string, value: string) => void;
  options: string[];
  isRetail?: boolean;
  text: any;
}

const MobileModalContent = ({ value, name, handleClose, onChange, options, isRetail = false, text }: Props) => {
  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.mobileModalContent} spacing={3} justifyContent="space-between" alignItems="center">
      {isRetail ? (
        <Typography sx={styles.selectedItem} variant="h5">
          {labelItems[name as keyof typeof labelItems]}
        </Typography>
      ) : (
        <Select sx={styles.select} value={value} name={name} onChange={onChange} options={options} />
      )}{' '}
      <Box sx={styles.image}>
        <img src={`/assets/images/items/${name}/${value}/0.png`} width={180} height={180} />
      </Box>
      {isRetail && (
        <a href="https://cruisedemo.bayadynamics.com/retail.html" target="_blank" rel="noopener noreferrer">
          <Button sx={styles.retailButton}>buy now</Button>
        </a>
      )}
      <Typography sx={styles.text}>{text}</Typography>
      <Stack sx={styles.closeButton} direction="row" justifyContent="flex-start">
        <IconButton onClick={handleClose} color="primary">
          <Close />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default MobileModalContent;
