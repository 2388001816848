import { useState, useEffect } from 'react';

import useStore from 'store/store';

import InfoPanelModal from './InfoPanelModal/InfoPanelModal';
import infoPanels from './config';

const InfoPanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPanel, setCurrentPanel] = useState('');

  const { emitUIInteraction, useWebRTCResponseEventListener } = useStore();

  useEffect(() => {
    // Prevent user from remaining in frozen configurator state if tab closes or reloads
    const handleBeforeUnload = (event?: any) => {
      event.returnValue = ''; // Chrome requires returnValue to be set
      emitUIInteraction({ State: 'Close' });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      handleCloseModal();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useWebRTCResponseEventListener('OpenPanel', (response: any) => {
    if (response.Type === 'Info' && response.State === 'Open') {
      setIsOpen(true);
      setCurrentPanel(response.Id);
    }
  });

  const handleCloseModal = () => {
    setIsOpen(false);
    emitUIInteraction({ State: 'Close' });
  };

  return (
    <InfoPanelModal
      data={infoPanels[currentPanel as keyof typeof infoPanels]}
      isOpen={isOpen}
      onClose={handleCloseModal}
    />
  );
};

export default InfoPanel;
