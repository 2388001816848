import { Stack } from '@mui/material';
import ControlTip from './ControlTip/ControlTip';

const Controls = ({ content }: any) => {
  return (
    <Stack p={4} spacing={5}>
      {content?.map((control: any, index: number) => (
        <ControlTip key={index} image={control.image} text={control.text} />
      ))}
    </Stack>
  );
};

export default Controls;
