import { Box, Alert as MuiAlert } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const CustomAlert = ({ children, severity }: any) => {
  return (
    <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', overflowX: 'hidden' }}>
      <MuiAlert severity={severity} sx={{ width: '100%', '.MuiAlert-icon': { pt: 1.2, pl: 3, mr: 1 } }}>
        {children}
      </MuiAlert>
    </Box>
  );
};

export default CustomAlert;
