import { Stack, Button, Typography } from '@mui/material';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';

import { labelsOptions } from '../../config';

import useStyles from 'hooks/useStyles';
import getStyles from './RetailControls.styles';

interface Props {
  value: string;
  name: string;
  onChange: (name: string, value: string) => void;
  options: string[];
}

const RetailControls = ({ value, name, onChange, options }: Props) => {
  const onPrev = () => {
    const currentItemIndex = options?.indexOf(value) as number;
    if (currentItemIndex > 0) {
      onChange(name, options[currentItemIndex - 1]);
    }
  };

  const onNext = () => {
    const currentItemIndex = options?.indexOf(value) as number;
    if (currentItemIndex < options.length - 1) {
      onChange(name, options[currentItemIndex + 1]);
    }
  };

  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.rightSectionHeader} direction="row" justifyContent="space-between" alignItems="center">
      <Button sx={styles.retailControl} onClick={() => null} startIcon={<ArrowBackIos />}>
        Prev
      </Button>
      <Typography> {labelsOptions[value as keyof typeof labelsOptions]}</Typography>
      <Button sx={styles.retailControl} onClick={() => null} endIcon={<ArrowForwardIos />}>
        Next
      </Button>
    </Stack>
  );
};

export default RetailControls;
