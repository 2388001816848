import { Stack, IconButton, Typography } from '@mui/material';
import { CompareArrows } from '@mui/icons-material';

const SwitchOrbit2D3D = () => {
  return (
    <Stack pt={4} px={2} pb={2} spacing={3}>
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton
          sx={{
            backgroundColor: '#FFF',
            color: '#292929',

            '&:hover': {
              backgroundColor: '#EEE',
            },
          }}
        >
          <Typography>2D</Typography>
        </IconButton>
        <CompareArrows sx={{ color: '#555' }} />
        <IconButton
          sx={{
            backgroundColor: '#292929',
            color: '#FFF',
          }}
        >
          <Typography>3D</Typography>
        </IconButton>
      </Stack>

      <Stack spacing={2}>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          Click this icon to switch between normal <span style={{ color: '#FFF' }}>3D mode</span>, and{' '}
          <span style={{ color: '#FFF' }}>2D mode</span> which shows a CAD drawing with dimensions etc.
        </Typography>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          This option is only available while in <span style={{ color: '#FFF' }}>Orbit mode</span>.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SwitchOrbit2D3D;
