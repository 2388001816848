import { Stack } from '@mui/material';

import useStyles from 'hooks/useStyles';

import Background from 'sections/loading/Background/Background';
import { ButtonFullScreen } from 'components/ui';
import Logo from 'sections/loading/Logo/Logo';
import Messages from 'sections/loading/Messages/Messages';
import Loader from 'sections/loading/Loader/Loader';
import Tips from 'sections/loading/Tips/Tips';

import getStyles from './LoadingPage.styles';
interface Props {
  messages: string[];
}

const LoadingPage = ({ messages }: Props) => {
  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.loadingPage}>
      {/* <Background /> */}
      <ButtonFullScreen sx={styles.fullScreenButton} />
      <Stack sx={styles.container} justifyContent="space-between">
        <Stack sx={styles.logoMessages} alignItems="flex-start">
          <Logo />
          <Messages messages={messages} />
        </Stack>
        {/* <Loader /> */}
        <Tips />
      </Stack>
    </Stack>
  );
};

export default LoadingPage;
