import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme, isRightSidebar: boolean): SxProps => ({
  topControls: {
    pl: isRightSidebar ? 0 : 1,
    pr: isRightSidebar ? 1 : 0,
    pt: 1,
    position: 'fixed',
    top: 0,
    left: isRightSidebar ? 'inherit' : 0,
    right: isRightSidebar ? 0 : 'inherit',
  },
});

export default getStyles;
