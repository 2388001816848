import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme, buttonsPerRow: number): SxProps => ({
  singleSelectGroupLabel: {
    width: '100%',
    mb: 2,
    '&:not(:first-of-type)': {
      mt: 1,
    },
  },
  singleSelectButton: {
    flex: `1 1 ${100 / buttonsPerRow}%`,
    maxWidth: `${100 / buttonsPerRow - 3.4}%`,
    mr: 1,
    mb: 1,
    p: 0,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  thumbnail: {
    borderRadius: '5px 5px 0px 0px',
    transform: 'translateY(2px)',
    zIndex: 1,
  },
  thumbnailDisabled: {
    filter: 'grayscale(100%)',
    cursor: 'not-allowed',
  },
});

export default getStyles;
