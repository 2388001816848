import React, { createContext, useContext, useState, ReactNode } from 'react';

type SnackbarSeverity = 'success' | 'error' | 'info' | 'warning';
type SnackbarDuration = number | null;

interface SnackbarContextProps {
  isSnackbarOpen: boolean;
  snackbarMessage: string;
  severity: SnackbarSeverity | undefined;
  duration: SnackbarDuration;
  showSnackbar: (severity: SnackbarSeverity, message: string, duration?: SnackbarDuration) => void;
  hideSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(undefined);

const useSnackbar = (): SnackbarContextProps => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<SnackbarSeverity | undefined>();
  const [duration, setDuration] = useState<SnackbarDuration>(null);

  const showSnackbar = (severity: SnackbarSeverity, message: string, duration: SnackbarDuration = null) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setDuration(duration);
    setIsSnackbarOpen(true);
  };

  const hideSnackbar = () => {
    setIsSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <SnackbarContext.Provider
      value={{
        isSnackbarOpen,
        snackbarMessage,
        severity,
        duration,
        showSnackbar,
        hideSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider, useSnackbar };
