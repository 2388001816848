import { useState, useEffect } from 'react';
import { Box, Fade, Stack } from '@mui/material';

import useStyles from 'hooks/useStyles';
import { ButtonFullScreen } from 'components/ui';
import HealthWarning from './HealthWarning';
import LegalWarning from './LegalWarning';

import getStyles from './HealthLegalPage.styles';

type TextState = 'legalInital' | 'legalIn' | 'legalOut' | 'healthIn' | 'healthOut';

const HealthLegalPage = () => {
  const [textState, setTextState] = useState<TextState>('legalInital');

  useEffect(() => {
    const timeout1 = setTimeout(() => {
      setTextState('legalIn');
    }, 500);

    const timeout2 = setTimeout(() => {
      setTextState('legalOut');
    }, 4500);
    const timeout3 = setTimeout(() => {
      setTextState('healthIn');
    }, 5000);

    const timeout4 = setTimeout(() => {
      setTextState('healthOut');
    }, 9500);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
      clearTimeout(timeout4);
    };
  }, []);

  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.container} justifyContent="center">
      <ButtonFullScreen sx={styles.fullScreenButton} />

      {textState.includes('legal') && (
        <Fade in={textState === 'legalIn'} timeout={1000}>
          <Box>
            <LegalWarning />
          </Box>
        </Fade>
      )}

      {textState.includes('health') && (
        <Fade in={textState === 'healthIn'} timeout={1000}>
          <Box>
            <HealthWarning />
          </Box>
        </Fade>
      )}
    </Stack>
  );
};

export default HealthLegalPage;
