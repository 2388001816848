import { useEffect, useState } from 'react';
import { Stack, Typography, useMediaQuery, Theme } from '@mui/material';

import Slider from '../Slider/Slider';

import useStore from 'store/store';
import { useSnackbar } from 'contexts/SnackBarContext/SnackbarContext';

import useStyles from 'hooks/useStyles';
import getStyles from './CameraModeSlider.style';

interface Props {
  label: string;
  functionName: string;
  min: number;
  max: number;
  step?: number;
  defaultValue: number;
  position: 'top' | 'bottom' | 'left' | 'right';
  isHighlighted?: boolean;
}

const CameraModeSlider = ({
  label,
  functionName,
  min,
  max,
  step = 1,
  defaultValue = 0,
  position,
  isHighlighted = false,
}: Props) => {
  const [value, setValue] = useState(defaultValue);

  const { emitUIInteraction } = useStore();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (isHighlighted && value === 0) {
      handleChange(150);
      showSnackbar('info', 'DOF set to 150. Use the slider on the left to adjust or turn off DOF.', 4000);
    }
  }, [isHighlighted]);

  const handleChange = (value: number) => {
    setValue(value);
    emitUIInteraction({
      CallFunctionRequest: {
        FunctionName: functionName,
        Arguments: value,
      },
    });
  };

  const isVertical = position === 'left' || position === 'right';

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const styles = useStyles(getStyles, isVertical, isHighlighted && value === 0);

  return (
    <Stack
      sx={{ ...styles.cameraSliderContainer, ...styles[position] }}
      direction={isVertical ? 'column' : 'row'}
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        sx={styles.overlay}
        direction={isVertical ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
        spacing={isVertical ? 1 : isMobile ? 3 : 3}
      >
        <Typography sx={styles.label} variant="subtitle1">
          {label}
        </Typography>
        <Slider
          sx={styles.slider}
          value={value}
          onChange={(event, newValue) => handleChange(newValue as number)}
          min={min}
          max={max}
          step={step}
          orientation={isVertical ? 'vertical' : 'horizontal'}
          onDoubleClick={() => handleChange(defaultValue)}
        />
        <Typography sx={styles.value} variant="subtitle1">
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CameraModeSlider;
