import { useState, useEffect } from 'react';

import useStore from 'store/store';

import ControlPanelModal from './ControlPanelModal/ControlPanelModal';

const defaultState = {
  Light1: 1,
};

const ControlPanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<keyof typeof defaultState>('Light1');
  const [configuratorState, setConfiguratorState] = useState(defaultState);

  const { emitUIInteraction, useWebRTCResponseEventListener } = useStore();

  useEffect(() => {
    // Prevent user from remaining in frozen configurator state if tab closes or reloads
    const handleBeforeUnload = (event: any) => {
      event.returnValue = ''; // Chrome requires returnValue to be set
      emitUIInteraction({ State: 'Close' });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  useWebRTCResponseEventListener('OpenPanel', (response: any) => {
    if (response.Type === 'LightDimmer' && response.State === 'Open') {
      setIsOpen(true);
      setCurrentItem(response.Id);
    }
  });

  const handleCloseModal = () => {
    setIsOpen(false);
    emitUIInteraction({ State: 'Close' });
  };

  const onChange = (name: string, value: any) => {
    setConfiguratorState((prev) => ({ ...prev, [name]: value }));
    emitUIInteraction({ Percentage: value });
  };

  return (
    <ControlPanelModal
      isOpen={isOpen}
      onClose={handleCloseModal}
      name={currentItem}
      value={configuratorState[currentItem]}
      onChange={onChange}
    />
  );
};

export default ControlPanel;
