import { Chip, SxProps } from '@mui/material';

interface Props {
  sx?: SxProps;
  label: string;
  selected?: boolean;
}

const MockButton = ({ sx, label, selected = false }: Props) => {
  return (
    <Chip
      sx={{
        minWidth: '115px',
        height: '20px',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: selected ? '#CCC' : '#555',
        color: selected ? '#292929' : '#fff',
        fontSize: '10px',
        ...sx,
      }}
      label={label}
      variant="outlined"
    />
  );
};

export default MockButton;
