import { Stack, Box, Button, IconButton, Typography } from '@mui/material';
import { Close, ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';

import RetailControls from '../RetailControls/RetailControls';
import RetailButtons from '../RetailButtons/RetailButons';
import Select from '../Select/Select';
import { labelItems } from '../../config';
import useStyles from 'hooks/useStyles';
import getStyles from './ModalContent.styles';

interface Props {
  value: string;
  name: string;
  handleClose: () => void;
  onChange: (name: string, value: string) => void;
  options: string[];
  isRetail?: boolean;
  toggleRightSection: () => void;
  isRightSectionVisible: boolean;
  text: any;
}

const ModalContent = ({
  value,
  name,
  handleClose,
  onChange,
  options,
  isRetail = false,
  toggleRightSection,
  isRightSectionVisible,
  text,
}: Props) => {
  const styles = useStyles(getStyles, isRightSectionVisible);

  return (
    <Stack sx={styles.contentContainer} direction="row">
      <Stack sx={styles.leftSection} spacing={2} justifyContent="space-between">
        <Stack sx={styles.textContainer} spacing={5}>
          <Typography sx={styles.selectedItem} variant="h5">
            {labelItems[name as keyof typeof labelItems]}
          </Typography>
          <Typography sx={styles.leftSectionText}>{text}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <IconButton onClick={handleClose} color="primary">
            <Close />
          </IconButton>
          <Button
            sx={styles.showMore}
            onClick={toggleRightSection}
            endIcon={isRightSectionVisible ? <ArrowBackIos /> : <ArrowForwardIos />}
          >
            {isRightSectionVisible ? 'less' : 'more'} info
          </Button>
        </Stack>
      </Stack>

      <Stack sx={styles.rightSection}>
        {isRetail ? (
          <RetailControls value={value} name={name} onChange={onChange} options={options} />
        ) : (
          <Stack sx={styles.rightSectionHeader} alignItems="center">
            <Select value={value} name={name} onChange={onChange} options={options} />
          </Stack>
        )}

        <Stack sx={styles.rightContent} alignItems="center">
          <Stack sx={styles.images} direction="row" justifyContent="center">
            {new Array(4).fill(null).map((image: any, index: number) => (
              <Box sx={styles.image} key={index}>
                <img src={`/assets/images/items/${name}/${value}/${index}.png`} width={180} height={180} />
              </Box>
            ))}
          </Stack>

          {isRetail && <RetailButtons />}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ModalContent;
