import { Typography, Stack, IconButton } from '@mui/material';

import { FlipToFront } from '@mui/icons-material';

import useStyles from 'hooks/useStyles';
import getStyles from './ButtonSidebar.styles';

interface Props {
  label: string;
  icon?: any;
  onClick: any;
}

const ButtonSidebar = ({ label, icon, onClick }: Props) => {
  const styles = useStyles(getStyles);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row">
        {icon}
        <Typography variant="body2" ml={icon && 2}>
          {label}
        </Typography>
      </Stack>

      <IconButton sx={styles.iconButton} onClick={() => onClick(true)}>
        <FlipToFront />
      </IconButton>
    </Stack>
  );
};

export default ButtonSidebar;
