import { Stack, IconButton, Typography } from '@mui/material';
import { Autorenew, Fullscreen, CompareArrows } from '@mui/icons-material';

const OrbitRotate = () => {
  return (
    <Stack pt={4} px={2} pb={2} spacing={3}>
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton
          sx={{
            backgroundColor: '#292929',
            color: '#FFF',
          }}
        >
          <Autorenew />
        </IconButton>
        <CompareArrows sx={{ color: '#555' }} />
        <IconButton
          sx={{
            backgroundColor: '#FFF',
            color: '#292929',

            '&:hover': {
              backgroundColor: '#EEE',
            },
          }}
        >
          <Fullscreen sx={{ transform: 'rotate(45deg)' }} />
        </IconButton>
      </Stack>

      <Stack spacing={2}>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          This option reverses the <span style={{ color: '#FFF' }}>pan</span> and{' '}
          <span style={{ color: '#FFF' }}>rotate</span> input commands while in{' '}
          <span style={{ color: '#FFF' }}>Orbit mode</span> to suit the preference of the user. This is also useful when
          using operating systems or devices that only have a <span style={{ color: '#FFF' }}>single mouse button</span>
          .
        </Typography>
      </Stack>
    </Stack>
  );
};

export default OrbitRotate;
