import { createTheme, Components } from '@mui/material';
import { scrollbar } from './globalStyles';

export const createComponents = (config: any): Components => {
  const { palette } = config;

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 40,
          '&:hover': {
            backgroundColor: '#333',
          },
          '&:disabled': {
            backgroundColor: '#222',
            color: '#aaa',
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
          },
        },
      },
      variants: [
        {
          props: { variant: 'active' },
          style: {
            height: '21px',
            fontSize: '10px',
            backgroundColor: '#cccccc',
            color: '#000000',
            '&:hover': {
              backgroundColor: '#cccccc',
            },
          },
        },
        {
          props: { variant: 'inactive' },
          style: {
            height: '21px',
            fontSize: '10px',
            backgroundColor: '#555555',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#555555',
            },
          },
        },
      ],
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          width: '40px',
          height: '40px',
          backgroundColor: '#292929',
          color: 'white',
          borderRadius: '5px',
          fontSize: '28px',

          '&:hover': {
            backgroundColor: '#333',
          },
        },
      },
    },

    MuiSlider: {
      styleOverrides: {
        thumb: {
          width: 14,
          height: 14,
          backgroundColor: '#fff',
          opacity: 1,
        },
        track: {
          height: '6px',
          backgroundColor: '#777',
          border: 0,
          opacity: 1,
        },
        rail: {
          height: '6px',
          backgroundColor: '#292929',
          opacity: 1,
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: '#494949',
          '&.Mui-checked': {
            backgroundColor: '#777',
          },
        },
        thumb: {
          width: 14,
          height: 14,
          backgroundColor: '#fff',
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: '#1b1b1b',

          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#1b1b1b',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: '#1b1b1b',
        },
      },
    },
  };
};
