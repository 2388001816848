const scrollbar = {
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    width: '7px',
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    width: '5px',
    height: '100px',
    borderRadius: '5px',
    backgroundColor: '#D3D3D3',
  },
  '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
    backgroundColor: '#A9A9A9',
    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#A9A9A9',
    },
    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#A9A9A9',
    },

    '@media (max-width:  1080px) and (max-height:  700px)': {
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        height: '80px',
      },
    },
  },
};

const horizontalScrollbar = {
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    height: '7px',
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    height: '5px',
    width: '100px',
    borderRadius: '5px',
    backgroundColor: '#D3D3D3',
  },
  '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
    backgroundColor: '#A9A9A9',
    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#A9A9A9',
    },
    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#A9A9A9',
    },

    '@media (max-width:  1080px) and (max-height:  700px)': {
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        width: '80px',
      },
    },
  },
};

export { scrollbar, horizontalScrollbar };
