import { Stack, IconButton, Typography } from '@mui/material';
import { Fullscreen as FullscreenIcon, FullscreenExit, CompareArrows } from '@mui/icons-material';

const Fullscreen = () => {
  return (
    <Stack pt={4} px={2} pb={2} spacing={3}>
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton>
          <FullscreenIcon />
        </IconButton>
        <CompareArrows sx={{ color: '#555' }} />
        <IconButton>
          <FullscreenExit />
        </IconButton>
      </Stack>

      <Stack spacing={2}>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          Click this icon to enable / disable <span style={{ color: '#FFF' }}>full screen</span> mode on operating
          systems and devices that support it.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Fullscreen;
