import { Stack, Button } from '@mui/material';

import SelectAll from './SelectAll/SelectAll';
import HideAll from './HideAll/HideAll';

import getButtonsPerRow from 'utils/getButtonsPerRow';
import useStyles from 'hooks/useStyles';
import getStyles from './MultiSelect.styles';

interface Option {
  value: string | string[];
  label: string;
}

interface Props {
  onChange: (values: string[]) => void;
  values: string[];
  options: Option[];
  disabled: boolean;
}

const MultiSelect = ({ onChange, values = [], options = [], disabled = false }: Props) => {
  const isActive = (value: string | string[]) => {
    if (Array.isArray(value)) {
      return value.every((subValue) => values.includes(subValue));
    } else {
      return values.includes(value);
    }
  };

  const handleSelect = (selectedValue: string) => {
    if (Array.isArray(selectedValue)) {
      if (isActive(selectedValue)) {
        onChange(values.filter((value: string) => !selectedValue.includes(value)));
      } else {
        onChange([...values, ...selectedValue]);
      }
    } else {
      if (isActive(selectedValue)) {
        onChange(values.filter((value: string) => value !== selectedValue));
      } else {
        onChange([...values, selectedValue]);
      }
    }
  };

  const buttonsPerRow = getButtonsPerRow(options);
  const styles = useStyles(getStyles, buttonsPerRow);

  return (
    <Stack sx={{ flexWrap: 'wrap' }} direction="row">
      {options.map((option: any) => {
        if (option.value === 'ShowAll') {
          return (
            <SelectAll
              key={option.label}
              onChange={onChange}
              label={option.label}
              values={values}
              options={options}
              buttonsPerRow={buttonsPerRow}
              disabled={disabled}
            />
          );
        } else if (option.value === 'HideAll') {
          return (
            <HideAll
              key={option.label}
              onChange={onChange}
              label={option.label}
              buttonsPerRow={buttonsPerRow}
              disabled={disabled}
            />
          );
        } else {
          return (
            <Button
              sx={styles.multiSelectButton}
              key={option.label}
              variant={isActive(option.value) ? 'active' : 'inactive'}
              onClick={() => handleSelect(option.value)}
              disabled={disabled}
            >
              {option.label}
            </Button>
          );
        }
      })}
    </Stack>
  );
};

export default MultiSelect;
