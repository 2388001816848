import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  imageContainer: {
    minWidth: '35%',
    width: '100px',
    height: '100px',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '50px',
      height: '50px',
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      width: '50px',
      height: '50px',
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  text: {
    minWidth: '65%',
    fontSize: theme.typography.h3,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h6,
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      fontSize: theme.typography.h6,
    },
  },
});

export default getStyles;
