import { useState } from 'react';
import { Stack } from '@mui/material';

import { getConfig } from '../../../config';
import useStore from 'store/store';

import SidebarSection from './SidebarSection/SidebarSection';
import HelpButton from 'components/ui/buttons/HelpButton/HelpButton';

const SidebarControls = () => {
  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  const { shareId } = useStore();

  const sideBarConfig = getConfig(shareId).sidebar;
  const sectionKeys = Object.keys(sideBarConfig);

  const handleAccordionChange = (sectionName: string) => (_event: any, isExpanded: boolean) => {
    setExpandedSection(isExpanded ? sectionName : null);
  };

  return (
    <Stack
      sx={{
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      {sectionKeys.map((sectionName, index) => (
        <SidebarSection
          key={index}
          sectionName={sectionName}
          onChange={handleAccordionChange(sectionName)}
          expanded={expandedSection === sectionName}
        />
      ))}
      <HelpButton />
    </Stack>
  );
};

export default SidebarControls;
