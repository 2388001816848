import { Select as MuiSelect, MenuItem } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { labelsOptions } from '../../config';

import useStyles from 'hooks/useStyles';
import getStyles from './Select.styles';

interface Props {
  sx?: any;
  value: string;
  name: string;
  onChange: (name: string, value: string) => void;
  options: string[];
}

const Select = ({ sx, value, name, onChange, options }: Props) => {
  const styles = useStyles(getStyles);

  return (
    <MuiSelect
      sx={{ ...styles.select, ...sx }}
      value={value}
      onChange={(event: any) => onChange(name, event.target.value)}
      IconComponent={ExpandMore}
    >
      {options &&
        options.map((option: string, index: number) => (
          <MenuItem key={index} value={option}>
            {labelsOptions[option as keyof typeof labelsOptions]}
          </MenuItem>
        ))}
    </MuiSelect>
  );
};

export default Select;
