import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  configuratorPanel: {
    position: 'fixed',
    top: 5,
    width: '500px',
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  content: {
    overflow: 'hidden',
    p: 0,
    backgroundColor: 'transparent',
  },
  contentContainer: { p: 3, backgroundColor: ' rgba(0, 0, 0, 0.75)' },
  slider: {
    width: '95%',
    '& .MuiSlider-rail': {
      backgroundColor: '#555',
    },
  },
});

export default getStyles;
