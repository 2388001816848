import IconButton from '@mui/material/IconButton';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { useFullScreen } from 'contexts/FullScreenContext/FullScreenContext';

interface Props {
  sx?: any;
}

const ButtonFullScreen = ({ sx }: Props) => {
  const { enterFullScreen, exitFullScreen, isFullScreenEnabled, isFullScreenSupported } = useFullScreen();

  if (!isFullScreenSupported) {
    return null;
  }

  return (
    <IconButton sx={sx} onClick={isFullScreenEnabled ? exitFullScreen : enterFullScreen}>
      {isFullScreenEnabled ? <FullscreenExit /> : <Fullscreen />}
    </IconButton>
  );
};

export default ButtonFullScreen;
