const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'development';
const REACT_APP_COMPANY = process.env.REACT_APP_COMPANY || 'bd';
const REACT_APP_SHOW_LEGAL = process.env.REACT_APP_SHOW_LEGAL === 'true';
const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || '';
const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const REACT_APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || '';
const REACT_APP_PORTAL_URL = process.env.REACT_APP_PORTAL_URL || '';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL || '';
const REACT_APP_SHARE_ID = process.env.REACT_APP_SHARE_ID || '';
const REACT_APP_GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || '';
const REACT_APP_IS_PUBLIC = process.env.REACT_APP_IS_PUBLIC === 'true';

// Export the environment variables
export default {
  REACT_APP_ENVIRONMENT,
  REACT_APP_COMPANY,
  REACT_APP_SHOW_LEGAL,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_PORTAL_URL,
  REACT_APP_API_URL,
  REACT_APP_SHARE_ID,
  REACT_APP_GA_TRACKING_ID,
  REACT_APP_IS_PUBLIC,
};
