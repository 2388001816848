import { Button } from '@mui/material';

import useStyles from 'hooks/useStyles';
import getStyles from '../MultiSelect.styles';

interface Props {
  onChange: any;
  label: string;
  buttonsPerRow: number;
  disabled: boolean;
}

const HideAll = ({ onChange, label, buttonsPerRow, disabled = false }: Props) => {
  const handleHideAll = () => onChange([]);

  const styles = useStyles(getStyles, buttonsPerRow);

  return (
    <Button sx={styles.multiSelectButton} variant={'inactive'} onClick={handleHideAll} disabled={disabled}>
      {label}
    </Button>
  );
};

export default HideAll;
