import { useNavigate } from 'react-router-dom';
import { Stack, Button, Divider } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

import useStore from 'store/store';

const HelpButton = () => {
  const { setIsHelpPanelOpen } = useStore();
  const navigate = useNavigate();

  const openHelp = () => {
    setIsHelpPanelOpen(true);
    navigate('/help/controls/touch/walk');
  };

  return (
    <Stack sx={{ width: '100' }}>
      <Button sx={{ py: 1.5, pl: 1, pr: 0 }} onClick={openHelp}>
        <Stack sx={{ width: '100%', color: '#FFFFFF' }} direction="row" justifyContent="space-between">
          Help
          <HelpOutline />
        </Stack>
      </Button>
      <Divider sx={{ borderColor: '#292929' }} />
    </Stack>
  );
};

export default HelpButton;
