import { useEffect, useState } from 'react';
import { WebRTCClient, WebRTCClientProps, events } from '@arcware/webrtc-plugin';

import useWebRTCResponse from 'hooks/useWebRTCResponse';
import useOnCloseSocketHandler from 'hooks/useOnCloseSocketHandler';
import { useSnackbar } from 'contexts/SnackBarContext/SnackbarContext';
import useStore from 'store/store';
import { environment, addCustomLoadingMessages } from 'utils';

const useWebRTCClient = () => {
  const [webRTCClient, setWebRTCClient] = useState<WebRTCClient | null>(null);

  const { REACT_APP_ENVIRONMENT } = environment;

  const {
    shareId,
    setEmitUIInteraction,
    setUseWebRTCResponseEventListener,
    setIsLoading,
    setError,
    setLoadingMessages,
    sizeContainerRef,
    containerRef,
    videoRef,
    audioRef,
  } = useStore();

  const { useWebRTCResponseEventListener, handleResponse } = useWebRTCResponse();
  const { showSnackbar } = useSnackbar();

  const initWebRTC = () => {
    const config: WebRTCClientProps = {
      address: 'wss://signalling-client.ragnarok.arcware.cloud/',
      shareId: shareId,
      packageId: '',
      settings: {},
      forceVideoToFitContainer: true,
      loader: (response) => setIsLoading(response),
      sendLetter: (response) => {
        addCustomLoadingMessages(response) && setLoadingMessages(addCustomLoadingMessages(response) as string);
      },
      applicationResponse: handleResponse,
      sizeContainer: sizeContainerRef?.current as HTMLDivElement,
      container: containerRef?.current as HTMLDivElement,
      videoRef: videoRef?.current as HTMLVideoElement,
      audioRef: audioRef?.current as HTMLAudioElement,
    };

    const newWebRTC = new WebRTCClient(config);

    setWebRTCClient(newWebRTC);

    // Handle AFK warn and error
    newWebRTC.events.addListener(events.EventTypes.AFK_Warn, () =>
      showSnackbar('warning', 'This stream will be closed due to user inactivity in 20 seconds', 20000),
    );
    newWebRTC.events.addListener(events.EventTypes.AFK_Action, () =>
      setError({
        isError: true,
        message: 'Your stream has timed out. Please refresh your browser to continue',
        type: 'afk',
      }),
    );
  };

  useEffect(() => {
    shareId && initWebRTC();
  }, [shareId]);

  useEffect(() => {
    const emitUIInteraction = (descriptor: any) => {
      if (!descriptor) {
        return;
      }

      webRTCClient?.emitUIInteraction(descriptor);
      console.log(descriptor);
    };

    setEmitUIInteraction(emitUIInteraction);
    setUseWebRTCResponseEventListener(useWebRTCResponseEventListener);
  }, [webRTCClient]);

  useOnCloseSocketHandler();
};

export default useWebRTCClient;
