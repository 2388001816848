import { IconButton, Stack, useMediaQuery, Theme } from '@mui/material';
import { Close } from '@mui/icons-material';

import useStyles from 'hooks/useStyles';
import getStyles from './CameraCloseButton.styles';

interface Props {
  handleClose: any;
}
const CameraCloseButton = ({ handleClose }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const styles = useStyles(getStyles);

  return (
    <Stack
      sx={styles.cameraClose}
      direction={isMobile ? 'row' : 'column'}
      alignItems="center"
      justifyContent="flex-end"
    >
      <IconButton sx={styles.button} onClick={handleClose} color="primary">
        <Close sx={styles.icon} />
      </IconButton>
    </Stack>
  );
};

export default CameraCloseButton;
