import j35Config from './sidebar/j35Config';
import spaConfig from './sidebar/spaConfig';
import k35Config from './sidebar/k35Config';
import stingrayConfig from './sidebar/stingrayConfig';
import s13Config from './sidebar/s13config';
import gymConfig from './sidebar/gymConfig';

import stingrayRoutes from './help/stingrayRoutes';
import j35Routes from './help/j35Routes';
import spaRoutes from './help/spaRoutes';

const configs = {
  'share-54278fbe-29a7-4f99-889f-03d88be5ca7a': j35Config,
  'share-22966ebd-0686-490f-a5b3-986715345ae3': j35Config,
  'share-cba104b6-7b71-4d4b-9347-4b568ae27077': spaConfig,
  'share-b9fe5760-a302-4252-bfee-1d66249d6e2b': k35Config,
  'share-8d564681-9aca-46dd-aaa9-c479510fc34f': stingrayConfig,
  'share-453bc932-f519-47cf-9afa-437c715483d0': stingrayConfig,
  'share-a9936b24-fc6f-47c7-8abd-effd405c137c': s13Config,
  'share-ade2c336-664a-41f1-aef8-51481183703e': gymConfig,
};

const routes = {
  'share-54278fbe-29a7-4f99-889f-03d88be5ca7a': j35Routes,
  'share-22966ebd-0686-490f-a5b3-986715345ae3': j35Routes,
  'share-cba104b6-7b71-4d4b-9347-4b568ae27077': spaRoutes,
  'share-b9fe5760-a302-4252-bfee-1d66249d6e2b': j35Routes,
  'share-8d564681-9aca-46dd-aaa9-c479510fc34f': stingrayRoutes,
  'share-453bc932-f519-47cf-9afa-437c715483d0': stingrayRoutes,
  'share-a9936b24-fc6f-47c7-8abd-effd405c137c': spaRoutes,
};

const getProject = (shareId: string) => {
  const projects = {
    'share-54278fbe-29a7-4f99-889f-03d88be5ca7a': 'j35',
    'share-22966ebd-0686-490f-a5b3-986715345ae3': 'j35',
    'share-cba104b6-7b71-4d4b-9347-4b568ae27077': 'spa',
    'share-b9fe5760-a302-4252-bfee-1d66249d6e2b': 'k35',
    'share-8d564681-9aca-46dd-aaa9-c479510fc34f': 'stingray',
    'share-453bc932-f519-47cf-9afa-437c715483d0': 'stingray',
    'share-a9936b24-fc6f-47c7-8abd-effd405c137c': 's13',
    'share-ade2c336-664a-41f1-aef8-51481183703e': 'gym',
  };

  return projects[shareId as keyof typeof projects] || 'j35';
};

const getConfig = (shareId: string) => {
  return configs[shareId as keyof typeof configs] || j35Config;
};

const getRoutes = (shareId: string) => {
  return routes[shareId as keyof typeof routes] || j35Routes;
};
export { getProject, getConfig, getRoutes };
