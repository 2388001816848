import { Theme, SxProps } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  guidedTourControls: {
    position: 'fixed',
    bottom: { xs: '100px', sm: '20px' },
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 5,
  },

  slider: {
    width: '50vw',

    [theme.breakpoints.down('sm')]: {
      width: '80vw',
    },
  },
});

export default getStyles;
