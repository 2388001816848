import { Box } from '@mui/material';
import { Joystick } from 'react-joystick-component';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';

import useStore from 'store/store';

const Joysticks = () => {
  const { emitUIInteraction } = useStore();

  const onMove = (event: IJoystickUpdateEvent, joystick: string) => {
    const { x, y, distance } = event;

    emitUIInteraction({
      [joystick]: {
        direction: {
          x,
          y,
        },
        distance,
      },
    });
  };

  const onStart = (event: IJoystickUpdateEvent, joystick: string) => {
    const { x, y, distance } = event;

    emitUIInteraction({
      [joystick]: {
        direction: {
          x,
          y,
        },
        distance,
      },
    });
  };

  const onStop = (event: IJoystickUpdateEvent, joystick: string) => {
    const { x, y, distance } = event;

    emitUIInteraction({
      [joystick]: {
        direction: {
          x,
          y,
        },
        distance,
      },
    });
  };

  return (
    <>
      <Box sx={{ position: 'fixed', bottom: 20, left: 40 }}>
        <Joystick
          size={100}
          baseColor="#29292980"
          stickImage="/assets/images/joystick-image.png"
          move={(event: IJoystickUpdateEvent) => onMove(event, 'JoystickMove')}
          start={(event: IJoystickUpdateEvent) => onStart(event, 'JoystickMoveStart')}
          stop={(event: IJoystickUpdateEvent) => onStop(event, 'JoystickMoveEnd')}
        />
      </Box>

      <Box sx={{ position: 'fixed', bottom: 20, right: 40 }}>
        <Joystick
          size={100}
          baseColor="#29292980"
          stickImage="/assets/images/joystick-image.png"
          move={(event: IJoystickUpdateEvent) => onMove(event, 'JoystickLook')}
          start={(event: IJoystickUpdateEvent) => onStart(event, 'JoystickLookStart')}
          stop={(event: IJoystickUpdateEvent) => onStop(event, 'JoystickLookEnd')}
        />
      </Box>
    </>
  );
};

export default Joysticks;
