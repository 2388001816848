import { Grid } from '@mui/material';

const CameraGrid = () => {
  return (
    <Grid sx={{ pointerEvents: 'none', position: 'fixed', width: '100dvw', height: '100dvh' }} container>
      {Array.from({ length: 9 }).map((_, index) => (
        <Grid
          key={index}
          item
          xs={4}
          sx={{
            border: '2px solid #CCCCCC22',
            height: '33.33%',
          }}
        />
      ))}
    </Grid>
  );
};

export default CameraGrid;
