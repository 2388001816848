const spaConfig = {
  sidebar: {
    Wayfinding: {
      views: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Views',
        defaultValue: null,
        orientation: 'horizontal',
        objectPath: 'TeleportPlayer',
        options: [
          {
            label: 'view 1',
            value: {
              location: {
                x: 2364.9050000000002,
                y: -817.50099999999998,
                z: 295.59399999999999,
              },
              rotation: {
                pitch: -20.032006000000017,
                yaw: -54.720857608785053,
                roll: -1.6926822139751998e-15,
              },
            },
            thumbnail: '/assets/images/spa/views/view1.webp',
          },
          {
            label: 'view 2',
            value: {
              location: {
                x: 1727.7529999999999,
                y: -980.29700000000003,
                z: 162.27500000000001,
              },
              rotation: {
                pitch: -10.03200600000002,
                yaw: 130.01600499999995,
                roll: 0,
              },
            },
            thumbnail: '/assets/images/spa/views/view2.webp',
          },
          {
            label: 'view 3',
            value: {
              location: {
                x: 2407.9270000000001,
                y: -129.22300000000001,
                z: 162.27500000000001,
              },
              rotation: {
                pitch: -10.032006000000024,
                yaw: 100.01600500000001,
                roll: 0,
              },
            },
            thumbnail: '/assets/images/spa/views/view3.webp',
          },
          {
            label: 'view 4',
            value: {
              location: {
                x: 2247.674,
                y: -422.43299999999999,
                z: 162.27500000000001,
              },
              rotation: {
                pitch: -10.03200600000002,
                yaw: -59.983994999999965,
                roll: -8.074846276548472e-16,
              },
            },
            thumbnail: '/assets/images/spa/views/view4.webp',
          },
          {
            label: 'view 5',
            value: {
              location: {
                x: 1755.5930000000001,
                y: 456.87200000000001,
                z: 162.27500000000001,
              },
              rotation: {
                pitch: -10.032006000000022,
                yaw: -119.98399499999996,
                roll: 0,
              },
            },
            thumbnail: '/assets/images/spa/views/view5.webp',
          },
          {
            label: 'view 6',
            value: {
              location: {
                x: 2526.5320000000002,
                y: -1258.702,
                z: 264.44999999999999,
              },
              rotation: {
                pitch: -10.032006000000022,
                yaw: -129.98399499999996,
                roll: 0,
              },
            },
            thumbnail: '/assets/images/spa/views/view6.webp',
          },
        ],
      },
      movement: {
        ui: 'interaction',
        type: 'controlGroup',
        mainLabel: 'Movement',
        children: {
          movementSpeed: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: 0.5,
            objectPath: 'CallFunctionRequest.Arguments',
            object: {
              CallFunctionRequest: {
                FunctionName: 'SetMovementSpeed',
                Arguments: null,
              },
            },
            options: [
              {
                label: '>',
                value: 0.5,
              },
              {
                label: '>>',
                value: 1,
              },
              {
                label: '>>>',
                value: 1.5,
              },
              {
                label: '>>>>',
                value: 2,
              },
            ],
          },
          movement: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: 'walk',
            objectPath: 'CallFunctionRequest.Arguments',
            object: {
              CallFunctionRequest: {
                FunctionName: 'SetActivePawn',
                Arguments: null,
              },
            },
            options: [
              {
                label: 'walk',
                value: 'walk',
              },

              {
                label: 'fly',
                value: 'fly',
              },
            ],
          },
        },
      },
      guidedTours: {
        ui: 'interaction',
        type: 'controlGroup',
        mainLabel: 'Guided tours',
        defaultValue: 1,
        children: {
          guidedToursSpeed: {
            ui: 'interaction',
            type: 'singleSelect',
            objectPath: 'PlaybackSpeed',
            options: [
              {
                label: '>',
                value: 1,
              },
              {
                label: '>>',
                value: 1.5,
              },
              {
                label: '>>>',
                value: 1.75,
              },
              {
                label: '>>>>',
                value: 2,
              },
            ],
          },
          guidedTours: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: null,
            objectPath: 'TourNumber',
            options: [
              {
                label: 'start tour',
                value: 1,
                thumbnail: '/assets/images/spa/guided_tours/tour1.webp',
              },
            ],
          },
        },
      },
    },
    Environment: {
      'Time of Day': {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Time of Day',
        orientation: 'horizontal',
        defaultValue: 'Day',
        objectPath: 'TimeOfDay',
        options: [
          { label: 'day', value: 'Day' },
          { label: 'dusk', value: 'Dusk' },
          { label: 'night', value: 'Night' },
        ],
      },
    },
    Information: {
      interactions: {
        ui: 'interaction',
        type: 'multiSelect',
        mainLabel: 'Interactions',
        defaultValue: ['Default'],
        objectPath: 'FilterTargets',
        options: [
          { label: 'show all', value: 'ShowAll' },
          { label: 'doors', value: 'Default' },
          { label: 'info', value: 'Info' },
          { label: 'hide all', value: 'HideAll' },
        ],
      },
    },
    Tools: {
      cameraMode: {
        ui: 'interaction',
        type: 'button',
        mainLabel: 'Camera mode',
        defaultValue: null,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'SetCameraMode',
            Arguments: null,
          },
        },
      },
      // measurementMode: {
      //   ui: 'interaction',
      //   type: 'button',
      //   mainLabel: 'Measurement Tool',
      //   defaultValue: null,
      //   objectPath: 'CallFunctionRequest.Arguments',
      //   object: {
      //     CallFunctionRequest: {
      //       FunctionName: 'ToggleMeasurementTool',
      //       Arguments: null,
      //     },
      //   },
      // },
    },
    'General Settings': {
      ShouldHideSidebar: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Hide nav on inactivity',
        defaultValue: false,
        objectPath: 'GeneralSettings.ShouldHideSidebar',
      },
      InvertLookX: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Invert x-axis',
        defaultValue: false,
        objectPath: 'GeneralSettings.InvertLookX',
      },
      InvertLookY: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Invert y-axis',
        defaultValue: false,
        objectPath: 'GeneralSettings.InvertLookY',
      },
      isRightSidebar: {
        ui: 'interactionWeb',
        type: 'switch',
        mainLabel: 'Sidebar on right',
        defaultValue: false,
      },
      showQuickCapture: {
        ui: 'interactionWeb',
        type: 'switch',
        mainLabel: 'Show quick capture',
        defaultValue: false,
      },
      // showJoysticks: {
      //   ui: 'interactionWeb',
      //   type: 'switch',
      //   mainLabel: 'Show joysticks',
      // defaultValue: false,
      // },
      LookSensitivity: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Look sensitivity',
        defaultValue: 2,
        objectPath: 'GeneralSettings.LookSensitivity',
        options: [
          {
            label: 'low',
            value: 1,
          },
          {
            label: 'medium',
            value: 2,
          },
          {
            label: 'high',
            value: 3,
          },
        ],
      },
      FieldOfView: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Field of view',
        defaultValue: 75,
        objectPath: 'GeneralSettings.FieldOfView',
        options: [
          {
            label: 'low',
            value: 25,
          },
          {
            label: 'medium',
            value: 75,
          },
          {
            label: 'high',
            value: 100,
          },
        ],
      },
      eyeHeight: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Walk mode eye height',
        defaultValue: 160,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'SetPawnEyeHeight',
            Arguments: null,
          },
        },
        options: [
          {
            label: 'lounging',
            value: 90,
          },
          {
            label: 'sitting',
            value: 110,
          },
          {
            label: 'standing',
            value: 160,
          },
        ],
      },
    },
  },
};

export default spaConfig;
