import createDescriptor from 'utils/createDescriptor';
export interface MainUiSlice {
  isSidebarOpen: boolean;
  isHelpPanelOpen: boolean;
  isCameraMode: boolean;
  isMeasurementMode: boolean;
  isGuidedTourMode: boolean;
  isOverheadMode: boolean;
  isSlicingMode: boolean;
  isRightSidebar: { value: boolean; disabled: boolean };
  showQuickCapture: { value: boolean; disabled: boolean };
  showJoysticks: { value: boolean; disabled: boolean };
  showTitlePage: boolean;
  error: { isError: boolean; message: string; type: string };

  setIsSidebarOpen: (updatedValue: boolean) => void;
  setIsHelpPanelOpen: (updatedValue: boolean) => void;
  setIsCameraMode: (updatedValue: boolean) => void;
  setIsMeasurementMode: (updatedValue: boolean) => void;
  setIsGuidedTourMode: (updatedValue: boolean) => void;
  setIsOverheadMode: (updatedValue: boolean) => void;
  setIsSlicingMode: (updatedValue: boolean) => void;
  setIsRightSidebar: (updatedValue: boolean) => void;
  setShowQuickCapture: (updatedValue: boolean) => void;
  setShowJoysticks: (updatedValue: boolean) => void;
  setShowTitlePage: (updatedValue: boolean) => void;
  setError: (updatedValue: any) => void;
}

type SetFunction = (callback: (state: any) => MainUiSlice) => void;

export const createMainUISlice = (set: SetFunction, get: any): MainUiSlice => ({
  isSidebarOpen: false,
  isHelpPanelOpen: false,
  isCameraMode: false,
  isMeasurementMode: false,
  isGuidedTourMode: false,
  isOverheadMode: false,
  isSlicingMode: false,
  isRightSidebar: { value: false, disabled: false },
  showQuickCapture: { value: false, disabled: false },
  showJoysticks: { value: false, disabled: false },
  showTitlePage: true,
  error: { isError: false, message: 'There was an error, please refresh', type: 'default' },

  setIsSidebarOpen: (updatedValue) => set((state) => ({ ...state, isSidebarOpen: updatedValue })),
  setIsHelpPanelOpen: (updatedValue) => set((state) => ({ ...state, isHelpPanelOpen: updatedValue })),
  setIsCameraMode: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('cameraMode', updatedValue));
    set((state) => ({ ...state, isCameraMode: updatedValue }));

    if (updatedValue) {
      get().setIsMeasurementMode(false);
      get().setIsGuidedTourMode(false);
    } else {
      get().updateMovementSpeed(0.5);
    }
  },
  setIsMeasurementMode: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('measurementMode', updatedValue));
    set((state) => ({ ...state, isMeasurementMode: updatedValue }));
  },
  setIsGuidedTourMode: (updatedValue) => {
    set((state) => ({ ...state, isGuidedTourMode: updatedValue }));

    if (updatedValue) {
      get().updateGuidedToursSpeed(get().guidedToursSpeed.value);
    } else {
      get().emitUIInteraction({ AnimationState: 'Stop' });
      set((state) => ({ ...state, guidedTours: { ...state.guidedTours, value: '' } }));
      get().updateMovementSpeed(0.5);
    }
  },
  setIsOverheadMode: (updatedValue) => set((state) => ({ ...state, isOverheadMode: updatedValue })),
  setIsSlicingMode: (updatedValue) => {
    if (updatedValue) {
      get().updateMovement('orbit');
    } else {
      get().updateMovement('walk');
    }

    get().emitUIInteraction(createDescriptor('slicingMode', updatedValue));
    set((state) => ({ ...state, isSlicingMode: updatedValue }));
  },
  setIsRightSidebar: (updatedValue) =>
    set((state) => ({ ...state, isRightSidebar: { ...state.isRightSidebar, value: updatedValue } })),
  setShowQuickCapture: (updatedValue) =>
    set((state) => ({ ...state, showQuickCapture: { ...state.showQuickCapture, value: updatedValue } })),
  setShowJoysticks: (updatedValue) =>
    set((state) => ({ ...state, showJoysticks: { ...state.showJoysticks, value: updatedValue } })),
  setShowTitlePage: (updatedValue) => set((state) => ({ ...state, showTitlePage: updatedValue })),
  setError: (updatedValue) => set((state) => ({ ...state, error: updatedValue })),
});
