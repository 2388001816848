import { IconButton } from '@mui/material';

interface Props {
  sx?: any;
  onClick: (value: any) => void;
  value: string | boolean | number;
  options: { value: any; icon: React.ReactNode; enabled: boolean }[];
  disabled?: boolean;
}

const ButonIconCycle = ({ sx, onClick, value, options, disabled = false }: Props) => {
  const handleClick = () => {
    const currentIndex = options.findIndex((option) => option.value === value);
    const nextIndex = currentIndex === options.length - 1 ? 0 : currentIndex + 1;
    const nextValue = options[nextIndex].value;

    onClick(nextValue);
  };

  const currentOption = options.find((option) => option.value === value);

  return (
    <IconButton
      sx={{
        ...sx,
        backgroundColor: currentOption?.enabled ? '#FFF' : '#292929',
        color: currentOption?.enabled ? '#292929' : '#FFF',

        '&:hover': {
          backgroundColor: currentOption?.enabled ? '#EEE' : '#333',
        },

        '&.Mui-disabled': {
          backgroundColor: '#292929',
          color: '#FFF',
          opacity: 0.4,
          cursor: 'not-allowed',
        },
      }}
      onClick={handleClick}
      disabled={disabled}
    >
      {currentOption?.icon}
    </IconButton>
  );
};

export default ButonIconCycle;
