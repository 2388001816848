import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import SidebarControl from '../SidebarControl/SidebarControl';
import { getConfig } from 'config';
import useStore from 'store/store';

import useStyles from 'hooks/useStyles';
import getStyles from './SidebarSection.styles';

const SidebarSection = ({ sectionName, onChange, expanded }: any) => {
  const { shareId } = useStore();
  const sideBarConfig: any = getConfig(shareId).sidebar;

  const sectionControls = Object.keys(sideBarConfig[sectionName]);

  const styles = useStyles(getStyles);

  return (
    <Accordion sx={styles.sidebarSection} onChange={onChange} expanded={expanded}>
      <AccordionSummary sx={styles.sidebarSectionSummary} expandIcon={<ExpandMore color="primary" />}>
        <Typography variant="body2">{sectionName}</Typography>
      </AccordionSummary>

      <AccordionDetails sx={styles.sidebarSectionDetails}>
        {sectionControls?.map((control: any, index: any) => (
          <SidebarControl key={index} control={sideBarConfig[sectionName][control]} controlName={control} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default SidebarSection;
