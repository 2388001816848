import { Drawer, Fade } from '@mui/material';
import { useIdleTimer } from 'react-idle-timer';

import SidebarHeader from './SidebarHeader/SidebarHeader';
import SidebarControls from './SidebarControls/SidebarControls';
import SidebarFooter from './SidebarFooter/SidebarFooter';
import useStore from 'store/store';

import useStyles from 'hooks/useStyles';
import getStyles from './Sidebar.styles';
import useUpdateSidebarStore from 'hooks/useUpdateSidebarStore';

const Sidebar = () => {
  const { isSidebarOpen, setIsSidebarOpen, ShouldHideSidebar, isRightSidebar } = useStore();

  useUpdateSidebarStore();

  const onIdle = () => {
    if (ShouldHideSidebar.value) {
      setIsSidebarOpen(false);
    }
  };

  useIdleTimer({ onIdle, timeout: 20000 });

  const styles = useStyles(getStyles);

  return (
    <>
      <Drawer
        anchor={isRightSidebar.value ? 'right' : 'left'}
        open={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        PaperProps={{ sx: styles.sideNav }}
        variant="persistent"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        TransitionComponent={Fade}
        transitionDuration={300}
      >
        <SidebarHeader />
        <SidebarControls />
        <SidebarFooter />
      </Drawer>
    </>
  );
};

export default Sidebar;
