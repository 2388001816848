import { Theme, SxProps } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  panel: { position: 'fixed', top: 0, right: 0, pt: 1, pr: 1, borderRadius: '5px' },
  textIcon: { textAlign: 'center' },
  button: { margin: 'auto', padding: 'auto', color: '#fff' },
  buttonActive: {
    backgroundColor: '#fff',
    color: '#000',
    '&:hover': { backgroundColor: '#EEE' },
  },
  slider: { width: '135px', zIndex: 200000 },
});

export default getStyles;
