import { Dialog, Stack, Box, IconButton, Typography, useMediaQuery, Theme } from '@mui/material';
import { Close } from '@mui/icons-material';

import useStyles from 'hooks/useStyles';
import getStyles from './InfoPanelModal.styles';

interface Props {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}

const InfoPanelModal = ({ data, isOpen, onClose }: Props) => {
  const styles = useStyles(getStyles);

  const isMobileLandscape = useMediaQuery((theme: Theme) => `(max-width: 1080px) and (max-height: 700px)`);

  return (
    <Dialog PaperProps={{ sx: styles.infoPanel }} open={isOpen} onClose={onClose} fullScreen={isMobileLandscape}>
      <Stack sx={styles.infoPanelContent} spacing={2} justifyContent="space-between">
        <Typography sx={styles.infoPanelTitle} variant="h5">
          {data?.title}
        </Typography>
        <Box sx={styles.infoPanelContentText}>{data?.text}</Box>
        <Stack direction="row" justifyContent="flex-start">
          <IconButton onClick={onClose} color="primary">
            <Close />
          </IconButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default InfoPanelModal;
