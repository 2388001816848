import { Theme, SxProps } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  titlePage: {
    width: '100%',
    height: '100dvh',
    position: 'fixed',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B1B1B',
    zIndex: theme.zIndex.loadingPage,
    cursor: 'pointer',
  },

  build: {
    width: '100%',
    height: '100%',
  },

  project: {
    width: '100%',
    height: '100%',
  },

  info: {
    ' @keyframes pulse': {
      '0%': { color: 'white' },
      '50%': { color: 'grey' },
      ' 100%': { color: 'white' },
    },
    width: '100%',
    height: '100%',
    mt: 1,
    pb: 12,
    textAlign: 'center',
    color: '#fff',
    textShadow: '2px 2px 4px rgba(255, 255, 255, 0.1)', // Light grey shadow
    animation: 'pulse 3s infinite ease-in-out',
  },
  projectText: {
    color: '#fff',
  },
});

export default getStyles;
