import { Theme, SxProps } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  measurementTool: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    pb: 1,
    pr: 1,
    zIndex: 5,

    [theme.breakpoints.down('sm')]: {
      bottom: '220px',
    },
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      bottom: '20px',
    },
  },
  button: {
    color: '#fff',
    backgroundColor: '#292929',

    '&:disabled': {
      backgroundColor: '#222',
      color: '#aaa',
    },
  },
  buttonText: {
    minWidth: '170px',
    backgroundColor: '#292929',
    color: '#fff',
  },
});

export default getStyles;
