import { Button } from '@mui/material';

import calculateAreAllOptionsSelected from '../utils/areAllOptionsSelected';
import useStyles from 'hooks/useStyles';
import getStyles from '../MultiSelect.styles';

interface Props {
  onChange: any;
  values: string[];
  options: any[];
  label: string;
  buttonsPerRow: number;
  disabled: boolean;
}

const SelectAll = ({ onChange, values, options, label, buttonsPerRow, disabled = false }: Props) => {
  const areAllOptionsSelected = calculateAreAllOptionsSelected(values, options);

  const handleSelectAll = () => {
    if (areAllOptionsSelected) {
      onChange([]);
    } else {
      const allValues = Object.values(options)
        .flatMap((option: any) => (Array.isArray(option.value) ? option.value : [option.value]))
        .filter((value) => value !== 'ShowAll' && value !== 'HideAll');

      onChange(allValues);
    }
  };

  const styles = useStyles(getStyles, buttonsPerRow);

  return (
    <Button
      sx={styles.multiSelectButton}
      variant={areAllOptionsSelected ? 'active' : 'inactive'}
      onClick={handleSelectAll}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default SelectAll;
