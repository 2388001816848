import { SxProps, Theme } from '@mui/material';
import { scrollbar } from 'theme/globalStyles';

const getStyles = (theme: Theme): SxProps => ({
  infoPanel: {
    minWidth: '750px',
    height: '65vh',
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      minWidth: '100vw',
      height: '80vh',
    },
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      minWidth: '50vw',
      height: '100dvh',
    },
  },
  infoPanelContent: {
    width: '350px',
    height: '100%',
    p: 4,
    boxSizing: 'border-box',
    backgroundColor: ' rgba(0, 0, 0, 0.75)',
    color: '#fff',
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      width: '450px',
    },
  },
  infoPanelTitle: {
    py: 2,
    m: 0,
    fontWeight: '400',
    color: '#DFB9B9',
    textAlign: 'center',
  },
  infoPanelContentText: { pr: 2, textAlign: 'left', overflowY: 'scroll', ...scrollbar },
});

export default getStyles;
