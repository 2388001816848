import { Stack, IconButton, Typography } from '@mui/material';
import { Menu, Autorenew, Fullscreen } from '@mui/icons-material';

import { ButtonFullScreen, ButonIconCycle } from 'components/ui';

import useStore from 'store/store';
import { getProject } from 'config';

import useStyles from 'hooks/useStyles';
import getStyles from './TopControls.styles';

const TopControls = () => {
  const {
    shareId,
    setIsSidebarOpen,
    isRightSidebar,
    showMode,
    updateShowMode,
    movement,
    inputMethod,
    updateInputMethod,
  } = useStore();

  const project = getProject(shareId);
  const isStingray = project === 'stingray';
  const isOrbit = movement.value === 'orbit';

  const styles = useStyles(getStyles, isRightSidebar.value);

  return (
    <Stack sx={styles.topControls} spacing={1}>
      <IconButton onClick={() => setIsSidebarOpen(true)}>
        <Menu />
      </IconButton>
      <ButtonFullScreen />

      {isStingray && isOrbit && (
        <ButonIconCycle
          onClick={updateShowMode}
          value={showMode.value}
          options={[
            { value: '2D', icon: <Typography>2D</Typography>, enabled: true },
            { value: '3D', icon: <Typography>3D</Typography>, enabled: false },
          ]}
          disabled={showMode.disabled}
        />
      )}

      {isStingray && isOrbit && (
        <ButonIconCycle
          onClick={updateInputMethod}
          value={inputMethod.value}
          options={[
            { value: false, icon: <Autorenew />, enabled: false },
            { value: true, icon: <Fullscreen sx={{ transform: 'rotate(45deg)' }} />, enabled: true },
          ]}
          disabled={showMode.disabled}
        />
      )}
    </Stack>
  );
};

export default TopControls;
