const updateFunctionsMap = {
  interactions: 'updateInteractions',
  scenario: 'updateScenario',
  'Time of Day': 'updateTimeOfDay',
  spaces: 'updateSpaces',
  views: 'updateViews',
  movement: 'updateMovement',
  movementSpeed: 'updateMovementSpeed',
  guidedTours: 'updateGuidedTours',
  guidedToursSpeed: 'updateGuidedToursSpeed',
  ShouldHideSidebar: 'updateShouldHideSidebar',
  InvertLookX: 'updateInvertLookX',
  InvertLookY: 'updateInvertLookY',
  isRightSidebar: 'setIsRightSidebar',
  showQuickCapture: 'setShowQuickCapture',
  showJoysticks: 'setShowJoysticks',
  LookSensitivity: 'updateLookSensitivity',
  FieldOfView: 'updateFieldOfView',
  eyeHeight: 'updateEyeHeight',
  orbitModeBackground: 'updateOrbitModeBackground',
  cameraMode: 'setIsCameraMode',
  measurementMode: 'setIsMeasurementMode',
  slicingMode: 'setIsSlicingMode',
  showMode: 'updateShowMode',
  inputMethod: 'updateInputMethod',
};

export default updateFunctionsMap;
