import { Stack, Typography } from '@mui/material';

import useStyles from 'hooks/useStyles';
import getStyles from './HealthLegalPage.styles';

const LegalWarning = () => {
  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.text} spacing={2}>
      <Typography variant="body1">Copyright © 2023 Baya Dynamics Ltd. All Rights Reserved.</Typography>
      <Typography variant="body1">
        All manufacturers, suppliers, names, brands, and associated imagery featured in this product are trademarks
        and/or copyrighted materials of their respective owners.
      </Typography>
      <Typography variant="body1">
        This product is a digital representation only and is not intended to be a simulation of real life. No
        identification with actual persons (living or deceased), places, buildings, and products is intended or should
        be inferred.
      </Typography>
    </Stack>
  );
};

export default LegalWarning;
