import { Stack, IconButton, Slider, Typography } from '@mui/material';
import { VolumeOff } from '@mui/icons-material';

const Volume = () => {
  return (
    <Stack pt={4} px={2} pb={2} spacing={3}>
      <Stack direction="row" spacing={4} alignItems="center">
        <IconButton>
          <VolumeOff />
        </IconButton>
        <Slider sx={{ width: '165px' }} disabled />
      </Stack>

      <Stack spacing={2}>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          Click this icon to enable / disable <span style={{ color: '#FFF' }}>audio</span> and use the{' '}
          <span style={{ color: '#FFF' }}>slider</span> to control the volume level.
        </Typography>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          <span style={{ color: '#FFF' }}> Please note:</span> this setting may be governed or overwritten by your
          operating system or device’s audio and volume settings.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Volume;
