const tips = [
  'Press the burger icon in the top left to reveal the menu bar where you can alter your real-time experience.',
  "You can select which interactions you want to see. Access the menu and go to 'Information' then 'Interactions'.",
  'If you get stuck or something goes wrong press the refresh button at the bottom of the menu bar to reset the stream.',
  "Movement too sensitive or not sensitive enough? Open the menu bar and go to 'General Settings' to change your preference.",
  'Sound is off by default. Use the volume slider in the menu bar to adjust the stream volume.',
  "You can alter the look and feel by changing scenarios and the time of day. Go to 'Environment' in the menu bar.",
  'Double click or tap on the floor to move to that location. You can adjust the movement speed from the sidebar.',
  'Prefer more movement freedom? Enter fly mode to move around the space like a drone.',
  "The 'Guided Tour' takes you on an automatic journey through the space. You can pause and resume the tour and don't forget you can look around while you follow the guided path.",
  "Use the 'Camera Mode' in the 'Tools' section to take beautiful 4K visuals downloaded straight to your device.",
];

export default tips;
