import { useState } from 'react';
import { Button, Stack, CircularProgress, useMediaQuery, Theme } from '@mui/material';

import { downloadFile } from 'utils';

import useStyles from 'hooks/useStyles';
import getStyles from './CameraScreenshotButton.styles';
import useStore from 'store/store';

const CameraScreenshotButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { emitUIInteraction, useWebRTCResponseEventListener } = useStore();

  useWebRTCResponseEventListener('Download', (response: any) => {
    if (response.ContentType === 'image/png') {
      const date = response.Date;
      downloadFile(response.URL, 'capture-' + date);
      setIsLoading(false);
    }
  });

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMobileLandscape = useMediaQuery((theme: Theme) => `(max-width: 1080px) and (max-height: 700px)`);
  const styles = useStyles(getStyles, isLoading);

  return (
    <Stack
      sx={styles.screenshotButtonContainer}
      direction={isMobile ? 'row' : 'column'}
      alignItems="center"
      justifyContent="flex-end"
    >
      <Button
        sx={styles.screenshotButton}
        onClick={() => {
          emitUIInteraction({ Interaction: 'UIButton', Capture: 'TakeCapture' });
          setIsLoading(true);
        }}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress
            sx={styles.screenshotButtonSpinner}
            size={isMobile || isMobileLandscape ? 59 : 72}
            thickness={2.5}
          />
        ) : null}
      </Button>
    </Stack>
  );
};

export default CameraScreenshotButton;
