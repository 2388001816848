import { Stack, Button } from '@mui/material';

interface Props {
  setIsVideoPlaying: any;
}

const TitlePageVideo = ({ setIsVideoPlaying }: Props) => {
  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
  };

  return (
    <Stack
      sx={(theme) => ({
        width: '100dvw',
        height: '100dvh',
        position: 'fixed',
        backgroundColor: '#1B1B1B',
        zIndex: theme.zIndex.loadingPage + 1,
      })}
    >
      <video
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        src="assets/videos/intro-video.webm"
        autoPlay
        muted
        onEnded={handleVideoEnd}
      ></video>
      <Button
        sx={{
          width: '100px',
          position: 'absolute',
          bottom: 30,
          right: 30,
          color: '#292929',
          backgroundColor: '#CCCCCC',
          '&:hover': {
            backgroundColor: '#D9D9D9',
          },
        }}
        onClick={handleVideoEnd}
      >
        Skip
      </Button>
    </Stack>
  );
};

export default TitlePageVideo;
