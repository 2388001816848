import { SxProps } from '@mui/material';

const getStyles = (): SxProps => ({
  select: {
    width: '210px',
    height: '40px',
    border: 'none',
    backgroundColor: '#555',
    '.MuiSelect-icon': {
      color: '#fff',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
});

export default getStyles;
