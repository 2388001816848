const spaRoutes = {
  controls: {
    label: 'Controls',
    route: 'controls',
    defaultRoute: 'options/movement-modes',
    secondaryNav: {
      touch: {
        label: 'Touch',
        route: 'touch',
        defaultRoute: 'walk',
        sideNav: {
          walkMode: { label: 'Walk mode - move and look', route: 'walk' },
          flyMode: { label: 'Fly mode - move and look', route: 'fly' },
        },
      },
      mouse: {
        label: 'Mouse',
        route: 'mouse',
        defaultRoute: 'walk',
        sideNav: {
          walkMode: { label: 'Walk mode - move and look', route: 'walk' },
          flyMode: { label: 'Fly mode - move and look', route: 'fly' },
        },
      },
      keyboard: {
        label: 'Keys',
        route: 'keyboard',
        defaultRoute: 'walk',
        sideNav: {
          walkMode: { label: 'Walk mode - move and look', route: 'walk' },
          flyMode: { label: 'Fly mode - move and look', route: 'fly' },
        },
      },
      options: {
        label: 'Options',
        route: 'options',
        defaultRoute: 'movement-modes',
        sideNav: {
          movementType: { label: 'Movement modes', route: 'movement-modes' },
          movementSpeed: { label: 'Movement speed', route: 'movement-speed' },
          lookSensitivity: { label: 'Look sensitivity', route: 'look-sensitivity' },
          fieldOfView: { label: 'Field of View', route: 'field-of-view' },
          walkModeEyeHeight: { label: 'Walk mode eye height', route: 'walk-mode-eye-height' },
          invertXorYAxis: { label: 'Invert x or y axis', route: 'invert-xy-axis' },
        },
      },
    },
  },
  settings: {
    label: 'Settings',
    route: 'settings',
    defaultRoute: 'general-settings/hide-nav',
    secondaryNav: {
      'general-settings': {
        label: 'General Settings',
        route: 'general-settings',
        defaultRoute: 'hide-nav',
        sideNav: {
          hideNav: { label: 'Hide nav on inactivity', route: 'hide-nav' },
          invertXYAxis: { label: 'Invert x or y axis', route: 'invert-xy-axis' },
          sidebarRight: { label: 'Sidebar on right', route: 'sidebar-on-right' },
          quickCapture: { label: 'Show quick capture', route: 'show-quick-capture' },
          lookSensitivity: { label: 'Look sensitivity', route: 'look-sensitivity' },
          fieldOfView: { label: 'Field of view', route: 'field-of-view' },
          walkModeEyeHeight: { label: 'Walk mode eye height', route: 'walk-mode-eye-height' },
        },
      },
    },
  },
  display: {
    label: 'Display',
    route: 'display',
    defaultRoute: 'main-icons/options-burger',
    secondaryNav: {
      'main-icons': {
        label: 'Main icons',
        route: 'main-icons',
        defaultRoute: 'options-burger',
        sideNav: {
          optionsBurger: { label: 'Options (Burger)', route: 'options-burger' },
          fullScreen: { label: 'Full screen', route: 'full-screen' },
          restart: { label: 'Restart', route: 'restart' },
          volume: { label: 'Volume', route: 'volume' },
        },
      },
    },
  },
  // information: {
  //   label: 'Information',
  //   route: 'information',
  //   defaultRoute: 'information',
  //   secondaryNav: {},
  // },
  // environment: {
  //   label: 'Environment',
  //   route: 'environment',
  //   defaultRoute: 'environment',
  //   secondaryNav: {},
  // },
  // wayfinding: {
  //   label: 'Wayfinding',
  //   route: 'wayfinding',
  //   defaultRoute: 'wayfinding',
  //   secondaryNav: {},
  // },
};

export default spaRoutes;
