export interface UpdateStateSlice {
  updateState: (updatedValues: any) => any;
}

export const createUpdateStateSlice = (set: any): UpdateStateSlice => ({
  updateState: (updatedValues: any) => {
    set((state: any) => {
      const newState = { ...state };

      Object.keys(updatedValues).forEach((key) => {
        if (newState[key]) {
          (newState[key] as any).value = updatedValues[key];
        }
      });

      return newState;
    });
  },
});

export default createUpdateStateSlice;
