import { Stack, IconButton, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';

const OptionsBurger = () => {
  return (
    <Stack pt={4} px={2} pb={2} spacing={3}>
      <IconButton color="inherit" aria-label="menu">
        <Menu />
      </IconButton>
      <Typography sx={{ color: '#A6A6A6' }} variant="body1">
        Click this icon to show / hide the option <span style={{ color: '#FFF' }}>sidebar</span>.
      </Typography>
    </Stack>
  );
};

export default OptionsBurger;
