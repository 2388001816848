import { useState } from 'react';
import { Stack, Typography } from '@mui/material';

import useStyles from 'hooks/useStyles';

import BuildInfo from 'sections/title/BuildInfo/BuildInfo';

import TitleLogo from 'sections/title/TitleLogo/TitleLogo';
import TitlePageVideo from '../TitlePageVideo/TitlePageVideo';

import rcLogo from 'assets/images/rc-logo.png';
import nclLogo from 'assets/images/ncl-logo.png';
import bwLogo from 'assets/images/bw-logo.svg';

import getStyles from './TitlePageMobile.styles';

const TitlePageMobile = ({ hideTitlePage, company, content }: any) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const styles = useStyles(getStyles);

  return (
    <>
      {isVideoPlaying && <TitlePageVideo setIsVideoPlaying={setIsVideoPlaying} />}
      <Stack sx={styles.titlePage} onClick={hideTitlePage} justifyContent="space-between">
        <Stack sx={styles.project} alignItems="center" spacing={3}>
          <img
            width="300"
            style={{ maxWidth: '100%', height: 'auto' }}
            src={company === 'bw' ? bwLogo : company === 'rc' ? rcLogo : nclLogo}
            alt=""
          />
          <Typography sx={{ color: '#fff' }} variant="h3">
            {content?.ship}
          </Typography>
          <Typography sx={{ color: '#fff' }} variant="h2">
            {content?.area}
          </Typography>
        </Stack>

        <Stack sx={styles.info} justifyContent="center" alignItems="center">
          <Typography variant="h3">tap or click to continue</Typography>
        </Stack>

        <Stack sx={styles.build} justifyContent="flex-end" alignItems="center" spacing={3}>
          <BuildInfo />
          <TitleLogo />
        </Stack>
      </Stack>
    </>
  );
};

export default TitlePageMobile;
