import { Stack, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

import useStyles from 'hooks/useStyles';
import getStyles from './ControlGroup.styles';

interface Props {
  icon?: any;
  label?: string;
  children: any;
}

const ControlGroup = ({ icon, label, children }: Props) => {
  const styles = useStyles(getStyles);

  return (
    <Accordion>
      <AccordionSummary sx={styles.header} expandIcon={<ArrowDropDown color="primary" />}>
        <Stack direction="row" alignItems="center">
          {icon}
          <Typography variant="body2" ml={icon && 2}>
            {label}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={styles.content}>
        <Stack sx={styles.buttons} spacing={1}>
          {children}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default ControlGroup;
