import { Stack, List, ListItem, ListItemText, Select, MenuItem, useMediaQuery, Theme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { scrollbar } from 'theme/globalStyles';

import useStore from 'store/store';
import { getRoutes } from 'config';

const PanelSidenav = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { shareId } = useStore();
  const routes = getRoutes(shareId);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMobileLandscape = useMediaQuery((theme: Theme) => `(max-width: 1080px) and (max-height: 700px)`);

  const previousPath = location.pathname.split('/').slice(0, 4).join('/');
  const firstRoute = location.pathname.split('/').slice(2, 3)[0];
  const secondRoute = location.pathname.split('/').slice(3, 4)[0];
  const thirdRoute = location.pathname.split('/').slice(4, 5)[0];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const sideNavConfig = routes[firstRoute].secondaryNav[secondRoute]?.sideNav;

  return (
    <Stack
      sx={{
        height: isMobileLandscape ? '80%' : '100%',
        overflowX: 'hidden',
        overflowY: isMobile ? 'hidden' : 'auto',
        ...scrollbar,
      }}
    >
      {isMobile ? (
        <Select
          onChange={(event) => navigate(`${previousPath}/${event.target.value}`)}
          value={thirdRoute}
          sx={{
            width: '340px',
            height: '35px',
            borderRadius: '4px',
            fontSize: '16px',
          }}
        >
          {sideNavConfig &&
            Object.values(sideNavConfig).map((option: any) => (
              <MenuItem key={option.route} value={option.route}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
      ) : (
        <List disablePadding>
          {sideNavConfig &&
            Object.values(sideNavConfig).map((option: any) => (
              <ListItem
                sx={{
                  height: '13px',
                  px: 2,
                  mb: 4,
                  borderLeft: thirdRoute === option.route ? '5px solid #D9D9D9' : '5px solid transparent',
                  color: thirdRoute === option.route ? '#CCCCCC' : '#555555',
                }}
                disablePadding
                key={option.route}
                component={Link}
                to={`${previousPath}/${option.route}`}
              >
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
        </List>
      )}
    </Stack>
  );
};

export default PanelSidenav;
