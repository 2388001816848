import { Theme, SxProps } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  loadingPage: {
    width: '100vw',
    height: '100dvh',
    position: 'fixed',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B1B1B',
    zIndex: theme.zIndex.loadingPage,
  },

  fullScreenButton: {
    position: 'fixed',
    top: 0,
    left: 0,
    mt: 2,
    ml: 2,
    zIndex: theme.zIndex.loadingPage + 1,

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      mt: 1,
      ml: 1,
    },
  },

  container: {
    width: '100%',
    height: '100%',
    mt: 10,
    mb: 6,
    zIndex: theme.zIndex.loadingPage,

    [theme.breakpoints.down('md')]: {
      mt: 2,
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      my: 1,
    },
  },

  logoMessages: {
    mt: 8,
    ml: 8,
    [theme.breakpoints.down('md')]: {
      ml: 5,
    },
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      mt: 1,
      ml: 8,
    },
  },
});

export default getStyles;
