import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme, isMobileLandscape: boolean): SxProps => ({
  logo: {
    p: 6,

    [theme.breakpoints.down('sm')]: {
      p: 0,
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      p: 0,
    },
  },

  image: {
    width: isMobileLandscape ? '200px' : '300px',
    maxWidth: '100%',
    height: 'auto',
  },
});

export default getStyles;
