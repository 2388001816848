import { createTheme as createMuiTheme } from '@mui/material';
import { Theme } from '@mui/material';
import { createPalette } from './createPalette';
import { createComponents } from './createComponents';
import { createShadows } from './createShadows';
import { createTypography } from './createTypography';

declare module '@mui/material/styles' {
  interface ZIndex {
    errorPage: number;
    legalHealthPage: number;
    loadingPage: number;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    inactive: true;
    active: true;
  }
}

declare module '@mui/material/Slider' {
  interface SliderPropsVariantOverrides {
    cameraMode: true;
  }
}

export const createTheme = (): Theme => {
  const palette = createPalette();
  const components = createComponents({ palette });
  const shadows = createShadows();
  const typography = createTypography();

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    components,
    palette,
    shadows,
    typography,
    shape: {
      borderRadius: 5,
    },
    zIndex: {
      mobileStepper: 1000,
      fab: 1050,
      speedDial: 1050,
      appBar: 1100,
      drawer: 1200,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500,
      loadingPage: 1310,
      legalHealthPage: 1320,
      errorPage: 1330,
    },
  });
};
