import { Box, useMediaQuery, Theme } from '@mui/material';

import useStyles from 'hooks/useStyles';
import bwLogo from 'assets/images/bw-logo.svg';
import getStyles from './TitleLogo.styles';

const TitleLogo = () => {
  const isMobileLandscape = useMediaQuery((theme: Theme) => `(max-width: 1080px) and (max-height: 700px)`);

  const styles = useStyles(getStyles, isMobileLandscape);

  return (
    <Box sx={styles.logo}>
      <img style={styles.image} src={bwLogo} alt="baya-weaver-logo" />
    </Box>
  );
};

export default TitleLogo;
