import axios from 'axios';
import environment from 'utils/environment';

const { REACT_APP_API_URL } = environment;

const instance = axios.create({
  baseURL: REACT_APP_API_URL,
});

export default instance;
