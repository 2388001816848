import { Theme, SxProps } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  buildInfo: {
    p: 6,

    [theme.breakpoints.down('sm')]: {
      p: 0,
    },
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      p: 0,
    },
  },
  text: {
    color: '#FFFFFF',
    textAlign: 'right',
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      fontSize: '12px',
    },
  },
});

export default getStyles;
