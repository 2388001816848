import { useState } from 'react';
import { Tabs, Tab, Box, useMediaQuery, Theme } from '@mui/material';
import { Link } from 'react-router-dom';

import useStore from 'store/store';
import { getRoutes } from 'config';

interface Props {
  isOpen: any;
  onClose: any;
}

const PanelNav = () => {
  const [title, setTitle] = useState(0);

  const { shareId } = useStore();
  const routes = getRoutes(shareId);

  const handleChangeTitle = (event: any, newValue: any) => {
    setTitle(newValue);
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: '#111',
        width: '100%',
      }}
    >
      <Tabs
        value={title}
        onChange={handleChangeTitle}
        variant={isMobile ? 'scrollable' : 'fullWidth'}
        scrollButtons
        allowScrollButtonsMobile
        sx={{
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        {Object.keys(routes).map((key) => {
          const option = routes[key as keyof typeof routes];
          return (
            <Tab
              key={option.route}
              component={Link}
              to={`/help/${option.route}/${option.defaultRoute}`}
              label={option.label}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default PanelNav;
