const s13Config = {
  sidebar: {
    Wayfinding: {
      movement: {
        ui: 'interaction',
        type: 'controlGroup',
        mainLabel: 'Movement',
        children: {
          movementSpeed: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: 0.5,
            objectPath: 'CallFunctionRequest.Arguments',
            object: {
              CallFunctionRequest: {
                FunctionName: 'SetMovementSpeed',
                Arguments: null,
              },
            },
            options: [
              {
                label: '>',
                value: 0.5,
              },
              {
                label: '>>',
                value: 1,
              },
              {
                label: '>>>',
                value: 1.5,
              },
              {
                label: '>>>>',
                value: 2,
              },
            ],
          },
          movement: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: 'walk',
            objectPath: 'CallFunctionRequest.Arguments',
            object: {
              CallFunctionRequest: {
                FunctionName: 'SetActivePawn',
                Arguments: null,
              },
            },
            options: [
              {
                label: 'walk',
                value: 'walk',
              },

              {
                label: 'fly',
                value: 'fly',
              },
            ],
          },
        },
      },
    },
    Environment: {
      scenario: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Scenario',
        orientation: 'horizontal',
        defaultValue: 'DAY',
        objectPath: 'VariantSwitch.VariantName',
        object: {
          VariantSwitch: {
            VariantSetName: 'DAYNIGHT',
            VariantName: null,
          },
        },
        options: [
          {
            label: 'day',
            value: 'DAY',
          },
          {
            label: 'night',
            value: 'NIGHT',
          },
        ],
      },
    },
    Tools: {
      cameraMode: {
        ui: 'interaction',
        type: 'button',
        mainLabel: 'Camera mode',
        defaultValue: null,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'SetCameraMode',
            Arguments: null,
          },
        },
      },
      measurementMode: {
        ui: 'interaction',
        type: 'button',
        mainLabel: 'Measurement Tool',
        defaultValue: null,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'ToggleMeasurementTool',
            Arguments: null,
          },
        },
      },
    },
    'General Settings': {
      ShouldHideSidebar: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Hide nav on inactivity',
        defaultValue: false,
        objectPath: 'GeneralSettings.ShouldHideSidebar',
      },
      InvertLookX: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Invert x-axis',
        defaultValue: false,
        objectPath: 'GeneralSettings.InvertLookX',
      },
      InvertLookY: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Invert y-axis',
        defaultValue: false,
        objectPath: 'GeneralSettings.InvertLookY',
      },
      isRightSidebar: {
        ui: 'interactionWeb',
        type: 'switch',
        mainLabel: 'Sidebar on right',
        defaultValue: false,
      },
      showQuickCapture: {
        ui: 'interactionWeb',
        type: 'switch',
        mainLabel: 'Show quick capture',
        defaultValue: false,
      },
      // showJoysticks: {
      //   ui: 'interactionWeb',
      //   type: 'switch',
      //   mainLabel: 'Show joysticks',
      // defaultValue: false,
      // },
      LookSensitivity: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Look sensitivity',
        defaultValue: 2,
        objectPath: 'GeneralSettings.LookSensitivity',
        options: [
          {
            label: 'low',
            value: 1,
          },
          {
            label: 'medium',
            value: 2,
          },
          {
            label: 'high',
            value: 3,
          },
        ],
      },
      FieldOfView: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Field of view',
        defaultValue: 75,
        objectPath: 'GeneralSettings.FieldOfView',
        options: [
          {
            label: 'low',
            value: 25,
          },
          {
            label: 'medium',
            value: 75,
          },
          {
            label: 'high',
            value: 100,
          },
        ],
      },
      eyeHeight: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Walk mode eye height',
        defaultValue: 160,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'SetPawnEyeHeight',
            Arguments: null,
          },
        },
        options: [
          {
            label: 'lounging',
            value: 90,
          },
          {
            label: 'sitting',
            value: 110,
          },
          {
            label: 'standing',
            value: 160,
          },
        ],
      },
    },
  },
};

export default s13Config;
