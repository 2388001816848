import { Stack, Typography } from '@mui/material';

import useStyles from 'hooks/useStyles';
import getStyles from './BuildInfo.styles';
import useStore from 'store/store';

const buildInfo = [
  { label: '/ build', value: 'version' },
  { label: '/ updated', value: 'date' },
  // { label: '/ source', value: 'source' },
];

const BuildInfo = () => {
  const { useWebRTCResponseEventListener, releaseInfo, setReleaseInfo } = useStore();

  useWebRTCResponseEventListener('ReleaseInfo', (response: any) => setReleaseInfo(response.payload));

  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.buildInfo} direction="row" spacing={3}>
      <Stack spacing={1}>
        {buildInfo.map((item, index) => (
          <Typography key={index} sx={styles.text} variant="body1">
            {releaseInfo[item.value as keyof typeof releaseInfo]}
          </Typography>
        ))}
      </Stack>

      <Stack spacing={1}>
        {buildInfo.map((item, index) => (
          <Typography key={index} sx={styles.text} variant="body1">
            {item.label}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

export default BuildInfo;
