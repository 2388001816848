import { useAuth0 } from '@auth0/auth0-react';
import environment from 'utils/environment';

const { REACT_APP_AUTH0_AUDIENCE, REACT_APP_IS_PUBLIC } = environment;

const useGetAccessToken = (scope: string | undefined = ''): (() => Promise<string | null>) => {
  const { getAccessTokenSilently } = useAuth0();

  const fetchAccessToken = async (): Promise<string | null> => {
    if (REACT_APP_IS_PUBLIC) {
      return null;
    }

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: { audience: REACT_APP_AUTH0_AUDIENCE, scope },
      });
      return accessToken;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  return fetchAccessToken;
};

export default useGetAccessToken;
