import { useState, useContext, createContext, ReactNode, useEffect } from 'react';
import styles from './FullScreenContext.module.css';

interface FullScreenContextValue {
  enterFullScreen: () => void;
  exitFullScreen: () => void;
  isFullScreenEnabled: boolean;
  isFullScreenSupported: boolean;
}

interface FullScreenProviderProps {
  children: ReactNode;
}

export const FullScreenContext = createContext<FullScreenContextValue>({
  enterFullScreen: () => null,
  exitFullScreen: () => null,
  isFullScreenEnabled: false,
  isFullScreenSupported: false,
});

const FullScreenProvider = ({ children }: FullScreenProviderProps) => {
  const [fsFix, setFsFix] = useState<boolean>(false);
  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(!!document.fullscreenElement);

  useEffect(() => {
    const updateFullScreenState = () => {
      setIsFullScreenEnabled(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', updateFullScreenState);

    return () => {
      document.removeEventListener('fullscreenchange', updateFullScreenState);
    };
  }, []);

  const fullScreenSizeFix = () => {
    setFsFix(true);
    setTimeout(() => setFsFix(false), 50);
  };

  const handleFullScreen = (action: 'enter' | 'exit') => {
    if (document.fullscreenEnabled) {
      if (action === 'enter') {
        document.documentElement.requestFullscreen().catch(console.error);
      } else {
        fullScreenSizeFix();
        document.exitFullscreen();
      }
    }
  };

  const enterFullScreen = () => handleFullScreen('enter');
  const exitFullScreen = () => handleFullScreen('exit');

  const isFullScreenSupported = document.fullscreenEnabled;

  return (
    <FullScreenContext.Provider value={{ enterFullScreen, exitFullScreen, isFullScreenEnabled, isFullScreenSupported }}>
      <div className={`${styles.fullScreen} ${fsFix ? styles.fsFix : ''}`}>{children}</div>
    </FullScreenContext.Provider>
  );
};

const useFullScreen = () => useContext(FullScreenContext);

export { FullScreenProvider, useFullScreen };
