import { Typography } from '@mui/material';

const Viewpoints = () => {
  return (
    <>
      <Typography>Viewpoints</Typography>
    </>
  );
};

export default Viewpoints;
