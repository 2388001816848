import { SxProps, Theme } from '@mui/material';
import { scrollbar } from 'theme/globalStyles';

const getStyles = (theme: Theme, isRightSectionVisible: boolean): SxProps => ({
  contentContainer: { height: '100%' },
  leftSection: {
    width: '300px',
    height: '100%',
    p: 4,
    boxSizing: 'border-box',
    backgroundColor: ' rgba(0, 0, 0, 0.75)',
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      width: '35%',
    },
  },
  selectedItem: { fontWeight: '400', color: '#DFB9B9' },
  textContainer: {
    maxHeight: '90%',
  },
  leftSectionText: { overflowY: 'scroll', ...scrollbar },
  showMore: { fontSize: '16px' },

  rightSection: {
    width: '490px',
    visibility: isRightSectionVisible ? 'visible' : 'hidden',
    backgroundColor: 'rgba(27, 27, 27, 0.75)',
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      width: '65%',
    },
  },
  rightSectionHeader: { p: 2, backgroundColor: 'rgba(17, 17, 17, 0.75)' },
  rightContent: {
    height: '100%',
    p: 4,
    backgroundColor: 'rgba(27, 27, 27, 0.75)',
    overflowY: 'scroll',
    ...scrollbar,
  },
  images: { display: 'flex', flexWrap: 'wrap', gap: 2 },
  image: { width: '180px', height: '180px' },
});

export default getStyles;
