import { Typography } from '@mui/material';

const GuidedTours = () => {
  return (
    <>
      <Typography>GuidedTours</Typography>
    </>
  );
};

export default GuidedTours;
