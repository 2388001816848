import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme): SxProps => {
  return {
    notificationSnackbar: {
      width: '50%',
      height: '40px',

      [theme.breakpoints.down('md')]: {
        width: 'auto',
        mb: 2,
      },
    },

    notificationSnackbarAlert: {
      width: '100%',

      [theme.breakpoints.down('md')]: {
        width: 'auto',
      },

      '& .MuiAlert-message': {
        color: 'white',
      },
    },
  };
};

export default getStyles;
