import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme, buttonsPerRow: number): SxProps => ({
  multiSelectButton: {
    flex: `1 1 ${100 / buttonsPerRow}%`,
    maxWidth: `${100 / buttonsPerRow - 3.4}%`,
    mr: 1,
    mb: 1,
    p: 0,
  },
});

export default getStyles;
