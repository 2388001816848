import { createDescriptor } from 'utils';

export interface SidebarSlice {
  interactions: { value: string[]; disabled: boolean };
  scenario: { value: string; disabled: boolean };
  'Time of Day': { value: string; disabled: boolean };
  spaces: { value: string; disabled: boolean };
  views: { value: string; disabled: boolean };
  movement: { value: string; disabled: boolean };
  movementSpeed: { value: number; disabled: boolean };
  guidedTours: { value: string; disabled: boolean };
  guidedToursSpeed: { value: number; disabled: boolean };
  ShouldHideSidebar: { value: boolean; disabled: boolean };
  InvertLookX: { value: boolean; disabled: boolean };
  InvertLookY: { value: boolean; disabled: boolean };
  LookSensitivity: { value: number; disabled: boolean };
  FieldOfView: { value: number; disabled: boolean };
  eyeHeight: { value: number; disabled: boolean };
  orbitModeBackground: { value: string; disabled: boolean };
  showMode: { value: string; disabled: boolean };
  inputMethod: { value: boolean; disabled: boolean };

  updateInteractions: (updatedValue: string[]) => void;
  updateScenario: (updatedValue: string) => void;
  updateTimeOfDay: (updatedValue: string) => void;
  updateSpaces: (updatedValue: string) => void;
  updateViews: (updatedValue: string) => void;
  updateMovement: (updatedValue: string) => void;
  updateMovementSpeed: (updatedValue: number) => void;
  updateGuidedTours: (updatedValue: string) => void;
  updateGuidedToursSpeed: (updatedValue: number) => void;
  updateShouldHideSidebar: (updatedValue: boolean) => void;
  updateInvertLookX: (updatedValue: boolean) => void;
  updateInvertLookY: (updatedValue: boolean) => void;
  updateLookSensitivity: (updatedValue: number) => void;
  updateFieldOfView: (updatedValue: number) => void;
  updateEyeHeight: (updatedValue: number) => void;
  updateOrbitModeBackground: (updatedValue: string) => void;
  updateShowMode: (updatedValue: string) => void;
  updateInputMethod: (updatedValue: boolean) => void;
}

type SetFunction = (callback: (state: SidebarSlice) => SidebarSlice) => void;

export const createSidebarSlice = (set: SetFunction, get: any): SidebarSlice => ({
  interactions: { value: ['Default'], disabled: false },
  scenario: { value: 'Welcome Aboard', disabled: false },
  'Time of Day': { value: 'Day', disabled: false },
  spaces: { value: 'option 1', disabled: false },
  views: { value: '', disabled: false },
  movement: { value: 'walk', disabled: false },
  movementSpeed: { value: 0.5, disabled: false },
  guidedTours: { value: '', disabled: false },
  guidedToursSpeed: { value: 1, disabled: false },
  ShouldHideSidebar: { value: false, disabled: false },
  InvertLookX: { value: false, disabled: false },
  InvertLookY: { value: false, disabled: false },
  LookSensitivity: { value: 2, disabled: false },
  FieldOfView: { value: 75, disabled: false },
  eyeHeight: { value: 160, disabled: false },
  orbitModeBackground: { value: 'environment grey', disabled: false },
  showMode: { value: '3D', disabled: false },
  inputMethod: { value: false, disabled: false },

  updateInteractions: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('interactions', updatedValue));
    set((state) => ({ ...state, interactions: { ...state.interactions, value: updatedValue } }));
  },
  updateScenario: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('scenario', updatedValue));
    set((state) => ({ ...state, scenario: { ...state.scenario, value: updatedValue } }));
  },
  updateTimeOfDay: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('Time of Day', updatedValue));
    set((state) => ({ ...state, 'Time of Day': { ...state['Time of Day'], value: updatedValue } }));
  },
  updateSpaces: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('spaces', updatedValue));
    set((state) => ({ ...state, spaces: { ...state.spaces, value: updatedValue } }));
    get().updateMovementSpeed(0.5);
  },
  updateViews: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('views', updatedValue));
    set((state) => ({ ...state, views: { ...state.views, value: updatedValue } }));
  },
  updateMovement: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('movement', updatedValue));
    set((state) => ({ ...state, movement: { ...state.movement, value: updatedValue } }));
    get().updateMovementSpeed(0.5);
  },
  updateMovementSpeed: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('movementSpeed', updatedValue));
    set((state) => ({ ...state, movementSpeed: { ...state.movementSpeed, value: updatedValue } }));
  },
  updateGuidedTours: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('guidedTours', updatedValue));
    set((state) => ({ ...state, guidedTours: { ...state.guidedTours, value: updatedValue } }));
    get().setIsGuidedTourMode(true);
  },
  updateGuidedToursSpeed: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('guidedToursSpeed', updatedValue));
    set((state) => ({ ...state, guidedToursSpeed: { ...state.guidedToursSpeed, value: updatedValue } }));
  },
  updateShouldHideSidebar: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('ShouldHideSidebar', updatedValue));
    set((state) => ({ ...state, ShouldHideSidebar: { ...state.ShouldHideSidebar, value: updatedValue } }));
  },
  updateInvertLookX: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('InvertLookX', updatedValue));
    set((state) => ({ ...state, InvertLookX: { ...state.InvertLookX, value: updatedValue } }));
  },
  updateInvertLookY: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('InvertLookY', updatedValue));
    set((state) => ({ ...state, InvertLookY: { ...state.InvertLookY, value: updatedValue } }));
  },
  updateLookSensitivity: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('LookSensitivity', updatedValue));
    set((state) => ({ ...state, LookSensitivity: { ...state.LookSensitivity, value: updatedValue } }));
  },
  updateFieldOfView: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('FieldOfView', updatedValue));
    set((state) => ({ ...state, FieldOfView: { ...state.FieldOfView, value: updatedValue } }));
  },
  updateEyeHeight: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('eyeHeight', updatedValue));
    set((state) => ({ ...state, eyeHeight: { ...state.eyeHeight, value: updatedValue } }));
  },
  updateOrbitModeBackground: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('orbitModeBackground', updatedValue));
    set((state) => ({ ...state, orbitModeBackground: { ...state.orbitModeBackground, value: updatedValue } }));
  },
  updateShowMode: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('showMode', updatedValue));
    set((state) => ({ ...state, showMode: { ...state.showMode, value: updatedValue } }));
  },
  updateInputMethod: (updatedValue) => {
    get().emitUIInteraction(createDescriptor('inputMethod', updatedValue));
    set((state) => ({ ...state, inputMethod: { ...state.inputMethod, value: updatedValue } }));
  },
});

export default createSidebarSlice;
