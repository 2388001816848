import { Stack, IconButton, Typography } from '@mui/material';
import { Menu, Refresh, Autorenew, Fullscreen } from '@mui/icons-material';

import { ButtonFullScreen, ButonIconCycle } from 'components/ui';
import AudioControls from './AudioControls/AudioControls';
import { useFullScreen } from 'contexts/FullScreenContext/FullScreenContext';
import useStore from 'store/store';
import { getProject } from '../../../config';

import bwLogo from 'assets/images/bw-logo.svg';

import useStyles from 'hooks/useStyles';
import getStyles from './SidebarHeader.styles';

const SidebarHeader = () => {
  const { isFullScreenSupported } = useFullScreen();
  const {
    shareId,
    emitUIInteraction,
    setIsSidebarOpen,
    showMode,
    updateShowMode,
    inputMethod,
    updateInputMethod,
    movement,
  } = useStore();

  const project = getProject(shareId);
  const isStingray = project === 'stingray';
  const isOrbit = movement.value === 'orbit';

  const handleRefresh = () => {
    emitUIInteraction({ CallFunctionRequest: { FunctionName: 'Restart' } });
    window.location.reload();
  };

  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.sidebarHeader} spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <IconButton onClick={() => setIsSidebarOpen(false)} color="inherit" aria-label="menu">
          <Menu />
        </IconButton>
        <img style={styles.sidebarHeaderLogo} src={bwLogo} alt="baya-weaver-logo" />
      </Stack>

      {isFullScreenSupported && (
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <ButtonFullScreen />
          <Typography variant="body2">Fullscreen</Typography>
        </Stack>
      )}

      {isStingray && isOrbit && (
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <ButonIconCycle
            onClick={updateShowMode}
            value={showMode.value}
            options={[
              { value: '2D', icon: <Typography>2D</Typography>, enabled: true },
              { value: '3D', icon: <Typography>3D</Typography>, enabled: false },
            ]}
            disabled={showMode.disabled}
          />
          <Typography color={showMode.disabled ? '#666' : '#FFF'} variant="body2">
            Switch Orbit 2D / 3D
          </Typography>
        </Stack>
      )}

      {isStingray && isOrbit && (
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <ButonIconCycle
            onClick={updateInputMethod}
            value={inputMethod.value}
            options={[
              { value: false, icon: <Autorenew />, enabled: false },
              { value: true, icon: <Fullscreen sx={{ transform: 'rotate(45deg)' }} />, enabled: true },
            ]}
            disabled={showMode.disabled}
          />
          <Typography color={showMode.disabled ? '#666' : '#FFF'} variant="body2">
            {inputMethod.value ? 'Orbit Pan' : 'Orbit Rotate'}
          </Typography>
        </Stack>
      )}

      <Stack direction="row" alignItems="center" spacing={1.5}>
        <IconButton onClick={handleRefresh}>
          <Refresh />
        </IconButton>
        <Typography variant="body2">Restart</Typography>
      </Stack>
      {!isStingray && <AudioControls />}
    </Stack>
  );
};

export default SidebarHeader;
