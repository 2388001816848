import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import { logger, googleAnalytics } from 'services';

import { environment } from 'utils';

import './index.css';

const { REACT_APP_ENVIRONMENT, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_AUDIENCE } =
  environment;
const isDev = REACT_APP_ENVIRONMENT === 'development';

!isDev && logger();
!isDev && googleAnalytics();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Auth0Provider
    domain={REACT_APP_AUTH0_DOMAIN}
    clientId={REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: REACT_APP_AUTH0_AUDIENCE,
    }}
  >
    <App />
  </Auth0Provider>,
);

reportWebVitals();
