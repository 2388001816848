import { withAuthenticationRequired } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import useStore from 'store/store';
import { useWebRTCClient } from 'services';
import useSetProjectData from 'store/hooks/useSetProjectData';
import useSetBaseURL from 'hooks/useSetBaseURL';
import { FullScreenProvider } from 'contexts/FullScreenContext/FullScreenContext';
import { SnackbarProvider } from 'contexts/SnackBarContext/SnackbarContext';
import useLegalHealthWarning from 'hooks/useLegalHealthWarning';
import { createTheme } from 'theme/index';
import { environment } from 'utils';

import AppUI from 'pages/AppUI/AppUI';
import HealthLegalPage from 'pages/HealthLegalPage/HealthLegalPage';
import LoadingPage from 'pages/LoadingPage/LoadingPage';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import NotificationSnackbar from 'components/ui/snackbar/NotificationSnackbar';
import useClearUrl from 'hooks/useClearUrl';

const { REACT_APP_IS_PUBLIC } = environment;

const AppContextConsumer = () => {
  const { error, isLoading, loadingMessages, showAppUi, sizeContainerRef, containerRef, videoRef, audioRef } =
    useStore();

  const { isLegalHealthVisible } = useLegalHealthWarning();

  const baseUrl = useSetBaseURL();
  useClearUrl();

  useSetProjectData();
  useWebRTCClient();

  return (
    <BrowserRouter basename={baseUrl}>
      <div ref={sizeContainerRef}>
        <div ref={containerRef}>
          <video ref={videoRef} />
          <audio ref={audioRef} />
          <ThemeProvider theme={createTheme()}>
            {isLegalHealthVisible && <HealthLegalPage />}
            {isLoading && <LoadingPage messages={loadingMessages} />}
            {!isLoading && showAppUi && <AppUI />}
            {error.isError && <ErrorPage errorMessage={error.message} type={error.type} />}
          </ThemeProvider>
        </div>
      </div>
    </BrowserRouter>
  );
};

const AppPublic = () => (
  <SnackbarProvider>
    <NotificationSnackbar />
    <FullScreenProvider>
      <AppContextConsumer />
    </FullScreenProvider>
  </SnackbarProvider>
);

const AppAuth = withAuthenticationRequired(() => (
  <SnackbarProvider>
    <NotificationSnackbar />
    <FullScreenProvider>
      <AppContextConsumer />
    </FullScreenProvider>
  </SnackbarProvider>
));

const App = REACT_APP_IS_PUBLIC ? AppPublic : AppAuth;
export default App;
