import { useState } from 'react';
import { Stack, Typography } from '@mui/material';

import useStyles from 'hooks/useStyles';

import rcLogo from 'assets/images/rc-logo.png';
import nclLogo from 'assets/images/ncl-logo.png';
import bwLogo from 'assets/images/bw-logo.svg';

import BuildInfo from 'sections/title/BuildInfo/BuildInfo';
import TitleLogo from 'sections/title/TitleLogo/TitleLogo';
import TitlePageVideo from '../TitlePageVideo/TitlePageVideo';

import getStyles from './TitlePageMobileLandscape.styles';

const TitlePageMobileLandscape = ({ hideTitlePage, company, content }: any) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const styles = useStyles(getStyles);

  return (
    <>
      {isVideoPlaying && <TitlePageVideo setIsVideoPlaying={setIsVideoPlaying} />}
      <Stack sx={styles.titlePage} onClick={hideTitlePage} direction="row" justifyContent="space-between">
        <Stack sx={styles.logo}>
          <TitleLogo />
        </Stack>

        <Stack sx={styles.project} alignItems="center" spacing={3}>
          <img
            width="250"
            style={{ maxWidth: '100%', height: 'auto' }}
            src={company === 'bw' ? bwLogo : company === 'rc' ? rcLogo : nclLogo}
            alt=""
          />
          <Typography sx={{ color: '#fff' }} variant="h3">
            {content?.ship}
          </Typography>
          <Typography sx={{ color: '#fff' }} variant="h2">
            {content?.area}
          </Typography>
          <Stack sx={styles.info} justifyContent="center" alignItems="center">
            <Typography variant="h3">tap or click to continue</Typography>
          </Stack>
        </Stack>

        <Stack sx={styles.build} justifyContent="flex-end" alignItems="flex-end">
          <BuildInfo />
        </Stack>
      </Stack>
    </>
  );
};

export default TitlePageMobileLandscape;
