import { SxProps, Theme } from '@mui/material';
import { scrollbar } from 'theme/globalStyles';

const getStyles = (theme: Theme): SxProps => ({
  mobileModalContent: {
    width: '260px',
    height: '100%',
    p: 4,
    boxSizing: 'border-box',
    backgroundColor: ' rgba(0, 0, 0, 0.75)',
  },
  selectedItem: { fontWeight: '400', color: '#DFB9B9' },
  select: { width: '180px' },
  image: { width: '180px', height: '180px' },
  text: { width: '180px', overflowY: 'scroll', ...scrollbar },
  closeButton: { width: '180px' },
  retailButton: {
    width: '180px',
    height: '40px',
    border: 'none',
    color: '#fff',
    backgroundColor: '#555',
    '.MuiSelect-icon': {
      color: '#fff',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
});

export default getStyles;
