import { useState, useEffect } from 'react';

import ConfiguratorPanelModal from './ConfiguratorPanelModal/ConfiguratorPanelModal';
import { configOptions } from './config';
import useStore from 'store/store';

const defaultState = {
  Pouffe: 'Pouffe01',
  'Wall Finish': 'WallFinish01',
  Artwork: 'Painting01',
  'Dining Table Setting': 'DT_Welcome_FlowerArr',
  'Dining Chairs': 'ChairBlue',
  'Sofa Fabric': 'SofaGreen',
  Armchair: 'Armchair01',
  'TV Channel': 'TV_Welcome_RitzVideo',
  'Balcony Furniture': 'Furniture01',
  'Power Sockets': 'Sockets01',
  Pillows: 'Pillows01',
  'Curtains & Sheers': 'Curtains_Welcome',
  Bed: 'BedRegular',
  'Wardrobe Clothes': 'ClothesEmpty',
};

const ConfiguratorPanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<keyof typeof defaultState>('Pillows');
  const [configuratorState, setConfiguratorState] = useState(defaultState);

  const { emitUIInteraction, useWebRTCResponseEventListener } = useStore();

  useEffect(() => {
    // Prevent user from remaining in frozen configurator state if tab closes or reloads
    const handleBeforeUnload = (event: any) => {
      event.returnValue = ''; // Chrome requires returnValue to be set
      emitUIInteraction({ State: 'Close' });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  useWebRTCResponseEventListener('OpenPanel', (response: any) => {
    if (response.Type === 'Config' && response.State === 'Open') {
      setIsOpen(true);
      setCurrentItem(response.Id);
    }
  });

  useWebRTCResponseEventListener('ConfiguratorState', (response: any) => {
    setConfiguratorState((prev) => ({ ...prev, ...response.configuratorState }));
  });

  const handleCloseModal = () => {
    setIsOpen(false);
    emitUIInteraction({ State: 'Close' });
  };

  const onChange = (name: string, value: string) => {
    setConfiguratorState((prev) => ({ ...prev, [name]: value }));
    emitUIInteraction({ VariantName: value });
  };

  return (
    <ConfiguratorPanelModal
      isOpen={isOpen}
      onClose={handleCloseModal}
      name={currentItem}
      value={configuratorState[currentItem]}
      onChange={onChange}
      options={configOptions[currentItem]}
      isRetail={currentItem === 'Pillows'}
    />
  );
};

export default ConfiguratorPanel;
