import { SxProps } from '@mui/material';

const getStyles = (): SxProps => ({
  sidebarHeader: {
    width: '250px',
    my: 1,
    pl: 1,
    background: '#1b1b1b',
    zIndex: 5,
  },

  sidebarHeaderLogo: {
    width: '166px',
    height: '22px',
  },
});

export default getStyles;
