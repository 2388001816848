import { Stack, Typography } from '@mui/material';

import useStyles from 'hooks/useStyles';
import getStyles from './HealthLegalPage.styles';

const HealthWarning = () => {
  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.text} spacing={2}>
      <Typography variant="h3" mb={1}>
        PHOTOSENSITIVE SEIZURE WARNING
      </Typography>
      <Typography variant="body1">
        A very small percentage of people may experience a seizure when exposed to certain visual images, including
        flashing lights or patterns that may appear in video games.
      </Typography>
      <Typography variant="body1">
        Even people who have no history of seizures or epilepsy may have an undiagnosed condition that can cause these
        “photosensitive epileptic seizures” while playing video games.
      </Typography>
      <Typography variant="body1">
        Immediately stop playing and consult a doctor if you experience any symptoms.
      </Typography>
    </Stack>
  );
};

export default HealthWarning;
