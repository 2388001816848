const gymConfig = {
  sidebar: {
    Wayfinding: {
      views: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Views',
        defaultValue: null,
        orientation: 'horizontal',

        objectPath: 'TeleportPlayer',
        options: [
          // {
          //   label: 'overhead',
          //   value: {
          //     location: {
          //       x: 770.20000000000005,
          //       y: -1427.2,
          //       z: 934.79999999999995,
          //     },
          //     rotation: {
          //       pitch: -90,
          //       yaw: 180,
          //       roll: 0,
          //     },
          //   },
          // },
          {
            label: 'view 1',
            value: {
              location: {
                x: 731.24400000000003,
                y: -1172.9290000000001,
                z: 154.40000000000001,
              },
              rotation: {
                pitch: -31.248244000000014,
                yaw: -137.42349500000003,
                roll: 1.8601306417146696e-15,
              },
            },
            thumbnail: '/assets/images/j35/views/view1.webp',
          },
          {
            label: 'view 2',
            value: {
              location: {
                x: 1002.147,
                y: -1817.6900000000001,
                z: 154.40000000000001,
              },
              rotation: {
                pitch: -11.360266000000021,
                yaw: 129.83196799999996,
                roll: 0,
              },
            },
            thumbnail: '/assets/images/j35/views/view2.webp',
          },
          {
            label: 'view 3',
            value: {
              location: {
                x: 749.697,
                y: -1018.6079999999999,
                z: 162.15700000000001,
              },
              rotation: {
                pitch: -17.843492000000023,
                yaw: 164.45934700000001,
                roll: 4.1766000443321987e-16,
              },
            },
            thumbnail: '/assets/images/j35/views/view3.webp',
          },
        ],
      },
      movement: {
        ui: 'interaction',
        type: 'controlGroup',
        mainLabel: 'Movement',
        children: {
          movementSpeed: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: 1,
            objectPath: 'CallFunctionRequest.Arguments',
            object: {
              CallFunctionRequest: {
                FunctionName: 'SetMovementSpeed',
                Arguments: null,
              },
            },
            options: [
              {
                label: '>',
                value: 1,
              },
              {
                label: '>>',
                value: 2,
              },
              {
                label: '>>>',
                value: 3,
              },
              {
                label: '>>>>',
                value: 4,
              },
            ],
          },
          movement: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: null,
            objectPath: 'CallFunctionRequest.Arguments',
            object: {
              CallFunctionRequest: {
                FunctionName: 'SetActivePawn',
                Arguments: null,
              },
            },
            options: [
              {
                label: 'walk',
                value: 'walk',
              },

              {
                label: 'fly',
                value: 'fly',
              },
            ],
          },
        },
      },
      guidedTours: {
        ui: 'interaction',
        type: 'controlGroup',
        mainLabel: 'Guided tours',
        defaultValue: 1,
        children: {
          guidedToursSpeed: {
            ui: 'interaction',
            type: 'singleSelect',
            objectPath: 'PlaybackSpeed',
            options: [
              {
                label: '>',
                value: 1,
              },
              {
                label: '>>',
                value: 1.5,
              },
              {
                label: '>>>',
                value: 1.75,
              },
              {
                label: '>>>>',
                value: 2,
              },
            ],
          },
          guidedTours: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: null,
            objectPath: 'TourNumber',
            options: [
              {
                label: 'start tour',
                value: 1,
                thumbnail: '/assets/images/j35/guided_tours/tour1.webp',
              },
            ],
          },
        },
      },
    },
    Environment: {
      'Time of Day': {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Time of Day',
        orientation: 'horizontal',
        defaultValue: 'Day',
        objectPath: 'TimeOfDay',
        options: [
          { label: 'day', value: 'Day' },
          { label: 'dusk', value: 'Dusk' },
          { label: 'night', value: 'Night' },
        ],
      },
    },
    Information: {
      interactions: {
        ui: 'interaction',
        type: 'multiSelect',
        mainLabel: 'Interactions',
        defaultValue: ['Default'],
        objectPath: 'FilterTargets',
        options: [
          { label: 'show all', value: 'ShowAll' },
          { label: 'doors', value: 'Default' },
          { label: 'furniture', value: 'Drawers' },
          { label: 'retail', value: 'ECommerce' },
          { label: 'options', value: 'Variant' },
          { label: 'control', value: 'Lights' },
          { label: 'hide all', value: 'HideAll' },
        ],
      },
    },
    Tools: {
      cameraMode: {
        ui: 'interaction',
        type: 'button',
        mainLabel: 'Camera mode',
        defaultValue: null,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'SetCameraMode',
            Arguments: null,
          },
        },
      },
      measurementMode: {
        ui: 'interaction',
        type: 'button',
        mainLabel: 'Measurement Tool',
        defaultValue: null,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'ToggleMeasurementTool',
            Arguments: null,
          },
        },
      },
    },
    'General Settings': {
      ShouldHideSidebar: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Hide nav on inactivity',
        defaultValue: false,
        objectPath: 'GeneralSettings.ShouldHideSidebar',
      },
      InvertLookX: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Invert x-axis',
        defaultValue: false,
        objectPath: 'GeneralSettings.InvertLookX',
      },
      InvertLookY: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Invert y-axis',
        defaultValue: false,
        objectPath: 'GeneralSettings.InvertLookY',
      },
      isRightSidebar: {
        ui: 'interactionWeb',
        type: 'switch',
        mainLabel: 'Sidebar on right',
        defaultValue: false,
      },
      showQuickCapture: {
        ui: 'interactionWeb',
        type: 'switch',
        mainLabel: 'Show quick capture',
        defaultValue: false,
      },
      // showJoysticks: {
      //   ui: 'interactionWeb',
      //   type: 'switch',
      //   mainLabel: 'Show joysticks',
      // defaultValue: false,

      // },
      LookSensitivity: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Look sensitivity',
        defaultValue: 2,
        objectPath: 'GeneralSettings.LookSensitivity',
        options: [
          {
            label: 'low',
            value: 1,
          },
          {
            label: 'medium',
            value: 2,
          },
          {
            label: 'high',
            value: 3,
          },
        ],
      },
      FieldOfView: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Field of view',
        defaultValue: 75,
        objectPath: 'GeneralSettings.FieldOfView',
        options: [
          {
            label: 'low',
            value: 25,
          },
          {
            label: 'medium',
            value: 75,
          },
          {
            label: 'high',
            value: 100,
          },
        ],
      },
      eyeHeight: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Walk mode eye height',
        defaultValue: 160,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'SetPawnEyeHeight',
            Arguments: null,
          },
        },
        options: [
          {
            label: 'lounging',
            value: 90,
          },
          {
            label: 'sitting',
            value: 110,
          },
          {
            label: 'standing',
            value: 160,
          },
        ],
      },
    },
  },
};

export default gymConfig;
