import { createRef, RefObject, MutableRefObject } from 'react';

export interface WebRTCClientSlice {
  emitUIInteraction: any;
  useWebRTCResponseEventListener: any;
  isLoading: boolean;
  loadingMessages: string[];
  sizeContainerRef: MutableRefObject<HTMLDivElement> | null;
  containerRef: MutableRefObject<HTMLDivElement> | null;
  videoRef: MutableRefObject<HTMLVideoElement> | null;
  audioRef: MutableRefObject<HTMLAudioElement> | null;

  setEmitUIInteraction: (newFunction: any) => void;
  setUseWebRTCResponseEventListener: (newFunction: any) => void;
  setIsLoading: (value: boolean) => void;
  setLoadingMessages: (message: string) => void;
}

type SetFunction = (callback: (state: WebRTCClientSlice) => WebRTCClientSlice) => void;

export const createWebRTCClientSlice = (set: SetFunction): WebRTCClientSlice => ({
  emitUIInteraction: () => null,
  useWebRTCResponseEventListener: () => null,
  isLoading: true,
  loadingMessages: [],
  sizeContainerRef: createRef() as MutableRefObject<HTMLDivElement>,
  containerRef: createRef() as MutableRefObject<HTMLDivElement>,
  videoRef: createRef() as MutableRefObject<HTMLVideoElement>,
  audioRef: createRef() as MutableRefObject<HTMLAudioElement>,

  setEmitUIInteraction: (newFunction) => set((state) => ({ ...state, emitUIInteraction: newFunction })),
  setUseWebRTCResponseEventListener: (newFunction) =>
    set((state) => ({ ...state, useWebRTCResponseEventListener: newFunction })),
  setIsLoading: (value) => set((state) => ({ ...state, isLoading: value })),
  setLoadingMessages: (message) => set((state) => ({ ...state, loadingMessages: [message, ...state.loadingMessages] })),
});
