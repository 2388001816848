import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme, isLoading: boolean): SxProps => ({
  screenshotButtonContainer: {
    position: 'fixed',
    right: 56,
    top: '50%',
    transform: 'translateY(-50%)',

    [theme.breakpoints.down('sm')]: {
      right: 'inherit',
      top: 'inherit',
      bottom: 25,
      left: '50%',
      transform: 'translateX(-50%)',
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      right: 32.5,
    },
  },

  screenshotButton: {
    width: isLoading ? '63px' : '68px',
    height: isLoading ? '63px' : '68px',
    padding: '3px',
    position: 'relative',
    border: isLoading ? 'none' : '5px solid white',
    borderRadius: '50%',
    backgroundClip: 'content-box',
    backgroundColor: '#fff',
    pointerEvents: 'auto',

    '&:hover': {
      backgroundColor: '#ddd',
    },

    '&:disabled': {
      backgroundColor: '#fff',
    },

    [theme.breakpoints.down('sm')]: {
      width: isLoading ? '50px' : '55px',
      height: isLoading ? '50px' : '55px',
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      width: isLoading ? '50px' : '55px',
      height: isLoading ? '50px' : '55px',
    },
  },
  screenshotButtonSpinner: {
    color: '#fff',
    position: 'absolute',
  },
});

export default getStyles;
