import { useFetchProject } from 'apiClient/projectAPI';
import { environment } from 'utils';
import useStore from 'store/store';
import { useEffect } from 'react';

const useSetProjectData = () => {
  const setProjectData = useStore((state) => state.setProjectData);

  const { REACT_APP_IS_PUBLIC } = environment;
  const url = window.location.pathname.split('/')[1];
  const projectId = decodeURIComponent(url);

  const projectData = useFetchProject(projectId, REACT_APP_IS_PUBLIC);

  useEffect(() => {
    if (projectData) {
      const { shareId, showAppUi = true } = projectData;

      setProjectData(shareId, showAppUi);
    }
  }, [projectData]);
};

export default useSetProjectData;
