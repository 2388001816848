import { Stack, Link, Typography, Button } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';

import useStyles from 'hooks/useStyles';

import getStyles from './SidebarFooter.styles';

const SidebarFooter = () => {
  const currentYear = new Date().getFullYear();
  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.footer}>
      <Stack sx={styles.footerLinks} direction="row">
        <Typography sx={styles.footerText} variant="caption">
          {`© ${currentYear} Baya Dynamics Ltd`}
        </Typography>

        <Typography sx={styles.footerDivider} variant="caption">
          |
        </Typography>

        <Link href="mailto:support@bayadynamics.com" underline="none">
          <Stack sx={styles.footerLink} direction="row" spacing={0.5}>
            <Typography sx={styles.footerText} variant="caption">
              Support
            </Typography>
            <OpenInNew sx={styles.footerIcon} />
          </Stack>
        </Link>
        {/* 
        <Typography sx={styles.footerDivider} variant="caption">
          |
        </Typography> */}

        {/* <Link href="#" underline="none">
          <Stack sx={styles.footerLink} direction="row" spacing={0.5}>
            <Typography sx={styles.footerText} variant="caption">
              Legal
            </Typography>
            <OpenInNew sx={styles.footerIcon} />
          </Stack>
        </Link> */}
      </Stack>
    </Stack>
  );
};

export default SidebarFooter;
