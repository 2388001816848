import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  cameraClose: {
    position: 'absolute',
    top: '40px',
    right: '50%',
    transform: 'translateX(50%)',
    pointerEvents: 'auto',

    [theme.breakpoints.down('sm')]: {
      bottom: '50%',
      right: '10px',
      transform: 'translateY(50%)',
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      top: '10px',
      right: '50%',
      transform: 'translateX(50%)',
    },
  },
  button: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: '40px',
    height: '40px',
    mb: 1,
  },
});

export default getStyles;
