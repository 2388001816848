import { Snackbar, Alert } from '@mui/material';

import { useSnackbar } from 'contexts/SnackBarContext/SnackbarContext';

import useStyles from 'hooks/useStyles';
import getStyles from './NotificationSnackbar.styles';

const NotificationSnackbar = () => {
  const { isSnackbarOpen, snackbarMessage, hideSnackbar, severity, duration } = useSnackbar();

  const styles = useStyles(getStyles);

  return (
    <Snackbar
      sx={styles.notificationSnackbar}
      open={isSnackbarOpen}
      autoHideDuration={duration}
      onClose={hideSnackbar}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <Alert sx={styles.notificationSnackbarAlert} onClose={hideSnackbar} severity={severity} variant="filled">
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default NotificationSnackbar;
