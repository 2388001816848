import { LoveLetterEasterEgg, LoveLetter, CustomLoveLetter } from 'types/enums';

const isMessageEasterEgg = (message: string): boolean => {
  const enumValues = Object.values(LoveLetterEasterEgg);

  return enumValues.some((value) => message.includes(value));
};

const replaceMessage = (message: string): string | null => {
  const messageKey = Object.keys(LoveLetter).find((key) => LoveLetter[key as keyof typeof LoveLetter] === message);

  if (messageKey) {
    return CustomLoveLetter[messageKey as keyof typeof CustomLoveLetter] || null;
  }

  return null;
};

const addCustomLoadingMessages = (message: string): string | null => {
  if (isMessageEasterEgg(message)) {
    return null;
  }

  const replacedMessage = replaceMessage(message);

  if (replacedMessage) {
    return replacedMessage;
  }

  return null;
};

export default addCustomLoadingMessages;
