import { SxProps } from '@mui/material';

const getStyles = (): SxProps => ({
  footer: {
    m: 'auto',
    mb: 0,
    py: 2,
    alignItems: 'center',
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      py: 1,
    },
  },

  footerLinks: {
    alignItems: 'center',
  },

  footerLink: {
    height: '14px',
    justifyContent: 'center',
    alignItems: 'center',
  },

  footerText: {
    color: '#555',
  },

  footerIcon: {
    height: '15px',
    fontSize: '10px',
    color: '#555',
  },

  footerDivider: { py: 0, px: 0.7, color: '#292929' },
});

export default getStyles;
