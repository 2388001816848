import { Theme, SxProps } from '@mui/material';

const getStyles = (theme: Theme): SxProps => {
  const desktopMultiplier = 1;
  const mobileLandscapeMultiplier = 0.8;
  const mobileMultiplier = 0.9;

  const getMultiplier = () => {
    if (window.matchMedia('(min-width: 960px)').matches) {
      return desktopMultiplier;
    } else if (window.matchMedia('(max-width: 1080px) and (max-height: 700px) and (orientation: landscape)').matches) {
      return mobileLandscapeMultiplier;
    } else {
      return mobileMultiplier;
    }
  };

  const multiplier = getMultiplier();

  return {
    messages: {
      width: '580px',
      position: 'relative',
      mt: 3,
      mb: 1,

      [theme.breakpoints.down('md')]: {
        width: '100%',
        mt: 2,
      },

      '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
        width: '580px',
        mt: 2,
      },
    },

    message: (index) => ({
      '@keyframes first': {
        from: {
          opacity: 0,
        },
        to: {
          opacity: 1,
        },
      },
      '@keyframes second': {
        from: {
          transform: `translateY(0)`,
          opacity: 1,
        },
        to: {
          transform: `translateY(${40 * multiplier}px)`,
          opacity: 0.6,
        },
      },
      '@keyframes third': {
        from: {
          transform: `translateY(${40 * multiplier}px)`,
          opacity: 0.6,
        },
        to: {
          transform: `translateY(${80 * multiplier}px)`,
          opacity: 0.3,
        },
      },
      '@keyframes fourth': {
        from: {
          transform: `translateY(${80 * multiplier}px)`,
          opacity: 0.3,
        },
        to: {
          transform: `translateY(${120 * multiplier}px)`,
          opacity: 0,
        },
      },

      display: (index as number) >= 4 ? 'none' : 'block',
      position: 'absolute',
      width: '100%',
      opacity: 0,
      lineHeight: 1,
      color: '#fff',
      animation: `${
        index === 0 ? 'first' : index === 1 ? 'second' : index === 2 ? 'third' : 'fourth'
      } 0.5s ease forwards`,

      [theme.breakpoints.down('md')]: {
        fontSize: '15px',
      },

      '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
        fontSize: '15px',
      },
    }),
  };
};

export default getStyles;
