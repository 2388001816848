import { useEffect, useState } from 'react';
import { Stack, IconButton } from '@mui/material';
import { PlayArrow, Pause, Stop } from '@mui/icons-material';

import Slider from 'components/ui/sliders/Slider/Slider';
import useStore from 'store/store';

import useStyles from 'hooks/useStyles';
import getStyles from './GuidedTourControls.style';

const GuidedTourControls = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [timeline, setTimeline] = useState(-33);
  const [frameLength, setFrameLength] = useState([-33, 2653]);

  const { emitUIInteraction, useWebRTCResponseEventListener, isGuidedTourMode, setIsGuidedTourMode } = useStore();

  useEffect(() => {
    if (isGuidedTourMode) {
      setIsPlaying(true);
    }
  }, [isGuidedTourMode]);

  useWebRTCResponseEventListener('FirstLastNumOfFrame', (response: any) => {
    setFrameLength([Number(response.payload.FirstF), Number(response.payload.LastF)]);
  });

  useWebRTCResponseEventListener('GuidedTourProgress', (response: any) => {
    setTimeline(response.payload.frameNumber.value);
  });

  const handlePlayPause = () => {
    if (isPlaying) {
      emitUIInteraction({ AnimationState: 'Pause' });
    } else {
      emitUIInteraction({ AnimationState: 'Play' });
    }

    setIsPlaying((prev) => !prev);
  };

  const handleStop = () => {
    setIsGuidedTourMode(false);
    setIsPlaying(false);
  };

  const handleChangeTimeline = (event: any) => {
    const value = event.target.value;

    if (!isPlaying) {
      setTimeline(value);
    }

    emitUIInteraction({
      SetPlaybackPosition: {
        frame: {
          frameNumber: {
            value,
          },
          subFrame: 0,
        },
        time: 0,
        markedFrame: '',
        positionType: 'Frame',
        updateMethod: 'Play',
        bHasJumped: false,
      },
    });
  };

  const styles = useStyles(getStyles);

  if (isGuidedTourMode) {
    return (
      <Stack sx={styles.guidedTourControls} spacing={2}>
        <Stack direction="row" spacing={2} justifyContent="center">
          <IconButton onClick={handlePlayPause}>{isPlaying ? <Pause /> : <PlayArrow />}</IconButton>
          <IconButton onClick={handleStop}>
            <Stop />
          </IconButton>
        </Stack>
        <Slider
          sx={styles.slider}
          min={frameLength[0]}
          max={frameLength[1]}
          step={0.1}
          value={timeline}
          onChange={handleChangeTimeline}
        />
      </Stack>
    );
  } else {
    return null;
  }
};

export default GuidedTourControls;
