import { Stack, IconButton, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';

const Restart = () => {
  return (
    <Stack pt={4} px={2} pb={2} spacing={3}>
      <IconButton color="inherit" aria-label="menu">
        <Refresh />
      </IconButton>
      <Typography sx={{ color: '#A6A6A6' }} variant="body1">
        Click this icon to <span style={{ color: '#FFF' }}>restart</span> the app back to the beginning if you run into
        any issues or get stuck.
      </Typography>
    </Stack>
  );
};

export default Restart;
