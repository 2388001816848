const areAllOptionsSelected = (values: any[], options: any[]) => {
  const calculateOptionsLength = (options: any[]) => {
    return options.reduce((acc, option) => {
      if (option.value !== 'ShowAll' && option.value !== 'HideAll') {
        return acc + (Array.isArray(option.value) ? option.value.length : 1);
      }
      return acc;
    }, 0);
  };

  const calculateSelectedLength = (values: string[], options: any[]) => {
    return options.reduce((acc, option) => {
      if (Array.isArray(option.value)) {
        return acc + option.value.filter((value: string) => values.includes(value)).length;
      } else {
        return acc + (values.includes(option.value) ? 1 : 0);
      }
    }, 0);
  };

  const optionsLength = calculateOptionsLength(options);
  const selectedLength = calculateSelectedLength(values, options);

  return selectedLength === optionsLength;
};

export default areAllOptionsSelected;
