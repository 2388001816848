import useStore from 'store/store';

const useUpdateSidebarStore = () => {
  const { useWebRTCResponseEventListener, updateState, updateTimeOfDay } = useStore();

  useWebRTCResponseEventListener('GeneralSettings', (response: any) => {
    updateState(response.GeneralSettings);
  });

  useWebRTCResponseEventListener('ConfiguratorState', (response: any) => {
    updateTimeOfDay(response.configuratorState['Time of Day']);
  });
};

export default useUpdateSidebarStore;
