import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import PanelNav from './PanelNav/PanelNav';
import PanelSecondaryNav from './PanelNav/PanelSecondaryNav/PanelSecondaryNav';
import PanelSidenav from './PanelNav/PanelSidenav/PanelSidenav';
import PanelContent from './PanelContent/PanelContent';

import useStore from 'store/store';
import useStyles from 'hooks/useStyles';
import getStyles from './HelpPanel.styles';

const HelpPanel = () => {
  const { isHelpPanelOpen, setIsHelpPanelOpen } = useStore();

  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMobileLandscape = useMediaQuery((theme: Theme) => `(max-width: 1080px) and (max-height: 700px)`);

  const handleCloseModal = () => {
    setIsHelpPanelOpen(false);
    navigate('/');
  };

  const styles = useStyles(getStyles);

  return (
    <Dialog
      open={isHelpPanelOpen}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: styles.helpPanel }}
      fullScreen={isMobile || isMobileLandscape}
      scroll="paper"
    >
      <DialogTitle sx={styles.title}>
        <PanelNav />
        <PanelSecondaryNav />
      </DialogTitle>
      <DialogContent sx={styles.contentContainer}>
        <Stack direction={isMobile ? 'column' : 'row'} sx={{ height: '100%' }}>
          <Stack sx={styles.sideNav} direction="row" justifyContent="center">
            <PanelSidenav />
          </Stack>
          <Stack sx={styles.content}>
            <PanelContent />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <IconButton onClick={handleCloseModal} color="primary">
          <Close />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default HelpPanel;
