import { Stack, Typography, List, ListItem } from '@mui/material';
import CustomAlert from 'components/ui/alert/InfoPanelAlert';

const CustomListItem = ({ children }: any) => (
  <ListItem sx={{ typography: 'body1' }} disableGutters>
    <Stack direction="row">
      <Typography pr={1}>•</Typography>
      {children}
    </Stack>
  </ListItem>
);

const infoPanels = {
  Info1: {
    title: 'SAUNA',
    text: (
      <>
        <Typography variant="body1" mb={3}>
          The health benefits of the traditional Sauna promotes increased circulation, and relaxes aching muscles. This
          dry heat treatment is +80ºC (Adjustable from +60-90ºC).
        </Typography>

        <Typography variant="h6">Safety Information</Typography>
        <List>
          <CustomListItem disablePadding>Do not wear eyeglasses, watches or jewelry in the Sauna.</CustomListItem>
          <CustomListItem disablePadding>
            It is recommended that sandals be worn while utilizing the facility.
          </CustomListItem>
          <CustomListItem disablePadding>Max Occupancy: 7</CustomListItem>
        </List>

        <CustomAlert severity="warning">
          <Typography component="h6">Caution</Typography>
          <List>
            <CustomListItem disablePadding>Extended exposure to heat may be detrimental to your health.</CustomListItem>
            <CustomListItem disablePadding>
              Persons with heart conditions, diabetes, high or low blood pressure, or any other medical condition should
              check with their physician before using this facility.
            </CustomListItem>
            <CustomListItem disablePadding>Pregnant women should not use this facility.</CustomListItem>
            <CustomListItem disablePadding>Drink water after using the Sauna.</CustomListItem>
            <CustomListItem disablePadding>No liquids other than water shall be placed in the Sauna.</CustomListItem>
          </List>
        </CustomAlert>
      </>
    ),
  },
  Info2: {
    title: 'AROMATIC STEAM ROOM',
    text: (
      <>
        <Typography variant="body1" mb={3}>
          Our aromatic temperature controlled steam room provides the perfect relaxing warmth of plant essence infused
          steam. Inhale and feel the benefits of relaxing or stimulation herbal essences according to your mood. Let the
          controlled 110˚- 120˚F (45˚-50˚C) soothe and invigorate you.
        </Typography>
        <Typography variant="h6">Safety Information</Typography>
        <List>
          <CustomListItem disablePadding>Do not wear eyeglasses, watches or jewelry in the steam bath.</CustomListItem>
          <CustomListItem disablePadding>
            It is recommended that sandals be worn while utilizing the facility.
          </CustomListItem>
          <CustomListItem disablePadding>Max Occupancy: 8</CustomListItem>
        </List>

        <CustomAlert severity="warning">
          <Typography variant="h6">Caution</Typography>
          <List>
            <CustomListItem disablePadding>Extended exposure to heat may be detrimental to your health.</CustomListItem>
            <CustomListItem disablePadding>
              Persons with heart conditions, diabetes, high or low blood pressure, or any other medical condition should
              check with their physician before using this facility.
            </CustomListItem>
            <CustomListItem disablePadding>Pregnant women should not use this facility.</CustomListItem>
            <CustomListItem disablePadding>Drink water after using the steam bath.</CustomListItem>
          </List>
        </CustomAlert>

        <Typography variant="body1" textAlign="center">
          USE CALL BUTTON FOR EMERGENCY.
        </Typography>
      </>
    ),
  },
  Info3: {
    title: 'RAINFALL RITUAL',
    text: (
      <>
        <Typography component="p" mb={3}>
          {`Introducing the Rainfall Ritual - an exceptional system of automated showers that will transform your daily
        routine into a rejuvenating journey of wellness. Carefully orchestrated to provide maximum health benefits, the
        Rainfall Ritual is not just a shower; it's a sanctuary of serenity and vitality. With the Rainfall Ritual, you
        can effortlessly transition from a deeply relaxing, invigorating hot experience to an exhilarating and
        revitalizing cold one. This ingenious program offers a spectrum of benefits to elevate your body and mind, one
        shower at a time.`}
        </Typography>
        <Typography component="p" mb={3}>
          {`But the journey doesn't end there. Finish your rainfall ritual with a foot massage as you walk
        over our pebble path. This soothing and tactile experience rejuvenates tired feet and enhances your overall
        sense of well-being. The Rainfall Ritual is uniquely customizable with three levels of comfort to suit your
        desired experience:`}
        </Typography>
        <Typography component="p" mb={3}>
          {`The Rainfall Ritual is uniquely customizable with three levels of comfort to suit your
        desired experience:`}
        </Typography>

        <Typography variant="h6">CALM</Typography>
        <Typography mb={3}>
          For our most gentle transition, perfect for those seeking a serene and soothing experience.
        </Typography>
        <Typography variant="h6">TEMPERATE</Typography>
        <Typography mb={3}>
          Ideal for modest transitions between hot and cold, offering a balanced approach to wellness.
        </Typography>
        <Typography variant="h6">INVIGORATING</Typography>
        <Typography mb={3}>
          Designed for the most adventurous souls, ready to test their limits and embrace the full spectrum of hot and
          cold therapy.
        </Typography>
      </>
    ),
  },
  Info4: {
    title: 'ICE ROOM',
    text: (
      <>
        <Typography variant="body1" mb={3}>
          {`          After a hot sauna or steam bath, cool off in the refreshing Ice Room. The cold air helps to increase blood
          circulation, awaken the senses, invigorate the respiratory tract, and strengthen the immune system. With the
          temperature at a stimulating +54˚F (+12˚C) adjustable from ~+7->, we recommend you limit your visit to a maximum
          of 2 minutes.`}
        </Typography>

        <Typography variant="h6">Safety Information</Typography>
        <List>
          <CustomListItem disablePadding>Do not wear eyeglasses, watches or jewelry in the Ice Room.</CustomListItem>
          <CustomListItem disablePadding>
            Footwear must be worn at all times while utilizing this facility.
          </CustomListItem>
          <CustomListItem disablePadding>No liquids in the Ice Room.</CustomListItem>
          <CustomListItem disablePadding>
            Children under the age of 16 must be accompanied by an adult at all times.
          </CustomListItem>
          <CustomListItem disablePadding>Maximum Capacity for this facility: 6</CustomListItem>
        </List>

        <CustomAlert severity="warning">
          <Typography variant="h6">Caution</Typography>
          <List>
            <CustomListItem disablePadding>Extended exposure to cold may be detrimental to your health.</CustomListItem>
            <CustomListItem disablePadding>
              Persons with heart conditions, diabetes, high or low blood pressure, or any other medical condition should
              check with their physician before using this facility.
            </CustomListItem>
            <CustomListItem disablePadding>Pregnant women should not use this facility.</CustomListItem>
            <CustomListItem disablePadding>Please exercise caution when walking through the facility.</CustomListItem>
            <CustomListItem disablePadding>Use this facility at your own risk.</CustomListItem>
          </List>
        </CustomAlert>
      </>
    ),
  },
  Info5: {
    title: 'PLUNGE POOL',
    text: (
      <>
        <Typography variant="h6" mb={3}>
          Cold Therapy Experiences
        </Typography>
        <Typography variant="body1" mb={3}>
          Cold Therapy experiences help reinvigorate the body. This experience also includes benefits relating to an
          increased in muscle recovery, enhanced immunity, mood improvement, energy, and improved skin health.
        </Typography>

        <Typography variant="h6">Safety Information</Typography>
        <List>
          <CustomListItem disablePadding>No lifeguard on Duty.</CustomListItem>
          <CustomListItem disablePadding>No Jumping or Diving.</CustomListItem>
          <CustomListItem disablePadding>Running is not permitted in the Pool Area.</CustomListItem>
          <CustomListItem disablePadding>No beverages, food or glass permitted.</CustomListItem>
          <CustomListItem disablePadding>Shower before entering the facility.</CustomListItem>
          <CustomListItem disablePadding>
            Children under 12 require adult supervision at all times while using the plunge pool.
          </CustomListItem>
          <CustomListItem disablePadding>No diapers or children who are not toilet trained allowed.</CustomListItem>
          <CustomListItem disablePadding>Please use handrails when entering and exiting the pools.</CustomListItem>
          <CustomListItem disablePadding>Surrounding surfaces may be wet.</CustomListItem>
          <CustomListItem disablePadding>
            Please use caution when walking around the pool areas, the surfaces may be wet.
          </CustomListItem>
          <CustomListItem disablePadding>
            Do not use the facilities if you are experiencing diarrhea, vomiting, or fever.
          </CustomListItem>
          <CustomListItem disablePadding>
            Do not use while under the influence of alcohol or after a meal.
          </CustomListItem>
          <CustomListItem disablePadding>
            Children, pregnant women, elderly persons, individuals on medications or who have underlying medical
            conditions, those suffering from heart ailments such as cardiovascular disease, diabetes, high or low blood
            pressure, anyone using anticoagulants, vasoconstrictors, vasodilators, stimulants, hypnotics, narcotics,
            tranquilizers and those who are immune compromised, are warned against using the plunge pool.
          </CustomListItem>
          <CustomListItem disablePadding>Exceeding 15 minutes of exposure is not recommended.</CustomListItem>
          <CustomListItem disablePadding>Bather load # Max. 1</CustomListItem>
          <CustomListItem disablePadding>{`Temperature: +54ºF (+12ºC) Adjustable from ~+7oC-`}.</CustomListItem>
          <CustomListItem disablePadding>Use the Plunge Pool at Your Own Risk.</CustomListItem>
        </List>
      </>
    ),
  },
  Info6: {
    title: 'SALT ROOM',
    text: (
      <>
        <Typography variant="body1" mb={3}>
          Experience the subtle and soothing effects of this salt chamber. Inhale the air over your tongue and you can
          taste the sea.
        </Typography>

        <Typography variant="body1" mb={3}>
          Dry Salt Therapy is a natural, non-invasive treatment that can help alleviate health problems associated with
          respiratory illnesses, allergies, and skin irritations. Salt therapy also promotes stress reduction and
          relaxation, which can lower blood pressure, and help with insomnia. Improve your quality of life with a
          session in the gentle 104˚F (+40˚C).
        </Typography>

        <Typography variant="h6">Safety Information</Typography>
        <List>
          <CustomListItem disablePadding>Do not wear eyeglasses, watches or jewelry in the salt bath. </CustomListItem>
          <CustomListItem disablePadding>
            It is recommended that sandals be worn while utilizing facility.
          </CustomListItem>
          <CustomListItem disablePadding>Max Occupancy: 8</CustomListItem>
        </List>

        <CustomAlert severity="warning">
          <Typography variant="h6">Caution</Typography>
          <List>
            <CustomListItem disablePadding>Sessions are limited to 20 minutes. </CustomListItem>
            <CustomListItem disablePadding>
              Pregnant women, persons with heart conditions, diabetes, high or low blood pressure, or any other medical
              condition should refrain from using these facilities.
            </CustomListItem>
            <CustomListItem disablePadding>Drink water after using the salt bath.</CustomListItem>
          </List>
        </CustomAlert>
        <Typography variant="body1" textAlign="center">
          USE CALL BUTTON FOR EMERGENCY.
        </Typography>
      </>
    ),
  },
  Info7: {
    title: 'FOUR SENSES LOUNGERS',
    text: (
      <>
        <Typography variant="body1" mb={3}>
          Experience a unique, multi-sensory voyage into the world of deep relaxation and well-being, which enlivens the
          4 senses.
        </Typography>
        <Typography variant="body1" mb={3}>
          The 4 Senses Lounger gives you a sense of security and warmth. The warm and softly vibrating surface has a
          soothing effect on the body. The special sound composition and the gentle release of an aroma, can lead to a
          state of meditation.
        </Typography>

        <Typography variant="h6">Instructions</Typography>
        <List>
          <CustomListItem disablePadding>Lie down</CustomListItem>
          <CustomListItem disablePadding>Close your eyes</CustomListItem>
          <CustomListItem disablePadding>Relax.</CustomListItem>
        </List>

        <Typography variant="body1" mb={3}>
          Please limit your time to one complete 25 minute cycle.
        </Typography>
      </>
    ),
  },
  Info8: {
    title: 'FOUR SENSES LOUNGERS',
    text: (
      <>
        <Typography variant="body1" mb={3}>
          Experience a unique, multi-sensory voyage into the world of deep relaxation and well-being, which enlivens the
          4 senses.
        </Typography>
        <Typography variant="body1" mb={3}>
          The 4 Senses Lounger gives you a sense of security and warmth. The warm and softly vibrating surface has a
          soothing effect on the body. The special sound composition and the gentle release of an aroma, can lead to a
          state of meditation.
        </Typography>

        <Typography variant="h6">Instructions</Typography>
        <List>
          <CustomListItem disablePadding>Lie down</CustomListItem>
          <CustomListItem disablePadding>Close your eyes</CustomListItem>
          <CustomListItem disablePadding>Relax.</CustomListItem>
        </List>

        <Typography variant="body1" mb={3}>
          Please limit your time to one complete 25 minute cycle.
        </Typography>
      </>
    ),
  },
  Info9: {
    title: 'THALASSOTHERAPY POOL',
    text: (
      <>
        <Typography variant="body1" mb={3}>
          Hot Therapy provides a thermo-therapeutic action that helps to eliminate toxins and provide relaxation. This
          experience also provides benefits associated with increase circulation, pain relief, improved sleep, and
          detoxification.
        </Typography>
        <Typography variant="body1" mb={3}>
          As a courtesy to other guests please shower before entering the Thalassotherapy Pool and limit your stay to 20
          minutes.
        </Typography>
        <Typography variant="body1" mb={3}>
          For your safety, please use the handrail and enter and exit slowly.
        </Typography>

        <Typography variant="h6">Safety Information</Typography>
        <List>
          <CustomListItem disablePadding>No lifeguard on Duty.</CustomListItem>
          <CustomListItem disablePadding>No Jumping or Diving.</CustomListItem>
          <CustomListItem disablePadding>Running is not permitted in the Pool Area.</CustomListItem>
          <CustomListItem disablePadding>No beverages, food or glass permitted.</CustomListItem>
          <CustomListItem disablePadding>Shower before entering the facility.</CustomListItem>
          <CustomListItem disablePadding>
            You must be at least 18 years of age to use the Thalassotherapy Pool.
          </CustomListItem>
          <CustomListItem disablePadding>Please use handrails when entering and exiting the pools.</CustomListItem>
          <CustomListItem disablePadding>Surrounding surfaces may be wet.</CustomListItem>
          <CustomListItem disablePadding>
            Please use caution when walking around the pool areas, the surfaces may be wet.
          </CustomListItem>
          <CustomListItem disablePadding>
            Do not use the facilities if you are experiencing diarrhea, vomiting, or fever.
          </CustomListItem>
          <CustomListItem disablePadding>
            Do not use while under the influence of alcohol or after a meal.
          </CustomListItem>
          <CustomListItem disablePadding>
            Children, pregnant women, elderly persons, individuals on medications or who have underlying medical
            conditions, those suffering from heart ailments such as cardiovascular disease, diabetes, high or low blood
            pressure, anyone using anticoagulants, vasoconstrictors, vasodilators, stimulants, hypnotics, narcotics,
            tranquilizers and those who are immune compromised, are warned against using the Thalassotherapy Pool.
          </CustomListItem>
          <CustomListItem disablePadding>Exceeding 15 minutes of exposure is not recommended.</CustomListItem>
          <CustomListItem disablePadding>Bather load # Max. 8</CustomListItem>
          <CustomListItem disablePadding>Temperature: +97ºF (+36ºC) Adjustable from 35-39ºC.</CustomListItem>
          <CustomListItem disablePadding>Use the Thalassotherapy Pool at Your Own Risk.</CustomListItem>
        </List>
      </>
    ),
  },
};

export default infoPanels;
