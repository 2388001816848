import { Stack, Typography } from '@mui/material';

import useStyles from 'hooks/useStyles';

import getStyles from './ControlTip.style';
interface Props {
  image: string;
  text: string;
}

const ControlTip = ({ image, text }: Props) => {
  const styles = useStyles(getStyles);

  return (
    <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
      <Stack sx={styles.imageContainer} justifyContent="flex-start" alignItems="center">
        <img style={styles.image} src={image} alt="" />
      </Stack>
      <Typography sx={styles.text}>{text}</Typography>
    </Stack>
  );
};

export default ControlTip;
