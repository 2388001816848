import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tabs, Tab, useMediaQuery, Theme } from '@mui/material';

import useStore from 'store/store';
import { getRoutes } from 'config';

const PanelSecondaryNav = () => {
  const [secondaryTitle, setSecondaryTitle] = useState(0);

  const { shareId } = useStore();
  const routes = getRoutes(shareId);

  const location = useLocation();
  const previousPath = location.pathname.split('/').slice(0, 3).join('/');
  const firstRoute = location.pathname.split('/').slice(2, 3)[0];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const secondaryNavConfig = routes[firstRoute].secondaryNav;

  const handleChangeSecondaryTitle = (event: React.SyntheticEvent, newValue: number) => {
    setSecondaryTitle(newValue);
  };
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Tabs
      value={secondaryTitle}
      onChange={handleChangeSecondaryTitle}
      variant={isMobile ? 'scrollable' : 'fullWidth'}
      scrollButtons
      allowScrollButtonsMobile
      aria-label="visible arrows tabs example"
      sx={{
        '& .MuiTabs-indicator': {
          display: 'none',
        },
        backgroundColor: '#1B1B1B',
      }}
    >
      {Object.keys(secondaryNavConfig).map((key) => {
        const option = secondaryNavConfig[key];
        return (
          <Tab
            key={option.route}
            component={Link}
            to={`${previousPath}/${option.route}/${option.defaultRoute}`}
            label={option.label}
          />
        );
      })}
    </Tabs>
  );
};

export default PanelSecondaryNav;
