import { SxProps, Theme } from '@mui/material';

import { scrollbar } from 'theme/globalStyles';

const getStyles = (theme: Theme): SxProps => {
  return {
    helpPanel: {
      height: '80vh',
      background: '#1B1B1B',

      '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
        height: '100dvh',
      },
    },
    title: { p: 0, m: 0, background: '#1B1B1B' },
    contentContainer: {
      width: '100%',
      height: '100%',
      mt: 2,
      p: 0,
      background: '#1B1B1B',
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        mt: 1,
      },
    },
    sideNav: {
      minWidth: '340px',
      height: '100%',
      pt: 4,
      borderRight: '1px solid #555',

      [theme.breakpoints.down('lg')]: {
        minWidth: '250px',
      },

      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
        height: 'auto',
        pt: 0,
      },
    },
    content: {
      width: '100%',
      height: '100%',
      px: 8,
      overflowY: 'scroll',
      overflowX: 'hidden',
      ...scrollbar,

      [theme.breakpoints.down('lg')]: {
        px: 4,
      },

      [theme.breakpoints.down('sm')]: {
        px: 0,
      },
    },
    actions: {
      background: '#1B1B1B',
    },
  };
};

export default getStyles;
