import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

import { createProjectSlice, ProjectSlice } from './slices/projectSlice';
import { createWebRTCClientSlice, WebRTCClientSlice } from './slices/webRTCClientSlice';
import { createMainUISlice, MainUiSlice } from './slices/mainUISlice';
import { createSidebarSlice, SidebarSlice } from './slices/sidebarSlice';
import { createUpdateStateSlice, UpdateStateSlice } from './slices/updateStateSlice';

import sideEffectsMiddleware from './middleware/sideEffectsMiddleware';

import { environment } from 'utils';

const { REACT_APP_ENVIRONMENT } = environment;
const isDev = REACT_APP_ENVIRONMENT === 'development';

type Store = ProjectSlice & WebRTCClientSlice & MainUiSlice & SidebarSlice & UpdateStateSlice;

const useStore = create<Store>()(
  devtools(
    persist(
      (set, get) => ({
        ...sideEffectsMiddleware((set: any, get: any) => ({
          ...createMainUISlice(set, get),
          ...createSidebarSlice(set, get),
          ...createUpdateStateSlice(set),
        }))(set, get, {}),
        ...createProjectSlice(set),
        ...createWebRTCClientSlice(set),
      }),
      {
        name: 'BayaWeaver-storage',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => ({ releaseInfo: state.releaseInfo }),
      },
    ),
    { name: 'BayaWeaver', enabled: false },
  ),
);

export default useStore;
