import { Box } from '@mui/material';

import { environment } from 'utils';
import useStyles from 'hooks/useStyles';

import bwLogo from 'assets/images/bw-logo.svg';
import adLogo from 'assets/images/ad-logo.png';

import getStyles from './Logo.styles';

const Logo = () => {
  const { REACT_APP_COMPANY } = environment;
  const isBd = REACT_APP_COMPANY === 'bd';

  const styles = useStyles(getStyles);

  return (
    <Box sx={styles.logo}>
      {isBd ? <img src={bwLogo} alt="baya-weaver-logo" /> : <img src={adLogo} alt="ad-logo" />}
    </Box>
  );
};

export default Logo;
