import { useState, useEffect } from 'react';
import { Stack, Typography, IconButton, Fade, useMediaQuery, Theme } from '@mui/material';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';

import tips from './tips';
import useStyles from 'hooks/useStyles';

import getStyles from './Tips.styles';

const Tips = () => {
  const styles = useStyles(getStyles);
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [resetInterval, setResetInterval] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
        setFadeIn(true);
      }, 300);
    }, 7000);

    return () => clearInterval(interval);
  }, [resetInterval]);

  const handleNextTip = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
      setFadeIn(true);
    }, 300);
    setResetInterval((prev) => !prev);
  };

  const handlePrevTip = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentTipIndex((prevIndex) => (prevIndex - 1 + tips.length) % tips.length);
      setFadeIn(true);
    }, 300);
    setResetInterval((prev) => !prev);
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMobileLandscape = useMediaQuery((theme: Theme) => `(max-width: 1080px) and (max-height: 700px)`);

  return (
    <Stack sx={styles.tips} spacing={1}>
      <Stack direction="row" spacing={2} alignItems="center">
        <IconButton sx={styles.button} onClick={handlePrevTip}>
          <ArrowBackIos viewBox="-6 0 24 24" />
        </IconButton>
        <Typography variant={isMobileLandscape || isMobile ? 'h6' : 'h5'}>TIPS</Typography>
        <IconButton sx={styles.button} onClick={handleNextTip}>
          <ArrowForwardIos />
        </IconButton>
      </Stack>
      <Fade in={fadeIn}>
        <Typography sx={styles.text} variant={isMobileLandscape || isMobile ? 'body2' : 'body1'}>
          {tips[currentTipIndex]}
        </Typography>
      </Fade>
    </Stack>
  );
};

export default Tips;
