import { useState, forwardRef, useEffect } from 'react';
import { Slider as MUISlider, SliderProps } from '@mui/material';
import useStore from 'store/store';

interface Props extends SliderProps {
  onChange: (
    event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[],
    activeThumb: number,
  ) => void;
  onChangeCommitted?: (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => void;
}

// Reusable Slider component that prevents camera move in UE while slider is active
const Slider = forwardRef<HTMLDivElement, Props>(({ onChange, onChangeCommitted, ...props }, ref) => {
  const [isSliderActive, setIsSliderActive] = useState(false);

  const { videoRef } = useStore();

  let changeCommittedTimeout: any = null;

  useEffect(() => {
    if (videoRef?.current) {
      if (isSliderActive) {
        videoRef.current.style.pointerEvents = 'none';
      } else {
        videoRef.current.style.pointerEvents = 'auto';
      }
    }
  }, [isSliderActive, videoRef]);

  const handleChange = (
    event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[],
    activeThumb: number,
  ) => {
    clearTimeout(changeCommittedTimeout);
    setIsSliderActive(true);
    onChange(event, value, activeThumb);
  };

  const handleChangeCommitted = (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
    clearTimeout(changeCommittedTimeout);
    changeCommittedTimeout = setTimeout(() => setIsSliderActive(false), 300);
    if (onChangeCommitted) {
      onChangeCommitted(event, value);
    }
  };

  return <MUISlider ref={ref} onChange={handleChange} onChangeCommitted={handleChangeCommitted} {...props} />;
});

Slider.displayName = 'CustomSlider';

export default Slider;
