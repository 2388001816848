import useStore from 'store/store';
import useInitialSettings from 'hooks/useInitialSettings';

import TopControls from 'sections/main/TopControls/TopControls';
import Sidebar from 'sections/sidebar/Sidebar';
import HelpPanel from 'sections/panel/HelpPanel/HelpPanel';
import GuidedTourControls from 'sections/main/GuidedTourControls/GuidedTourControls';
import MeasurementTool from 'sections/main/MeasurementTool/MeasurementTool';
import CameraMode from 'sections/main/CameraMode/CameraMode';
import { ButtonScreenshot } from 'components/ui';
import ConfiguratorPanel from 'sections/panel/ConfiguratorPanel/ConfiguratorPanel';
import InfoPanel from 'sections/panel/InfoPanel /InfoPanel';
import ControlPanel from 'sections/panel/ControlPanel/ControlPanel';
import CloseOverheadMode from 'sections/main/CloseOverheadMode/CloseOverheadMode';
import Joysticks from 'sections/main/Joysticks/Joysticks';
import TitlePage from 'pages/TitlePage/TitlePage';
import SlicingTool from 'sections/main/SlicingTool/SlicingTool';

const AppUI = () => {
  const {
    showTitlePage,
    isCameraMode,
    isMeasurementMode,
    isGuidedTourMode,
    isOverheadMode,
    isSlicingMode,
    isHelpPanelOpen,
    showQuickCapture,
    showJoysticks,
  } = useStore();

  useInitialSettings();

  return (
    <>
      {isCameraMode ? (
        <CameraMode />
      ) : isOverheadMode ? (
        <>
          <CloseOverheadMode />
          <ConfiguratorPanel />
        </>
      ) : (
        <>
          {showTitlePage && <TitlePage />}
          {isOverheadMode && <CloseOverheadMode />}
          {!isGuidedTourMode && <TopControls />}
          {!isGuidedTourMode && <Sidebar />}
          <GuidedTourControls />
          {isMeasurementMode && <MeasurementTool />}
          {isSlicingMode && <SlicingTool />}
          {showJoysticks.value && <Joysticks />}
          {isHelpPanelOpen && <HelpPanel />}
          <ConfiguratorPanel />
          <ControlPanel />
          <InfoPanel />
          {!isMeasurementMode && showQuickCapture.value && <ButtonScreenshot />}
        </>
      )}
    </>
  );
};

export default AppUI;
