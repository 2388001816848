import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme, isVertical: boolean, isHighlighted: boolean): SxProps => ({
  cameraSliderContainer: {
    width: isVertical ? 'auto' : '100dvw',
    height: isVertical ? '100dvh' : 'auto',
    position: 'fixed',
    color: 'white',
    pointerEvents: 'none',

    '@keyframes pulse': {
      '0%': {
        opacity: '100%',
        transform: ' scale(1)',
      },
      '50%': {
        opacity: '20%',
        transform: ' scale(1.01)',
      },
      '100%': {
        opacity: '100%',
        transform: ' scale(1)',
      },
    },

    animation: isHighlighted ? 'pulse 2s infinite' : 'none',
  },

  top: {
    top: 0,
  },
  bottom: {
    bottom: 0,

    [theme.breakpoints.down('sm')]: {
      bottom: 96,
    },
  },
  left: {
    left: 0,
  },
  right: {
    right: 186,

    [theme.breakpoints.down('sm')]: {
      right: 0,
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      right: 120,
    },
  },

  overlay: {
    p: 3,
    pointerEvents: 'auto',

    [theme.breakpoints.down('sm')]: {
      p: 1,
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      p: 1,
    },
  },

  slider: {
    width: isVertical ? 'inherit' : '400px',
    height: isVertical ? '400px' : 'inherit',
    pointerEvents: 'auto',

    '& .MuiSlider-track': {
      display: 'none',
    },
    '& .MuiSlider-rail': {
      width: isVertical ? '10px' : 'inherit',
      height: isVertical ? '100%' : '10px',
      backgroundColor: '#CCCCCC80 ',
      boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.50)',
      opacity: 1,
    },
    '& .MuiSlider-thumb': {
      width: '40px',
      height: '40px',
      backgroundColor: 'white',
      boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.50)',

      '@keyframes shadow': {
        '0%': {
          boxShadow: '0px 0px 5px 6px rgba(232,232,232,0.3)',
        },
        '50%': {
          boxShadow: '0px 0px 10px 6px rgba(232,232,232,0.3)',
        },
        '80%': {
          boxShadow: '0px 0px 24px 6px rgba(232,232,232,0.3)',
        },
        '100%': {
          boxShadow: 'none',
        },
      },
      animation: isHighlighted ? 'shadow 3s infinite' : 'none',
    },

    [theme.breakpoints.down('sm')]: {
      width: isVertical ? 'inherit' : '200px',
      height: isVertical ? '200px' : 'inherit',

      '& .MuiSlider-rail': {
        width: isVertical ? '7px' : 'inherit',
        height: isVertical ? '100%' : '7px',
      },
      '& .MuiSlider-thumb': {
        width: '25px',
        height: '25px',
      },
    },
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      width: isVertical ? 'inherit' : '250px',
      height: isVertical ? '250px' : 'inherit',

      '& .MuiSlider-rail': {
        width: isVertical ? '7px' : 'inherit',
        height: isVertical ? '100%' : '7px',
      },
      '& .MuiSlider-thumb': {
        width: '25px',
        height: '25px',
      },
    },
  },

  label: {
    textAlign: isVertical ? 'auto' : 'right',
    width: isVertical ? 'inherit' : '80px',
    pointerEvents: 'auto',
    textShadow: ' 0px 0px 5px rgba(0, 0, 0, 0.50)',

    [theme.breakpoints.down('sm')]: {
      width: isVertical ? 'inherit' : '50px',
      fontSize: '1em',
    },
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      fontSize: '1em',
    },
  },

  value: {
    textAlign: isVertical ? 'auto' : 'left',
    width: isVertical ? 'inherit' : '80px',
    pt: isVertical ? 0.5 : 'inerit',
    pointerEvents: 'auto',
    textShadow: ' 0px 0px 5px rgba(0, 0, 0, 0.50)',

    [theme.breakpoints.down('sm')]: {
      width: isVertical ? 'inherit' : '50px',
      fontSize: '1em',
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      fontSize: '1em',
    },
  },
});

export default getStyles;
