const imageBaseURL = '/assets/images/help';

const helpPanelContent = {
  controls: {
    touch: {
      walk: [
        { image: `${imageBaseURL}/touch/touch-double.png`, text: 'double tap the floor to walk to that point' },
        { image: `${imageBaseURL}/touch/swipe.png`, text: 'swipe with one finger to look around' },
        { image: `${imageBaseURL}/touch/pinch.png`, text: 'pinch with two fingers to zoom in and out' },
        {
          image: `${imageBaseURL}/touch/touch-interaction.png`,
          text: 'hold finger on an interaction point to activate it',
        },
      ],
      fly: [
        { image: `${imageBaseURL}/touch/pinch.png`, text: 'pinch with two fingers to move forward and backward' },
        { image: `${imageBaseURL}/touch/swipe.png`, text: 'swipe with one finger to look around' },
        { image: `${imageBaseURL}/touch/swipe-two-fingers.png`, text: 'swipe with two fingers to change height' },
        {
          image: `${imageBaseURL}/touch/touch-interaction.png`,
          text: 'hold finger on interaction point to activate it',
        },
      ],
      orbit: [
        { image: `${imageBaseURL}/touch/swipe.png`, text: 'swipe with one finger to rotate view' },
        { image: `${imageBaseURL}/touch/swipe-two-fingers.png`, text: 'swipe with two fingers to pan view' },
        { image: `${imageBaseURL}/touch/pinch.png`, text: 'pinch with two fingers to zoom in and out' },
      ],
    },
    mouse: {
      walk: [
        { image: `${imageBaseURL}/mouse/click-double.png`, text: 'double click the floor to walk to that point    ' },
        { image: `${imageBaseURL}/mouse/left-drag.png`, text: 'hold the left mouse button to look around' },
        { image: `${imageBaseURL}/mouse/mmb.png`, text: 'scroll the mouse wheel to zoom in and out' },
        {
          image: `${imageBaseURL}/mouse/click-interaction.png`,
          text: 'hold the left mouse button on an interaction point to activate it',
        },
      ],
      fly: [
        { image: `${imageBaseURL}/mouse/mmb-scroll.png`, text: 'scroll the mouse wheel to move forward and backward' },
        { image: `${imageBaseURL}/mouse/left-drag.png`, text: 'hold the left mouse button to look around' },
        { image: `${imageBaseURL}/mouse/mmb-move.png`, text: 'hold the mouse wheel and move mouse to change height' },
        {
          image: `${imageBaseURL}/mouse/click-interaction.png`,
          text: 'hold finger on interaction point to activate it',
        },
      ],
      orbit: [
        {
          image: `${imageBaseURL}/mouse/left-drag.png`,
          text: `hold the left mouse button to rotate the view (or pan the view if the ‘Orbit Pan’ button is active)`,
        },
        { image: `${imageBaseURL}/mouse/mmb.png`, text: 'scroll the mouse wheel to zoom in and out' },
      ],
    },
    keyboard: {
      walk: [
        {
          image: `${imageBaseURL}/keyboard/wasd.png`,
          text: 'press the W,A,S,D keys to move forward, left, backwards and right respectively',
        },
        {
          image: `${imageBaseURL}/keyboard/ijkl.png`,
          text: 'press the I,J,K,L keys to look up, left, down and right respectively',
        },
        { image: `${imageBaseURL}/keyboard/rf.png`, text: 'press the R and F keys to zoom in and out' },
      ],
      fly: [
        {
          image: `${imageBaseURL}/keyboard/wasd.png`,
          text: 'press the W,A,S,D keys to move forward, left, backwards and right respectively',
        },
        {
          image: `${imageBaseURL}/keyboard/ijkl.png`,
          text: 'press the I,J,K,L keys to look up, left, down and right respectively',
        },
        { image: `${imageBaseURL}/keyboard/rf.png`, text: 'press the R and F keys to change height' },
      ],
      orbit: [
        {
          image: `${imageBaseURL}/keyboard/wasd.png`,
          text: 'press the W,A,S,D keys to pan the view forward, left, backwards and right respectively',
        },
        {
          image: `${imageBaseURL}/keyboard/ijkl.png`,
          text: 'press the I,J,K,L keys to rotate the view',
        },
        { image: `${imageBaseURL}/keyboard/rf.png`, text: 'press the R and F keys to zoom in and out' },
        {
          image: `${imageBaseURL}/keyboard/q.png`,
          text: 'press the Q key to toggle the camera between rotate and pan modes',
        },
      ],
    },
  },
};

export default helpPanelContent;
