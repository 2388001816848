import * as Sentry from '@sentry/react';

const logger = () => {
  Sentry.init({
    dsn: 'https://711f9437b30153aa2a88471e290a4294@o4505623943970816.ingest.sentry.io/4505623945543680',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /https:\/\/adassociates\.london\//,
          /https:\/\/bayadynamics\.com\//,
          /https:\/\/bayatrove\.com\//,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export default logger;
