import { useState } from 'react';
import { Stack, useMediaQuery, Theme } from '@mui/material';

import { CameraModeSlider } from 'components/ui';
import useStore from 'store/store';

import CameraGrid from './CameraGrid/CameraGrid';
import CameraScreenshotButton from './CameraScreenshotButton/CameraScreenshotButton';
import CameraOptions from './CameraOptions/CameraOptions';
import CameraCloseButton from './CameraCloseButton/CameraCloseButton';

import useStyles from 'hooks/useStyles';
import getStyles from './CameraMode.styles';

const CameraMode = () => {
  const [highlightDOF, setHighlightDOF] = useState(false);

  const { useWebRTCResponseEventListener, setIsCameraMode } = useStore();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const styles = useStyles(getStyles);

  useWebRTCResponseEventListener('SetManualFocusEvent', () => {
    setHighlightDOF(true);
    setTimeout(() => {
      setHighlightDOF(false);
    }, 300);
  });

  return (
    <>
      <CameraGrid />
      <CameraModeSlider
        label="Exposure"
        functionName="SetExposureOffset"
        min={-5}
        max={5}
        defaultValue={0}
        position="top"
      />
      <CameraModeSlider
        label="Roll"
        functionName="SetRollOffset"
        min={-90}
        max={90}
        defaultValue={0}
        position="bottom"
      />
      <CameraModeSlider label="FOV" functionName="SetFOVOffset" min={-50} max={50} defaultValue={0} position="right" />
      <CameraModeSlider
        label="DOF"
        functionName="SetSensorWidth"
        min={0}
        max={300}
        defaultValue={0}
        position="left"
        isHighlighted={highlightDOF}
      />
      <Stack sx={styles.cameraControls} direction={isMobile ? 'row' : 'column'}>
        <CameraScreenshotButton />
        <Stack>
          {/* <CameraOptions /> */}
          <CameraCloseButton handleClose={() => setIsCameraMode(false)} />
        </Stack>
      </Stack>
    </>
  );
};

export default CameraMode;
