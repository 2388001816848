import { Route, Routes } from 'react-router-dom';

import Controls from './controls/Controls';
import MovementModes from './controls/options/MovementModes';
import MovementSpeed from './controls/options/MovementSpeed';
import LookSensitivity from './controls/options/LookSensitivity';
import FieldOfView from './controls/options/FieldOfView';
import WalkModeEyeHeight from './controls/options/WalkModeEyeHeight';
import InvertXYAxis from './controls/options/InvertXYAxis';

import HideNavOnInactivity from './generalSettings/HideNavOnInactivity';
import SidebarOnRight from './generalSettings/SidebarOnRight';
import ShowQuickCapture from './generalSettings/ShowQuickCapture';

import OptionsBurger from './display/OptionsBurger';
import Fullscreen from './display/Fullscreen';
import SwitchOrbit2D3D from './display/SwitchOrbit2D3D';
import OrbitRotate from './display/OrbitRotate';
import Restart from './display/Restart';
import Volume from './display/Volume';

import Viewpoints from './viewpoints/Viewpoints';
import GuidedTours from './guidedTours/GuidedTours';

import content from '../panelContent';

const PanelContent = () => {
  return (
    <Routes>
      <Route path="help/controls/touch/walk" element={<Controls content={content.controls.touch.walk} />} />
      <Route path="help/controls/touch/fly" element={<Controls content={content.controls.touch.fly} />} />
      <Route path="help/controls/touch/orbit" element={<Controls content={content.controls.touch.orbit} />} />

      <Route path="help/controls/mouse/walk" element={<Controls content={content.controls.mouse.walk} />} />
      <Route path="help/controls/mouse/fly" element={<Controls content={content.controls.mouse.fly} />} />
      <Route path="help/controls/mouse/orbit" element={<Controls content={content.controls.mouse.orbit} />} />

      <Route path="help/controls/keyboard/walk" element={<Controls content={content.controls.keyboard.walk} />} />
      <Route path="help/controls/keyboard/fly" element={<Controls content={content.controls.keyboard.fly} />} />
      <Route path="help/controls/keyboard/orbit" element={<Controls content={content.controls.keyboard.orbit} />} />

      <Route path="help/controls/options/movement-modes" element={<MovementModes />} />
      <Route path="help/controls/options/movement-speed" element={<MovementSpeed />} />
      <Route path="help/controls/options/look-sensitivity" element={<LookSensitivity />} />
      <Route path="help/controls/options/field-of-view" element={<FieldOfView />} />
      <Route path="help/controls/options/walk-mode-eye-height" element={<WalkModeEyeHeight />} />
      <Route path="help/controls/options/invert-xy-axis" element={<InvertXYAxis />} />

      <Route path="help/settings/general-settings/hide-nav" element={<HideNavOnInactivity />} />
      <Route path="help/settings/general-settings/invert-xy-axis" element={<InvertXYAxis />} />
      <Route path="help/settings/general-settings/sidebar-on-right" element={<SidebarOnRight />} />
      <Route path="help/settings/general-settings/show-quick-capture" element={<ShowQuickCapture />} />
      <Route path="help/settings/general-settings/look-sensitivity" element={<LookSensitivity />} />
      <Route path="help/settings/general-settings/field-of-view" element={<FieldOfView />} />
      <Route path="help/settings/general-settings/walk-mode-eye-height" element={<WalkModeEyeHeight />} />

      <Route path="help/display/main-icons/options-burger" element={<OptionsBurger />} />
      <Route path="help/display/main-icons/full-screen" element={<Fullscreen />} />
      <Route path="help/display/main-icons/switch-orbit-2D-3D" element={<SwitchOrbit2D3D />} />
      <Route path="help/display/main-icons/orbit-rotate" element={<OrbitRotate />} />
      <Route path="help/display/main-icons/restart" element={<Restart />} />
      <Route path="help/display/main-icons/Volume" element={<Volume />} />

      <Route path="help/viewpoints" element={<Viewpoints />} />
      <Route path="help/guided-tours" element={<GuidedTours />} />
    </Routes>
  );
};

export default PanelContent;
