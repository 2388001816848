import { Dialog, DialogContent, Stack, IconButton } from '@mui/material';
import { WbIncandescent, Close } from '@mui/icons-material';

import Slider from 'components/ui/sliders/Slider/Slider';

import useStyles from 'hooks/useStyles';
import getStyles from './ControlPanelModal.styles';

interface Props {
  value: any;
  name: string;
  isOpen: boolean;
  onClose: () => void;
  onChange: (name: string, value: any) => void;
}

const ControlPanelModal = ({ value, name, isOpen, onClose, onChange }: Props) => {
  const styles = useStyles(getStyles);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: styles.configuratorPanel }}
      slotProps={{ backdrop: { invisible: true } }}
    >
      <DialogContent sx={styles.content}>
        <Stack sx={styles.contentContainer} direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <WbIncandescent />
          <Slider
            sx={styles.slider}
            value={value}
            min={0}
            max={1}
            step={0.01}
            onChange={(event, newValue) => onChange(name, newValue)}
          />
          <IconButton sx={styles.closeButton} onClick={onClose} color="primary">
            <Close />
          </IconButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ControlPanelModal;
