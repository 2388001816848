import isEqual from 'lodash.isequal';

const sideEffectsMiddleware = (config: any) => (set: any, get: any, api: any) =>
  config(
    (args: any) => {
      const prevState = get();
      set(args);
      const nextState = get();

      const updateDisabledStates = () => {
        const state = get();
        const isMovementOrbit = state.movement.value === 'orbit';
        const isMovementWalk = state.movement.value === 'walk';
        const isSlicingMode = state.isSlicingMode;

        set((prevState: any) => ({
          ...prevState,
          views: { ...prevState.views, disabled: isMovementOrbit },
          guidedTours: { ...prevState.guidedTours, disabled: isMovementOrbit },
          guidedToursSpeed: { ...prevState.guidedToursSpeed, disabled: isMovementOrbit },
          eyeHeight: { ...prevState.eyeHeight, disabled: !isMovementWalk },
          orbitModeBackground: { ...prevState.orbitModeBackground, disabled: !isMovementOrbit },
          spaces: { ...prevState.spaces, disabled: isSlicingMode },
          movement: { ...prevState.movement, disabled: isSlicingMode },
          movementSpeed: { ...prevState.movementSpeed, disabled: isSlicingMode },
        }));
      };

      const handleExitOrbitMode = () => {
        if (prevState.movement.value !== nextState.movement.value && prevState.movement.value === 'orbit') {
          get().updateShowMode('3D');
          get().updateInputMethod(false);
          get().emitUIInteraction({ CallFunctionRequest: { FunctionName: 'Toggle2DMode', Arguments: 'Walk3D' } });
        }
      };

      const handleVolumetricScenario = () => {
        if (prevState.scenario.value !== nextState.scenario.value && nextState.scenario.value === 'Whitecard') {
          get().updateInteractions(['Default']);
        }
      };

      const handleOverheadView = () => {
        const overheadValue = {
          location: {
            x: 770.20000000000005,
            y: -1427.2,
            z: 934.79999999999995,
          },
          rotation: {
            pitch: -90,
            yaw: 180,
            roll: 0,
          },
        };

        if (prevState.views.value !== nextState.views.value && isEqual(nextState.views.value, overheadValue)) {
          get().setIsOverheadMode(true);

          get().updateMovement('fly');
          get().emitUIInteraction({
            VariantSwitch: {
              VariantSetName: 'Ceiling Visibility',
              VariantName: 'Hidden',
            },
          });

          if (prevState.scenario.value === 'Whitecard') {
            get().updateInteractions([]);
          } else {
            get().updateInteractions(['Variant']);
          }
        }
      };

      updateDisabledStates();
      handleExitOrbitMode();
      handleVolumetricScenario();
      handleOverheadView();
    },
    get,
    api,
  );

export default sideEffectsMiddleware;
