const configOptions = {
  Pouffe: ['Pouffe01', 'Pouffe02', 'Pouffe03'],
  'Wall Finish': ['WallFinish01', 'WallFinish02'],
  Artwork: ['Painting01', 'Painting02', 'Painting03'],
  'Dining Table Setting': [
    'DT_Welcome_FlowerArr',
    'DT_Boarded_Laptop',
    'DT_Dining_Tableware',
    'DT_TurnDown_WhiteFlowers',
  ],
  'Dining Chairs': ['ChairBlue', 'ChairBrown', 'ChairWhite'],
  'Sofa Fabric': ['SofaGreen', 'SofaBlue', 'SofaPink'],
  Armchair: ['Armchair01', 'Armchair02'],
  'TV Channel': ['TV_Welcome_RitzVideo', 'TV_Boarded_News', 'TV_Dining_Relax', 'TV_TurnDown_Relax'],
  'Balcony Furniture': ['Furniture01', 'Furniture02'],
  Carpet: ['CarpetMat01', 'CarpetMat02'],
  'Power Sockets': ['Sockets01', 'Sockets02'],
  Pillows: ['Pillows01', 'Pillows03'],
  'Curtains & Sheers': ['Curtains_Welcome', 'Curtains_Boarded', 'Curtains_InRoomDining', 'Curtains_TurnDown'],
  Bed: ['BedRegular', 'BedTurnDown'],
  'Wardrobe Clothes': ['ClothesEmpty', 'ClothesFilled'],
};

const text = {
  Pouffe01:
    'Elevate your relaxation experience with our sumptuously soft and irresistibly plush pouffe, designed to envelop you in a cloud-like embrace. Sink into its luxurious cushioning and let your worries melt away as you unwind in style. Whether as a footrest, extra seating, or simply a statement piece in your living space, the this pouffe effortlessly adds a touch of elegance and comfort to any room.',
  Pouffe02:
    'Crafted for the discerning traveler, this elegant piece combines a gentle aesthetic with the promise of unparalleled comfort. Indulge in the superior softness of its high-quality fabric as it offers a welcoming respite for your feet or an inviting option for additional seating. Its chic, rounded design complements any upscale suite, promising a luxurious and restful atmosphere.',
  Pouffe03:
    "Designed with an eye for luxury and a feel for comfort, this pouffe is a seamless addition to any premium suite decor. Embrace the delicate balance of firm support and a velvety touch that it offers, perfect for those moments of relaxation. With its understated elegance, it stands as both a functional furnishing and a graceful accent in your suite's ensemble.",
  WallFinish01: 'Wall Finish Option 1',
  WallFinish02: 'Wall Finish Option 2',
  Painting01:
    'Mountain Mosaic captures the stark beauty of a rugged landscape through a collage of abstract shapes and a muted colour palette. This modern piece brings a contemplative silence to any space, inviting guests to ponder the majestic tranquillity of mountainous terrains.',
  Painting02:
    "Sea's Whisper offers a serene depiction of the ocean's gentle rhythms. Its subtle blend of blues and greys, accented with a hint of earthy tones, reflects the calming influence of the sea, perfect for creating an atmosphere of peace and reflection in a luxurious setting.",
  Painting03:
    'Wooden Waves presents a unique interpretation of natural textures, reminiscent of rippling water or the soft lines of wood grain. The soothing simplicity of this piece makes it an elegant and meditative addition to the refined ambiance of a cruise ship suite.',
  DT_Welcome_FlowerArr: 'Dining Table Setting Option 1',
  DT_Boarded_Laptop: 'Dining Table Setting Option 2',
  DT_Dining_Tableware: 'Dining Table Setting Option 3',
  DT_TurnDown_WhiteFlowers: 'Dining Table Setting Option 4',
  ChairBlue:
    'Introducing our sleek and stylish dining chair - the perfect blend of form and function for your dining space. Crafted with premium materials and meticulous attention to detail, this chair offers unparalleled comfort and durability. Its modern design effortlessly elevates any dining room aesthetic, while the cushioned seat ensures a cozy dining experience every time.',
  ChairBrown:
    'Introducing our sleek and stylish dining chair - the perfect blend of form and function for your dining space. Crafted with premium materials and meticulous attention to detail, this chair offers unparalleled comfort and durability. Its modern design effortlessly elevates any dining room aesthetic, while the cushioned seat ensures a cozy dining experience every time.',
  ChairWhite:
    'Introducing our sleek and stylish dining chair - the perfect blend of form and function for your dining space. Crafted with premium materials and meticulous attention to detail, this chair offers unparalleled comfort and durability. Its modern design effortlessly elevates any dining room aesthetic, while the cushioned seat ensures a cozy dining experience every time.',
  SofaGreen:
    'Introducing our luxurious sofa, designed to redefine comfort and style in your living space. Crafted with premium materials and exquisite attention to detail, this sofa offers unparalleled relaxation and sophistication. Sink into the plush cushions and experience ultimate comfort while lounging or entertaining guests. Its timeless design effortlessly complements any decor, making it the perfect centrepiece to any room.',
  SofaBlue:
    'Introducing our luxurious sofa, designed to redefine comfort and style in your living space. Crafted with premium materials and exquisite attention to detail, this sofa offers unparalleled relaxation and sophistication. Sink into the plush cushions and experience ultimate comfort while lounging or entertaining guests. Its timeless design effortlessly complements any decor, making it the perfect centrepiece to any room.',
  SofaPink:
    'Introducing our luxurious sofa, designed to redefine comfort and style in your living space. Crafted with premium materials and exquisite attention to detail, this sofa offers unparalleled relaxation and sophistication. Sink into the plush cushions and experience ultimate comfort while lounging or entertaining guests. Its timeless design effortlessly complements any decor, making it the perfect centrepiece to any room.',
  Armchair01:
    'This armchair is designed to provide a peaceful haven for relaxation, merging understated design with an invitingly soft seat cushion. Its minimalist appeal makes it a versatile piece, perfect for enjoying a quiet morning coffee or indulging in a late-night read.',
  Armchair02:
    'Crafted with precision and quality materials, this armchair offers unparalleled relaxation and style. Sink into its plush cushions and experience ultimate comfort as you unwind after a long day. With its timeless design and sophisticated silhouette, it effortlessly enhances any living space.',
  TV_Welcome_RitzVideo: 'TV Channel Option 1',
  TV_Boarded_News: 'TV Channel Option 2',
  TV_Dining_Relax: 'TV Channel Option 3',
  TV_TurnDown_Relax: 'TV Channel Option 4',
  Furniture01:
    'Offering an intimate setting where guests can greet the morning light. This arrangement features a comfortable lounger paired with a modern, minimalist side table, ideal for enjoying a quiet breakfast or a peaceful moment of solitude with the horizon stretching out before you.',
  Furniture02:
    'Thoughtfully laid out to provide the perfect vantage point for the evening’s amber hues. With a plush chair, matching footstool, and a chic glass-top table, guests can unwind in style, sipping a cocktail as the sun dips below the distant waves.',
  CarpetMat01: 'Carpet Option 1',
  CarpetMat02: 'Carpet Option 2',
  Sockets01:
    'Discover the sleek integration of power with option 1, a European-style outlet designed to blend seamlessly into the sophisticated decor of any stateroom. Its refined, metallic finish complements the room’s ambiance, providing guests with the essential connectivity for their devices.',
  Sockets02:
    'Option 2 presents a familiar convenience with its American socket design, offering a harmonious balance of form and function. The polished, contemporary interface caters to the power needs of guests, ensuring their electronic essentials are always within reach.',
  Pillows01:
    'Introducing the epitome of opulence in sleep: our LuxeComfort Pillow. Crafted with unparalleled precision and infused with the finest materials, this pillow redefines the art of relaxation. Indulge in the lavish embrace of its premium down alternative fill, meticulously designed to cradle your head and neck in heavenly comfort.',
  Pillows03:
    'Introducing the epitome of opulence in sleep: our LuxeComfort Pillow. Crafted with unparalleled precision and infused with the finest materials, this pillow redefines the art of relaxation. Indulge in the lavish embrace of its premium down alternative fill, meticulously designed to cradle your head and neck in heavenly comfort.',
  Curtains_Welcome: 'Curtains & Sheers Option 1',
  Curtains_Boarded: 'Curtains & Sheers Option 2',
  Curtains_InRoomDining: 'Curtains & Sheers Option 3',
  Curtains_TurnDown: 'Curtains & Sheers Option 4',
  BedRegular: 'Bed Option 1',
  BedTurnDown: 'Bed Option 2',
  ClothesEmpty: 'Wardrobe Clothes Option 1',
  ClothesFilled: 'Wardrobe Clothes Option 2',
};

const labelItems = {
  Pouffe: 'Pouffe',
  Artwork: 'Artwork',
  'Dining Chairs': 'Dining Chairs',
  'Sofa Fabric': 'Sofa',
  Armchair: 'Armchair',
  'Balcony Furniture': 'Balcony Furniture',
  'Power Sockets': 'Sockets',
  Pillows: 'Pillow',
};

const labelsOptions = {
  Pouffe01: 'Pouffe Option 1',
  Pouffe02: 'Pouffe Option 2',
  Pouffe03: 'Pouffe Option 3',
  Painting01: 'Mountain Mosaic',
  Painting02: "Sea's Whisper",
  Painting03: 'Wooden Waves',
  ChairBlue: 'Dining Chair – Blue',
  ChairBrown: 'Dining Chair – Brown',
  ChairWhite: 'Dining Chair – White',
  SofaGreen: 'Sofa – Green',
  SofaBlue: 'Sofa – Blue',
  SofaPink: 'Sofa – Pink',
  Armchair01: 'Armchair Option 1',
  Armchair02: 'Armchair Option 2',
  Furniture01: 'Balcony Furniture Option 1',
  Furniture02: 'Balcony Furniture Option 2',
  Sockets01: 'Sockets Option 1',
  Sockets02: 'Sockets Option 2',
  Pillows01: 'LuxeComfort',
  Pillows03: 'LuxeComfort',
};

export { configOptions, text, labelItems, labelsOptions };
