import { SxProps } from '@mui/material';
import { scrollbar } from 'theme/globalStyles';
const getStyles = (): SxProps => ({
  sideNav: {
    width: '250px',
    pr: 1,
    border: 'none',
    backgroundColor: '#1B1B1B',
    overflowX: 'hidden',

    ...scrollbar,
  },
});

export default getStyles;
