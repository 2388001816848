import ReactGA from 'react-ga4';
import { environment } from 'utils';

const googleAnalytics = () => {
  const { REACT_APP_GA_TRACKING_ID } = environment;

  if (REACT_APP_GA_TRACKING_ID.length) {
    ReactGA.initialize(REACT_APP_GA_TRACKING_ID);
  }
};

export default googleAnalytics;
