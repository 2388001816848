import { useState } from 'react';
import { Dialog, DialogContent, useMediaQuery, Theme } from '@mui/material';

import useStyles from 'hooks/useStyles';
import getStyles from './ConfiguratorPanelModal.styles';
import { text } from '../config';

import ModalContent from './ModalContent/ModalContent';
import MobileModalContent from './MobileModalContent/MobileModalContent';
interface Props {
  value: string;
  name: string;
  isOpen: boolean;
  onClose: () => void;
  onChange: (name: string, value: string) => void;
  options: string[];
  isRetail?: boolean;
}

const ConfiguratorPanelModal = ({ value, name, isOpen, onClose, onChange, options, isRetail = false }: Props) => {
  const [isRightSectionVisible, setIsRightSectionVisible] = useState(false);

  const toggleRightSection = () => {
    setIsRightSectionVisible(!isRightSectionVisible);
  };

  const handleClose = () => {
    onClose();
    setIsRightSectionVisible(false);
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMobileLandscape = useMediaQuery((theme: Theme) => `(max-width: 1080px) and (max-height: 700px)`);

  const styles = useStyles(getStyles, isRightSectionVisible);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{ sx: styles.configuratorPanel }}
      fullScreen={isMobileLandscape}
    >
      <DialogContent sx={styles.content}>
        {isMobile ? (
          <MobileModalContent
            value={value}
            name={name}
            handleClose={handleClose}
            onChange={onChange}
            options={options}
            isRetail={isRetail}
            text={text[value as keyof typeof text]}
          />
        ) : (
          <ModalContent
            value={value}
            name={name}
            handleClose={handleClose}
            onChange={onChange}
            options={options}
            isRetail={isRetail}
            isRightSectionVisible={isRightSectionVisible}
            toggleRightSection={toggleRightSection}
            text={text[value as keyof typeof text]}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ConfiguratorPanelModal;
