import { common } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import { error, primary, info, neutral, success, warning } from './colors';
import { PaletteOptions } from '@mui/material/styles';

export const createPalette = (): PaletteOptions => {
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12),
    },
    background: {
      default: neutral[900],
      paper: neutral[900],
    },
    divider: neutral[300],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    neutral,
    error,
    info,
    mode: 'dark',
    primary,
    success,
    text: {
      primary: common.white,
      secondary: neutral[400],
      disabled: alpha(neutral[900], 0.38),
    },
    warning,
  };
};
