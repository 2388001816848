import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useStore from 'store/store';
import { environment, getDefaultValue } from 'utils';
import controlPaths from 'config/sidebar/controlPaths';
import updateFunctionsMap from 'store/updateFunctionsMap';

const useInitialSettings = () => {
  const { shareId, emitUIInteraction, useWebRTCResponseEventListener, setIsHelpPanelOpen, updateInputMethod } =
    useStore();
  const store = useStore();

  const navigate = useNavigate();

  const { REACT_APP_IS_PUBLIC } = environment;

  // Let know UE that web app is initialized
  useWebRTCResponseEventListener('Ping', () => {
    emitUIInteraction({
      CallFunctionRequest: {
        FunctionName: 'Pong',
        Arguments: '',
      },
    });
  });

  // UE changes inputMethod on "Q" press, and sends response, update state on response
  useWebRTCResponseEventListener('AlternativeInput', (response: any) => {
    updateInputMethod(response.payload);
  });

  const setInitialSettings = () => {
    Object.keys(controlPaths).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const defaultValue = getDefaultValue(key, shareId);

      if (defaultValue) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        store[updateFunctionsMap[key]](defaultValue);
      }
    });
  };

  useEffect(() => {
    if (REACT_APP_IS_PUBLIC) {
      setIsHelpPanelOpen(true);
      navigate('/help/controls/touch/walk');
    }

    setInitialSettings();
  }, []);
};

export default useInitialSettings;
