import { SxProps, Theme } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  configuratorPanel: {
    minWidth: '750px',
    height: '65vh',
    p: 0,
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    [theme.breakpoints.down('md')]: {
      minWidth: '100vw',
      height: '80vh',
    },
    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      minWidth: '100vw',
      height: '100dvh',
    },
  },
  content: {
    overflow: 'hidden',
    height: '100%',
    p: 0,
    backgroundColor: 'transparent',
  },
});

export default getStyles;
