import { useEffect, useState } from 'react';

import { environment } from 'utils';

const { REACT_APP_SHOW_LEGAL } = environment;

const useLegalHealthWarning = () => {
  const [isLegalHealthVisible, setIsLegalHealthVisible] = useState<boolean>(REACT_APP_SHOW_LEGAL);

  useEffect(() => {
    if (REACT_APP_SHOW_LEGAL) {
      setTimeout(() => setIsLegalHealthVisible(false), 10000);
    }
  }, []);

  return { isLegalHealthVisible };
};

export default useLegalHealthWarning;
