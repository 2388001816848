const getButtonsPerRow = (options: any) => {
  // Calculate the total length of all labels
  const totalLabelLength = options.reduce((sum: number, option: any) => {
    return option.groupLabel ? sum : sum + option.label.length;
  }, 0);

  // Calculate the number of group labels
  let groupLabelsCount = options.filter((option: any) => option.groupLabel).length;

  // If there are no group labels set it to 1
  groupLabelsCount = groupLabelsCount === 0 ? 1 : groupLabelsCount;

  // Adjust the total label length and total buttons by the number of group labels
  const adjustedLabelLength = totalLabelLength / groupLabelsCount;
  const totalButtons = options.length / groupLabelsCount;

  const areLabelsTooLong = adjustedLabelLength > 15;

  // Set number of buttons per row
  let buttonsPerRow;
  if (totalButtons === 1) {
    buttonsPerRow = 1;
  } else if (totalButtons === 2) {
    buttonsPerRow = 2;
  } else if (totalButtons <= 3) {
    buttonsPerRow = 3;
  } else if (totalButtons === 4 || totalButtons === 5) {
    buttonsPerRow = areLabelsTooLong ? 2 : 4;
  } else if (totalButtons === 6) {
    buttonsPerRow = 3;
  } else if (totalButtons === 8) {
    buttonsPerRow = 4;
  } else {
    buttonsPerRow = 3;
  }

  return buttonsPerRow;
};

export default getButtonsPerRow;
