import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface WebRTCResponseEventListener {
  event: string;
  id: string;
  callback: (response: any) => void;
}

const useWebRTCResponse = () => {
  const responseEventListenersRef = useRef<WebRTCResponseEventListener[]>([]);

  const addResponseEventListener = (event: string, callback: (response: any) => void) => {
    const id = uuidv4();
    const newListeners = [...responseEventListenersRef.current, { event, id, callback }];
    responseEventListenersRef.current = newListeners;

    return id;
  };

  const removeResponseEventListener = (id: string) => {
    responseEventListenersRef.current = responseEventListenersRef.current.filter(
      (eventListener: WebRTCResponseEventListener) => eventListener.id !== id,
    );
  };

  const useWebRTCResponseEventListener = (event: string, callback: any) => {
    useEffect(() => {
      const eventListenerId = addResponseEventListener(event, callback);

      return () => removeResponseEventListener(eventListenerId);
    }, []);
  };

  const handleResponse = (response: any) => {
    const parsedResponse = JSON.parse(response);
    console.log(parsedResponse);

    let eventName = parsedResponse.Task || parsedResponse.task;

    // Need to set standard with UE team
    if (Object.keys(parsedResponse).includes('GeneralSettings')) {
      eventName = 'GeneralSettings';
    } else if (Object.keys(parsedResponse).includes('configuratorState')) {
      eventName = 'ConfiguratorState';
    }

    responseEventListenersRef.current.forEach((eventListener: WebRTCResponseEventListener) => {
      if (eventListener.event === eventName) {
        eventListener.callback(parsedResponse);
      }
    });
  };

  return { useWebRTCResponseEventListener, handleResponse };
};

export default useWebRTCResponse;
