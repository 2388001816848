import React from 'react';
import { Typography, Stack, Box, Button } from '@mui/material';

import { environment } from 'utils';
import useStyles from 'hooks/useStyles';

import bwLogo from 'assets/images/bw-logo.svg';
import adLogo from 'assets/images/ad-logo.png';

import getStyles from './ErrorPage.styles';

interface Props {
  errorMessage: string;
  type: string;
}

const { REACT_APP_COMPANY, REACT_APP_PORTAL_URL } = environment;
const isBd = REACT_APP_COMPANY === 'bd';

const ErrorPage: React.FC<Props> = ({ errorMessage, type }) => {
  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.errorPage} direction="column" spacing={6}>
      <Box sx={styles.logo} component="img" src={isBd ? bwLogo : adLogo} alt={isBd ? 'baya-weaver-logo' : 'ad-logo'} />

      <Typography variant="h6">{errorMessage}</Typography>

      {type === 'shareIdError' && (
        <a href={REACT_APP_PORTAL_URL}>
          <Button sx={styles.button}>Return to Baya Weaver</Button>
        </a>
      )}
    </Stack>
  );
};

export default ErrorPage;
