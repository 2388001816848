export enum LoveLetter {
  bypassSuccess = 'Bypass succeeded, about to connect.',
  bypassFailed = 'Bypass failed, something went wrong.',
  bypassNoStream = 'Bypass succeeded, no stream available.',
  bypassError = 'Bypass error.',
  signallingConnectionEstablished = 'Signalling connection established.',
  signallingConnectClient = 'Start connection to stream signalling.',
  startInstance = "No stream found, let's try start one only for you.",
  startInstanceWithRId = 'Directly starting stream - debugging.',
  searchResource = 'Searching for resources to start the stream.',
  foundResource = 'Found resource to start the stream.',
  commandedStart = 'Starting stream.',
  requestInstance = 'Connecting to cloud.',
  searchSession = "Let's try to find your session.",
  searchInstance = 'Searching for stream.',
  connectClient = 'Reserving stream.',
  awaitConnectClient = 'Waiting for stream.',
  ticketRegistered = 'Ticket registered.',
  ticketDestroyed = 'Ticket destroyed.',
  checkToken = 'Checking token.',
  shareIdError = 'Share error.',
  shareIdDoesNotExist = 'Share unknown.',
  shareIdExpired = 'Share expired.',
  shareIdInactive = 'Share inactive.',
  shareIdUsageCapReached = 'Share inactive, usage cap reached.',
  authorizationError = 'Authorization error.',
  scissors = "Don't run with scissors.",
  noProjectIdTableFlip = 'No projectId? (\u25E0\u203F\u25E0 ) (\u256F\u0CA0_\u0CA0)\u256F\uFE35 \u253B\u2501\u253B',
  tableFlip = '(\u256F\u00B0\u25A1\u00B0\uFF09\u256F\uFE35 \u253B\u2501\u253B',
  shrug = '\u00AF\\_(\u30C4)_/\u00AF',
  wat = '\u0CA0_\u0CA0',
  destroy_projectNotDistributed = 'The requested project is not distributed.',
  destroy_projectNotYetDistributed = 'The project is currently in distribution. Please retry in a few minutes.',
  destroy_projectHasNeverBeenReleased = 'Project unavailable. This project has never been released.',
  destroy_projectHasNeverBeenReleasedYet = 'Project unavailable. Seems to be available soon.',
  destroy_projectLastReleaseFailed = 'Project unavailable. Last release failed.',
  destroy_instanceTimeout = "Project didn't start in time.",
}
export enum CustomLoveLetter {
  bypassSuccess = 'Bypass succeeded, about to connect.',
  bypassFailed = 'Bypass failed, something went wrong.',
  bypassNoStream = 'Bypass succeeded, no stream available.',
  bypassError = 'Bypass error.',
  signallingConnectionEstablished = 'Connection to server established.',
  signallingConnectClient = 'Connecting to server.',
  startInstance = 'No stream found, please wait while we try again.',
  startInstanceWithRId = 'Directly starting stream - debugging.',
  searchResource = 'Searching for resources to start the stream.',
  foundResource = 'Found resource to start the stream.',
  commandedStart = 'Starting stream.',
  requestInstance = 'Connecting to cloud.',
  searchSession = 'Please wait while we search for your session.',
  searchInstance = 'Searching for stream.',
  connectClient = 'Reserving stream.',
  awaitConnectClient = 'Waiting for stream.',
  ticketRegistered = 'Stream starting soon.',
  ticketDestroyed = 'There has been a problem with the stream. Please refresh your browser.',
  checkToken = 'Checking token.',
  shareIdError = 'Share error.',
  shareIdDoesNotExist = 'Share unknown.',
  shareIdExpired = 'Share expired.',
  shareIdInactive = 'Share inactive.',
  shareIdUsageCapReached = 'Share inactive, usage cap reached.',
  authorizationError = 'Authorization error.',
  destroy_projectNotDistributed = 'The requested stream is not ready. Please retry in a few minutes.',
  destroy_projectNotYetDistributed = ' The stream is being prepared. Please retry in a few minutes.',
  destroy_projectHasNeverBeenReleased = 'Stream unavailable. Please contact support.',
  destroy_projectHasNeverBeenReleasedYet = 'Stream unavailable. Please retry in a few minutes.',
  destroy_projectLastReleaseFailed = 'Stream unavailable. Please contact support.',
  destroy_instanceTimeout = "Stream didn't start in time. Please retry in a few minutes.",
}

export enum LoveLetterEasterEgg {
  scissors = "Don't run with scissors.",
  noProjectIdTableFlip = 'No projectId? (\u25E0\u203F\u25E0 ) (\u256F\u0CA0_\u0CA0)\u256F\uFE35 \u253B\u2501\u253B',
  tableFlip = '(\u256F\u00B0\u25A1\u00B0\uFF09\u256F\uFE35 \u253B\u2501\u253B',
  shrug = '\u00AF\\_(\u30C4)_/\u00AF',
  wat = '\u0CA0_\u0CA0',
  easterEgg_MayTheForce = 'May the force be with you. ;)',
  easterEgg_NotProjectLookingFor = 'This is not the project you are looking for. ;)',
  easterEgg_BendingTheSpoon = 'Bending the spoon. ;)',
  easterEgg_AdjustFluxCapacitor = 'Adjusting the flux capacitor. ;)',
  easterEgg_ExecuteGitForcePush = "Executing 'git push --force'. ;)",
  easterEgg_WaitForDaenerys = 'Waiting for Daenerys to say all her titles ... ;)',
  easterEgg_ReleasingTheKraken = 'Releasing the Kraken. ;)',
  easterEgg_BadFeeling = 'Uhh ... I have a bad feeling about that. ;)',
  easterEgg_TestPixelsForCovid = 'Testing pixels for covid-19.',
}

export enum LoveLetterError {
  bypassSuccess = 'Bypass succeeded, about to connect.',
  bypassFailed = 'Bypass failed, something went wrong.',
  bypassNoStream = 'Bypass succeeded, no stream available.',
  bypassError = 'Bypass error.',
  ticketDestroyed = 'Ticket destroyed.',
  shareIdError = 'Share error.',
  shareIdDoesNotExist = 'Share unknown.',
  shareIdExpired = 'Share expired.',
  shareIdInactive = 'Share inactive.',
  shareIdUsageCapReached = 'Share inactive, usage cap reached.',
  authorizationError = 'Authorization error.',
  destroy_projectNotDistributed = 'The requested project is not distributed.',
  destroy_projectNotYetDistributed = 'The project is currently in distribution. Please retry in a few minutes.',
  destroy_projectHasNeverBeenReleased = 'Project unavailable. This project has never been released.',
  destroy_projectHasNeverBeenReleasedYet = 'Project unavailable. Seems to be available soon.',
  destroy_projectLastReleaseFailed = 'Project unavailable. Last release failed.',
  destroy_instanceTimeout = "Project didn't start in time.",
}
