import { Stack, Typography, Button } from '@mui/material';
import isEqual from 'lodash.isequal';

import getButtonsPerRow from 'utils/getButtonsPerRow';
import useStyles from 'hooks/useStyles';
import getStyles from './SingleSelect.styles';
interface Props {
  onChange: any;
  value: any;
  options: any;

  disabled?: boolean;
}

const SingleSelect = ({ onChange, value, options, disabled = false }: Props) => {
  const styles = useStyles(getStyles, getButtonsPerRow(options));

  return (
    <Stack sx={{ flexWrap: 'wrap' }} direction="row">
      {options.map((option: any, index: number) => {
        if (option.groupLabel) {
          return (
            <Typography sx={styles.singleSelectGroupLabel} key={`${option.groupLabel}${index}`} variant="body2">
              {option.groupLabel}
            </Typography>
          );
        } else {
          return (
            <Stack
              sx={styles.singleSelectButton}
              key={`${option.label}${index}`}
              onClick={() => (disabled ? null : onChange(option.value))}
            >
              {option.thumbnail && (
                <img
                  style={{ ...styles.thumbnail, ...(disabled ? styles.thumbnailDisabled : {}) }}
                  src={option.thumbnail}
                />
              )}
              <Button
                onClick={() => onChange(option.value)}
                variant={isEqual(value, option.value) ? 'active' : 'inactive'}
                disabled={disabled}
              >
                {option.label}
              </Button>
            </Stack>
          );
        }
      })}
    </Stack>
  );
};

export default SingleSelect;
