import { Theme, SxProps } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  titlePage: {
    width: '100%',
    height: '100dvh',
    position: 'fixed',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B1B1B',
    zIndex: theme.zIndex.loadingPage,
    cursor: 'pointer',
  },

  project: {
    width: '100%',
    height: '100%',
    mt: 3,
  },

  info: {
    width: '100%',
    height: '100%',
    color: '#fff',
    textAlign: 'center',
  },

  build: {
    width: '100%',
    height: '100%',
    mb: 3,
    color: '#fff',
  },
});

export default getStyles;
