const downloadFile = async (downloadlink: string, date: string) => {
  const file = await fetch(downloadlink);
  const fileBlob = await file.blob();
  const fileURL = URL.createObjectURL(fileBlob);

  const anchor = document.createElement('a');
  anchor.href = fileURL;
  anchor.download = '' + date;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(fileURL);
};

export default downloadFile;
