import { Stack, Typography } from '@mui/material';

import useStyles from 'hooks/useStyles';

import getStyles from './Messages.styles';

interface Props {
  messages: string[];
}

const Messages = ({ messages }: Props) => {
  const styles = useStyles(getStyles);

  return (
    <Stack sx={styles.messages}>
      {messages.map((message, index) => (
        <Typography sx={styles.message(index)} key={`${message}${index}`} variant="body1">
          {message}
        </Typography>
      ))}
    </Stack>
  );
};

export default Messages;
