import { Theme, SxProps } from '@mui/material';

const getStyles = (theme: Theme): SxProps => ({
  tips: {
    color: '#fff',
    ml: 7,
    alignSelf: 'flex-start',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '120px',
      mx: 4,
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      width: '100%',
      height: '100px',
      ml: 7,
    },
  },

  button: {
    backgroundColor: 'transparent',

    [theme.breakpoints.down('md')]: {
      fontSize: '0.5em',
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      fontSize: '0.5em',
    },
  },

  text: {
    pl: 1.5,

    [theme.breakpoints.down('md')]: {
      width: '80%',
    },

    '@media (max-width: 1080px) and (max-height: 700px) and (orientation: landscape)': {
      width: '80%',
    },
  },
});

export default getStyles;
